<template>
  <BaseButton
    elevation="0"
    fab
    class="mini-navigate-icon"
    outlined
    :title="$t('Accessibility.backArrow.library.tooltip')"
    :aria-label="$t('Accessibility.toLibrary')"
    @click="toLibrary"
  >
    <BaseSpriteIcon icon-name="ico-library-outline"></BaseSpriteIcon>
  </BaseButton>
</template>

<script>
import urlUtils from '@shared/utils/url';

import AppStateEnum from '@/enums/AppStateEnum';

import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';
import BaseButton from '@/components/base/BaseButton/BaseButton';

export default {
  name: 'ToLibraryIconControl',
  components: {
    BaseSpriteIcon,
    BaseButton
  },
  computed: {
    isBlogApp() {
      return this.$store.getters['ContextStore/isBlogApp'];
    }
  },
  methods: {
    toLibrary() {
      if (this.isBlogApp) {
        const clientReaderUrl = this.$store.getters[
          'ContextStore/getClientReaderUrl'
        ];
        this.$store.dispatch('ContextStore/openUrl', {
          url: `${clientReaderUrl}library`
        });
        return;
      }
      if (this.$route.name === AppStateEnum.SEARCH) {
        const selectedCategories = this.$store.getters[
          'SwFilterStore/getSelectedCategories'
        ];
        if (selectedCategories?.length === 1) {
          this.$router.push({
            name: AppStateEnum.CATEGORY,
            params: {
              name: urlUtils.encodeStringToPath(
                selectedCategories[0].originalName
              )
            }
          });
          return;
        }
      }
      this.$router.push({ name: AppStateEnum.MANAGE_PUBLICATION });
    }
  }
};
</script>

<style lang="less" src="./ToLibraryIconControl.less"></style>
