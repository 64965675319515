<template>
  <div class="read-mode-settings-wrapper pa-5">
    <div
      v-if="showReadingFonts && displayBlockRules.fonts"
      class="settings-item pb-3"
    >
      <ul class="font-family-block">
        <li
          v-for="font in readingSettingsView.readingFonts"
          :key="font.id"
          @click="changeFont(font)"
        >
          <div
            class="font-family-item"
            :class="{ checked: isFontChecked(font) }"
          >
            <span
              class="font-sample"
              :style="[font.styles, { 'font-family': font.name }]"
            >
              <span>Abc</span>
              <BaseSpriteIcon
                v-if="isFontChecked(font)"
                icon-name="ico-check"
              />
            </span>
            <span class="font-name">{{ font.name }}</span>
          </div>
        </li>
      </ul>
    </div>

    <div
      v-if="showAudioSpeedRangeItem && displayBlockRules.audioSpeed"
      class="settings-item py-3"
    >
      <div class="range-title">
        <span>{{ $t('ReadModeSettingsMenu.audioSpeed.label') }}</span>
      </div>
      <div class="range-wrapper">
        <v-slider
          id="audioSpeedRange"
          v-model.number="currentAudioSpeed"
          :max="readingSettingsView.maxAudioSpeed"
          :min="readingSettingsView.minAudioSpeed"
          :step="audioSpeedStep"
          :color="isNightTheme ? '#FFFFFF29' : '#e2e8f0'"
          :track-color="isNightTheme ? '#FFFFFF29' : '#e2e8f0'"
          thumb-color="primary"
          @change="onAudioSpeedChange"
        ></v-slider>
        <div class="default-range-position">
          <div class="default-range-position-handler" />
        </div>
      </div>
      <div class="scale-description-block">
        <BaseButton
          outlined
          text
          :disabled="isMinAudioSpeed"
          :class="{ disabled: isMinAudioSpeed }"
          class="min-button scale-audio-speed-block px-0"
          @click="decreaseAudioSpeed"
          ><BaseSpriteIcon icon-name="ico-minus"
        /></BaseButton>
        <span class="title-value">
          {{
            $t('ReadModeSettingsMenu.audioSpeed.wpm', {
              audioSpeed: currentAudioSpeed
            })
          }}
        </span>
        <BaseButton
          outlined
          text
          :disabled="isMaxAudioSpeed"
          :class="{ disabled: isMaxAudioSpeed }"
          class="max-button scale-audio-speed-block px-0"
          @click="increaseAudioSpeed"
          ><BaseSpriteIcon icon-name="ico-plus"
        /></BaseButton>
      </div>
    </div>

    <div class="settings-item font-control-block py-3">
      <div v-if="displayBlockRules.fontSize" class="font-size-control-block">
        <div class="item-title">
          {{ $t('ReadModeSettingsMenu.fontSize.label') }}
        </div>
        <div class="item-controls">
          <BaseButton
            outlined
            text
            :disabled="isMinFontSize"
            :class="{ disabled: isMinFontSize }"
            class="min-button px-0"
            @click="decreaseFontSize"
          >
            <BaseSpriteIcon icon-name="ico-minus" />
          </BaseButton>

          <div>{{ readingSettingsView.fontSize + '%' }}</div>
          <BaseButton
            outlined
            text
            :disabled="isMaxFontSize"
            :class="{ disabled: isMaxFontSize }"
            class="max-button px-0"
            @click="increaseFontSize"
          >
            <BaseSpriteIcon icon-name="ico-plus" />
          </BaseButton>
        </div>
      </div>

      <div
        v-if="displayBlockRules.lineSpacing"
        class="line-spacing-control-block"
      >
        <div class="item-title">
          {{ $t('ReadModeSettingsMenu.lineSpacing.label') }}
        </div>
        <div class="item-controls">
          <BaseButton
            outlined
            text
            :disabled="isMinLineSpacing"
            :class="{ disabled: isMinLineSpacing }"
            class="min-button px-0"
            @click="decreaseLineSpacing"
          >
            <BaseSpriteIcon icon-name="ico-minus" />
          </BaseButton>
          <div>{{ readingSettingsView.lineSpacing + '%' }}</div>

          <BaseButton
            outlined
            text
            :disabled="isMaxLineSpacing"
            :class="{ disabled: isMaxLineSpacing }"
            class="max-button px-0"
            @click="increaseLineSpacing"
          >
            <BaseSpriteIcon icon-name="ico-plus" />
          </BaseButton>
        </div>
      </div>
    </div>

    <div v-if="displayBlockRules.themes" class="settings-item py-3">
      <div class="theme-change-block">
        <div class="theme-change-title">
          {{ $t('ReadModeSettingsMenu.themes.label') }}
        </div>
        <div class="theme-change-items">
          <div
            v-for="theme in readingSettingsView.readingThemes"
            :key="theme.name"
            class="theme-item"
            @click="changeTheme(theme)"
          >
            <span
              class="dummy-button"
              :class="[theme.name, { active: isThemeChosen(theme) }]"
            >
              <BaseSpriteIcon icon-name="ico-check" />
            </span>
            <span class="theme-text">{{ $t(theme.title) }}</span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!context.isCompilation" class="settings-item">
      <ul class="switcher-block">
        <li
          v-if="showExpandMarginNotes"
          class="switcher-wrapper expanded-margin-notes"
        >
          <v-switch
            v-model="expandedNotesMode"
            inset
            dense
            class="v-input--reverse v-input--expand"
            :label="$t('ReadModeSettingsMenu.expandedMarginNotes.label')"
          ></v-switch>
        </li>
        <li v-if="displayBlockRules.expandedFootnotes" class="switcher-wrapper">
          <v-switch
            v-model="expandedFootnotesMode"
            inset
            dense
            class="v-input--reverse v-input--expand"
            :label="$t('ReadModeSettingsMenu.expandedFootnotes.label')"
          ></v-switch>
        </li>
      </ul>
    </div>

    <div
      v-if="displayBlockRules.autoPlayNotes && !isGuest"
      class="settings-item"
    >
      <ul class="switcher-block">
        <li class="switcher-wrapper">
          <v-switch
            v-model="autoPlayNotes"
            inset
            dense
            class="v-input--reverse v-input--expand"
            :label="$t('ReadModeSettingsMenu.autoPlayNotes.label')"
          ></v-switch>
        </li>
      </ul>
    </div>

    <div v-if="!context.isCompilation" class="settings-item pb-3">
      <div v-if="displayBlockRules.dictionaryAutoOpen">
        <ul class="switcher-block">
          <li class="switcher-wrapper">
            <v-switch
              v-model="dictionaryAutoOpen"
              inset
              dense
              class="v-input--reverse v-input--expand"
              :label="$t('ReadModeSettingsMenu.dictionaryAutoOpen.label')"
              :disabled="isDictionaryAutoOpenIsUpdating"
            ></v-switch>
          </li>
        </ul>
      </div>

      <div>
        {{ $t('ReadModeSettingsMenu.dictionaries.label') }}
      </div>
      <div class="dictionaries-list-block ml-2">
        <!-- <v-checkbox
          v-model="googleTranslate"
          dense
          hide-details
          :label="$t('ReadModeSettingsMenu.googleTranslate.label')"
          :disabled="isGoogleTranslateIsUpdating"
        ></v-checkbox> -->
        <v-checkbox
          v-model="dictionaryMode"
          dense
          hide-details
          :label="$t('ReadModeSettingsMenu.dictionaryMode.label')"
          :disabled="isDictionaryModeIsUpdating"
        ></v-checkbox>
        <v-checkbox
          v-model="wikiMode"
          dense
          hide-details
          :label="$t('ReadModeSettingsMenu.wikiMode.label')"
          :disabled="isWikiModeIsUpdating"
        ></v-checkbox>
      </div>
    </div>

    <div v-if="isTranslateVisible" class="settings-item py-3 flex">
      <div class="settings-label">{{ $t('TranslatePopup.header') }}</div>
      <div class="translate-block" @click="openTranslatePopup">
        <span>{{
          `${
            selectedLanguage.name
          } (${selectedLanguage.language.toUpperCase()})`
        }}</span>
        <BaseSpriteIcon icon-name="ico-arrow-right" />
      </div>
    </div>

    <div
      v-if="!context.isCompilation && context.isAudioBook"
      class="settings-item py-3"
    >
      <ul class="reading-mode-block switcher-block">
        <li>
          <div class="switch">
            <label>
              <span>
                {{ $t('ReadModeSettingsMenu.scrollingMode.label') }}
              </span>
            </label>
          </div>
        </li>
        <li class="radio-wrapper">
          <v-radio-group
            v-model="currentScrollingMode"
            class="control-wrapper"
            :class="{ disabled: isScrollingModeIsUpdating }"
            row
          >
            <v-radio
              v-for="(scrollingModeOption,
              index) in readingSettingsView.scrollingModes"
              :key="index"
              color="primary"
              class="browser-defaults"
              :label="$t(scrollingModeOption.label)"
              :value="scrollingModeOption.value"
              :disabled="isScrollingModeIsUpdating"
            ></v-radio>
          </v-radio-group>
        </li>
      </ul>
    </div>

    <div
      v-if="context.isAudioBook && displayBlockRules.underlineMode"
      class="py-3"
    >
      <ul class="switcher-block">
        <li>
          <div class="switch">
            <label>
              <span>
                {{ $t('ReadModeSettingsMenu.highlightMode.label') }}
              </span>
            </label>
          </div>
        </li>
        <li class="radio-wrapper">
          <v-radio-group
            v-model="currentHighlightMode"
            class="control-wrapper"
            :class="{ disabled: isHighlightModeIsUpdating }"
            row
          >
            <v-radio
              v-for="(highlightOption,
              index) in readingSettingsView.highlightOptions"
              :key="index"
              color="primary"
              class="browser-default"
              :class="highlightOption.customClass"
              :value="highlightOption.value"
              :disabled="isHighlightModeIsUpdating"
            >
              <template slot="label">
                <span
                  class="label-text"
                  v-html="$t(highlightOption.label)"
                ></span>
              </template>
            </v-radio>
          </v-radio-group>
        </li>
      </ul>
    </div>

    <div v-if="isAdmin && !context.isCompilation" class="pt-1">
      <ul class="switcher-block">
        <li class="switcher-wrapper">
          <v-switch
            v-if="displayBlockRules.readingPositionTracking"
            v-model="readingPositionTracking"
            inset
            dense
            class="v-input--reverse v-input--expand"
            :label="$t('ReadModeSettingsMenu.readingPositionTracking.label')"
          ></v-switch>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/base/BaseButton/BaseButton.vue';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import ReadingSettingsContextFactory from '@/classes/factories/ReadingSettingsContextFactory';
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import ReadingSettingsEnum from '@/enums/ReadingSettingsEnum';

const SLIDER_THUMB_WIDTH = 20;
const SETTING_ITEM_PADDING = 20;

export default {
  name: 'ReadingSettingsComponent',
  components: { BaseSpriteIcon, BaseButton },
  props: {},
  data() {
    const bookId = this.$store.getters['OpenParameterStore/getPublicationId'];
    return {
      bookId,
      defaultRangePositionStyle: null,
      context: {},
      currentAudioSpeed: 0
    };
  },
  computed: {
    isDictionaryAutoOpenIsUpdating() {
      return this.$store.getters[
        'ReadingSettingsStore/getIsSettingsIsUpdating'
      ](ReadingSettingsEnum.DICTIONARY_AUTO_OPEN);
    },
    isGoogleTranslateIsUpdating() {
      return this.$store.getters[
        'ReadingSettingsStore/getIsSettingsIsUpdating'
      ](ReadingSettingsEnum.GOOGLE_TRANSLATE);
    },
    isWikiModeIsUpdating() {
      return this.$store.getters[
        'ReadingSettingsStore/getIsSettingsIsUpdating'
      ](ReadingSettingsEnum.WIKI_MODE);
    },
    isDictionaryModeIsUpdating() {
      return this.$store.getters[
        'ReadingSettingsStore/getIsSettingsIsUpdating'
      ](ReadingSettingsEnum.DICTIONARY_MODE);
    },
    isHighlightModeIsUpdating() {
      return this.$store.getters[
        'ReadingSettingsStore/getIsSettingsIsUpdating'
      ](ReadingSettingsEnum.HIGHLIGHT_MODE);
    },
    isScrollingModeIsUpdating() {
      return this.$store.getters[
        'ReadingSettingsStore/getIsSettingsIsUpdating'
      ](ReadingSettingsEnum.SCROLLING_MODE);
    },
    isOnline() {
      return this.$store.getters['ContextStore/isOnline'];
    },
    isGuest() {
      return this.$store.getters['UserStore/isGuestUser'];
    },
    selectedLanguage() {
      return this.$store.state.WikiStore.translatorLanguage;
    },
    isTranslateVisible() {
      return (
        this.isOnline && !this.context.isCompilation && this.selectedLanguage
      );
    },
    readingSettingsView() {
      return this.$store.getters['ReadingSettingsStore/getReadingSettingsView'];
    },
    audioSpeedStep() {
      return this.$store.getters['ReadingSettingsStore/getAudioSpeedStep'];
    },
    showReadingFonts() {
      return this.readingSettingsView.readingFonts.length > 1;
    },
    displayBlockRules() {
      return this.$store.getters['ReadingSettingsStore/getDisplayRules'];
    },
    showExpandMarginNotes() {
      return (
        this.displayBlockRules.expandedMarginNotes &&
        this.$store.state.ReadingSettingsStore.canShowMarginNotes
      );
    },
    narrow() {
      return this.$store.getters['MediaDetectorStore/mediaSize'].narrow;
    },
    showAudioSpeedRangeItem() {
      return this.context.isAudioBook;
    },
    isMinFontSize() {
      return this.$store.getters['ReadingSettingsStore/getIsMinFontSize'];
    },
    isMaxFontSize() {
      return this.$store.getters['ReadingSettingsStore/getIsMaxFontSize'];
    },
    isMinAudioSpeed() {
      return this.$store.getters['ReadingSettingsStore/getIsMinAudioSpeed'];
    },
    isMaxAudioSpeed() {
      return this.$store.getters['ReadingSettingsStore/getIsMaxAudioSpeed'];
    },
    isMinLineSpacing() {
      return this.$store.getters['ReadingSettingsStore/getIsMinLineSpacing'];
    },
    isMaxLineSpacing() {
      return this.$store.getters['ReadingSettingsStore/getIsMaxLineSpacing'];
    },
    isAdmin() {
      return this.$store.getters['UserStore/isUserAdmin'];
    },
    expandedNotesMode: {
      get: function() {
        return this.readingSettingsView.expandedNotesMode;
      },
      set: function(newMode) {
        this.$store.dispatch('ReadingSettingsStore/setExpandedNotesMode', {
          bookId: this.bookId,
          expandedNotesMode: newMode
        });
      }
    },
    expandedFootnotesMode: {
      get: function() {
        return this.readingSettingsView.expandedFootnotesMode;
      },
      set: function(setting) {
        this.$store.dispatch('ReadingSettingsStore/setExpandedFootnotesMode', {
          setting
        });
      }
    },
    autoPlayNotes: {
      get() {
        return this.readingSettingsView.autoPlayNotes;
      },
      set(value) {
        this.$store.dispatch('ReadingSettingsStore/setAutoPlayNotes', {
          setting: value
        });
      }
    },
    googleTranslate: {
      get: function() {
        return this.readingSettingsView.googleTranslate;
      },
      set: function(setting) {
        this.$store.dispatch('ReadingSettingsStore/setGoogleTranslateMode', {
          setting
        });
      }
    },
    dictionaryMode: {
      get: function() {
        return this.readingSettingsView.dictionaryMode;
      },
      set: function(setting) {
        this.$store.dispatch('ReadingSettingsStore/setDictionaryMode', {
          setting
        });
      }
    },
    wikiMode: {
      get: function() {
        return this.readingSettingsView.wikiMode;
      },
      set: function(setting) {
        this.$store.dispatch('ReadingSettingsStore/setWikiMode', {
          setting
        });
      }
    },
    dictionaryAutoOpen: {
      get: function() {
        return this.readingSettingsView.dictionaryAutoOpen;
      },
      set: function(setting) {
        this.$store.dispatch('ReadingSettingsStore/setDictionaryAutoOpen', {
          setting
        });
      }
    },
    currentScrollingMode: {
      get: function() {
        return this.readingSettingsView.scrollingMode;
      },
      set: function(setting) {
        this.$store.dispatch('ReadingSettingsStore/setScrollingMode', {
          setting
        });
      }
    },
    currentHighlightMode: {
      get: function() {
        return this.readingSettingsView.highlightMode;
      },
      set: function(setting) {
        this.$store.dispatch('ReadingSettingsStore/setHighlightMode', {
          setting
        });
      }
    },
    underlineMode: {
      get: function() {
        return this.readingSettingsView.underlineMode;
      },
      set: function(newMode) {
        this.$store.dispatch('ReadingSettingsStore/setUnderlineMode', newMode);
      }
    },
    readingPositionTracking: {
      get: function() {
        return this.readingSettingsView.readingPositionTracking;
      },
      set: function(val) {
        this.$store.dispatch(
          'ReadingSettingsStore/setReadingPositionTracking',
          val
        );
      }
    },
    isNightTheme() {
      return this.$store.getters['ReadingSettingsStore/isNightTheme'];
    }
  },
  created() {
    this.$store.commit('ReadingSettingsStore/recalculateCanShowMarginNotes');
  },
  mounted() {
    this.currentAudioSpeed = this.readingSettingsView.audioSpeed;
    const meta = this.$store.getters['PublicationStore/getMeta'](this.bookId);
    const isCompilation = this.$store.getters[
      'PublicationStore/isCompilationOpen'
    ];
    this.context = ReadingSettingsContextFactory.create({
      isCompilation,
      isAudioBook: meta?.audio
    });
    this.$nextTick(() => {
      this.defaultRangePositionStyle = this.getDefaultRangePositionStyle();
    });
  },
  destroyed() {
    this.$store.dispatch('ManagePopupStore/closePopup', {
      name: PopupNamesEnum.TRANSLATE_POPUP
    });
  },
  methods: {
    isFontChecked(font) {
      return font.name === this.readingSettingsView.font.name;
    },
    isThemeChosen(theme) {
      return theme.name === this.readingSettingsView.themeName;
    },
    getDefaultRangePositionStyle() {
      const { defaultAudioSpeed, minAudioSpeed } = this.readingSettingsView;
      const stepAmount = AppConstantsUtil.AUDIO_STEPS_IN_EACH_SIDE * 2;
      const stepOfDefaultSpeed =
        (defaultAudioSpeed - minAudioSpeed) / this.audioSpeedStep;
      const sliderWidth =
        (this.narrow ? window.innerWidth : this.$el.offsetWidth) -
        2 * SETTING_ITEM_PADDING;
      const leftOfLastStep = sliderWidth - SLIDER_THUMB_WIDTH;
      const leftOffset = (stepOfDefaultSpeed / stepAmount) * leftOfLastStep;
      return { left: `${leftOffset}px` };
    },
    changeTheme(chosenTheme) {
      this.$store.dispatch(
        'ReadingSettingsStore/setReadingThemeName',
        chosenTheme.name
      );
    },
    decreaseFontSize() {
      this.$store.dispatch('ReadingSettingsStore/decreaseFontSize');
    },
    increaseFontSize() {
      this.$store.dispatch('ReadingSettingsStore/increaseFontSize');
    },
    decreaseLineSpacing() {
      this.$store.dispatch('ReadingSettingsStore/decreaseLineSpacing');
    },
    increaseLineSpacing() {
      this.$store.dispatch('ReadingSettingsStore/increaseLineSpacing');
    },
    changeFont(font) {
      this.$store.dispatch('ReadingSettingsStore/setFont', font);
    },
    increaseAudioSpeed() {
      const audioSpeed = this.currentAudioSpeed;
      const maxSpeed = this.readingSettingsView.maxAudioSpeed;
      if (audioSpeed >= maxSpeed) {
        return;
      }
      this.currentAudioSpeed = audioSpeed + this.audioSpeedStep;
      this.onAudioSpeedChange();
    },
    decreaseAudioSpeed() {
      const audioSpeed = this.currentAudioSpeed;
      const minSpeed = this.readingSettingsView.minAudioSpeed;
      if (audioSpeed <= minSpeed) {
        return;
      }
      this.currentAudioSpeed = audioSpeed - this.audioSpeedStep;
      this.onAudioSpeedChange();
    },
    onAudioSpeedChange() {
      this.$store.dispatch('ReadingSettingsStore/setAudioSpeed', {
        bookId: this.bookId,
        audioSpeed: this.currentAudioSpeed
      });
    },
    openTranslatePopup() {
      this.$store.dispatch(
        'ManagePopupStore/hidePopup',
        PopupNamesEnum.READING_SETTINGS_BOTTOM_POPUP
      );

      this.$store.dispatch('ManagePopupStore/openPopup', {
        name: PopupNamesEnum.TRANSLATE_POPUP,
        popupContext: {
          parentPopup: PopupNamesEnum.READING_SETTINGS_BOTTOM_POPUP
        }
      });
    }
  }
};
</script>

<style lang="less" src="./ReadingSettingsComponent.less"></style>
