export default {
  HOME: 'home',
  LIBRARY_READER: 'libraryReader',
  COMPILATIONS: 'compilations',
  PRICING: 'pricing',
  BLOG: 'blog',
  LIBRARY_VIEW: 'LibraryView',
  ADMIN_PANEL: 'AdminPanel',
  REPORTS: 'Reports',
  OVERVIEW: 'Overview',
  ASSESSMENTS: 'Assessments',
  LIBRARY_SET: 'LibrarySet',
  CONTACT_US: 'ContactUs',
  RESUME_READING: 'ResumeReading',
  EXPLORE: 'Explore',
  DICTIONARY: 'Dictionary',
  FLASHCARDS: 'Flashcards',
  NEW_STUDY_PROJECT: 'NewStudyProject',
  CREATE_NEW_COURSE_SYLLABUS: 'CreateNewCourseSyllabus',
  CREATE_NEW_LIBRARY_SET: 'CreateNewLibrarySet',
  LIBRARY_CONTENT_EDITOR: 'LibraryContentEditor',
  FAVORITES: 'Favorites',
  DASHBOARD: 'Dashboard',
  ACTIVITY_REPORT: 'ActivityReport',
  MANAGE_CONTENT: 'ManageContent',
  PROFILE: 'Profile',
  LOGOUT: 'Logout',
  LOGIN: 'Login',
  ABOUT: 'About',
  APPLICATION_SETTINGS: 'ApplicationSettings',
  SHARE: 'Share',
  MANAGE_USERS: 'Users',
  MANAGE_AUTHORS: 'Authors',
  GOOGLE_ANALYTICS: 'GoogleAnalytics',
  BOOKS_LIST: 'Books',
  AFFILIATES: 'Affiliates',
  AFFILIATE_DASHBOARD: 'AffiliateDashboard',
  MY_CAMPAIGNS: 'MyCampaigns',
  TESTS: 'Tests',
  EXTERNAL_BLOG: 'ExternalBlog',
  ACTIVITY_DASHBOARD: 'ActivityDashboard',
  SHORTENER_EDITOR: 'ShortenerEditor'
};
