<template>
  <Popup
    ref="dialog"
    :width="popupWidth"
    :popup-visible="dialog"
    :close-on-content-click="false"
    :z-index="13"
    :content-class="annotationPopupClass"
    :header-title="popupData.popupHeaderTitle"
    :header-divider="false"
    :footer-divider="false"
    :fullscreen="isFullscreen"
    @popupEvent="closeWithoutSavingAnnotation"
  >
    <template #custom-content>
      <div class="content-container pa-4">
        <div class="top-block">
          <AnnotationChipItem
            v-show="dialog"
            :annotation="currentEditingAnnotationData"
            chip-content-class="no-hover"
            :click-action="false"
            :show-comment-icon="getEditingAnnotationShowCommentIcon"
            :show-tag-icon="getEditingAnnotationShowTagIcon"
          />
          <BaseTextEditor
            ref="editor"
            :content="editorContent"
            :counter-label="$t('ContextNote.textarea.label')"
            process-icon-visibility
            :font-size="contentFontSize"
          />
        </div>
        <BaseMultiSelect
          ref="multiselect"
          :items="multiselectItems"
          :options="sortedNoteHashtagsByDate"
          :input-max-length="maxNoteHashtagLength"
          :max-selected-items="maxNoteHashtags"
          :placeholder="$t('NoteHashtags.multiselect.placeholder')"
        />
      </div>
    </template>

    <template #custom-footer>
      <v-card-actions class="popup-footer">
        <div
          v-if="categoryId"
          class="tag-select"
          :class="isTagsPopupOpened ? 'collapsed' : ''"
          @click="openTagsPopup"
        >
          <span class="tag-name">{{ $t('TagSelect.label') }}</span>
          <div class="tag-data">
            <div
              class="circle-icon"
              :class="getStyleByAnnCategory(categoryId)"
              :style="{
                'background-color': getBackgroundColor(
                  currentEditingAnnotationData.category
                )
              }"
            ></div>
            <div class="category-select">
              <span class="category-name">
                {{ getCategoryName(categoryId) }}
              </span>
              <BaseSpriteIcon icon-name="ico-arrow-right" />
            </div>
          </div>
        </div>
        <div class="footer-actions">
          <BaseButton
            class="btn-secondary"
            elevation="0"
            @click="popupData.secondaryActionHandler"
          >
            {{ popupData.secondaryActionLabel }}
          </BaseButton>
          <BaseButton color="primary" elevation="0" @click="confirmCreation">
            {{ $t('Save') }}
          </BaseButton>
        </div>
      </v-card-actions>
    </template>
  </Popup>
</template>

<script>
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('CreateAnnotationPopup.vue');

import AnnotationChipItem from '@/components/views/Extras/NotesTab/AnnotationChipItem/AnnotationChipItem';
import Popup from '@/components/base/Popup/Popup.vue';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon';
import BaseButton from '@/components/base/BaseButton/BaseButton.vue';
import BaseTextEditor from '@/components/base/BaseTextEditor/BaseTextEditor.vue';
import BaseMultiSelect from '@/components/base/BaseMultiSelect/BaseMultiSelect.vue';
import highlightedQuoteMixin from '@/components/mixins/highlightedQuoteMixin';

import Locator from '@shared/publication/locator.mjs';
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import ThemeClassNamesEnum from '@/enums/ThemeClassNamesEnum';

import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import cloneDeep from 'lodash/cloneDeep';
import { getContentFontSize } from '@/services/FontSizeService';

export default {
  name: PopupNamesEnum.CREATE_ANNOTATION_POPUP,
  components: {
    BaseButton,
    BaseTextEditor,
    BaseMultiSelect,
    BaseSpriteIcon,
    Popup,
    AnnotationChipItem
  },
  mixins: [highlightedQuoteMixin],
  data() {
    return {
      popupWidth: AppConstantsUtil.CREATE_ANNOTATION_POPUP_WIDTH,
      maxNoteHashtags: AppConstantsUtil.MAX_NOTE_HASHTAGS,
      maxNoteHashtagLength: AppConstantsUtil.MAX_NOTE_HASHTAG_LENGTH,
      shouldSaveAnnotation: true,
      dialog: false,
      editorContent: ''
    };
  },
  computed: {
    getAllNoteHashtags() {
      return this.$store.getters['NoteHashtagsStore/getAllNoteHashtags'];
    },
    sortedNoteHashtagsByDate() {
      return this.getAllNoteHashtags.slice().sort((a, b) => {
        return b.modifiedAt - a.modifiedAt;
      });
    },
    currentEditingAnnotationData() {
      return this.$store.getters['AnnotationsStore/getEditingAnnotationData'];
    },
    multiselectItems() {
      return this.currentEditingAnnotationData?.noteHashtags || [];
    },
    getEditingAnnotationShowCommentIcon() {
      return this.$store.getters[
        'AnnotationsStore/getEditingAnnotationShowCommentIcon'
      ];
    },
    getEditingAnnotationShowTagIcon() {
      return this.$store.getters[
        'AnnotationsStore/getEditingAnnotationShowTagIcon'
      ];
    },
    isTagsPopupOpened() {
      return this.$store.getters['ManagePopupStore/isPopupOpened'](
        PopupNamesEnum.TAGS_POPUP
      );
    },
    popupContext() {
      return this.$store.getters['ManagePopupStore/getPopupContext'](
        PopupNamesEnum.CREATE_ANNOTATION_POPUP
      );
    },
    annotationPopupClass() {
      const isPopupHidden = this.popupContext?.popupHidden;
      return `create-annotation-popup ${isPopupHidden ? 'hidden' : ''}`;
    },
    categories() {
      return this.$store.getters['CategoriesStore/getShownCategories'];
    },
    isAnnotationExist() {
      return Boolean(this.popupContext?.annotation);
    },
    addAnnPopupData() {
      return {
        popupHeaderTitle: this.$t(`ContextNote.header.add`),
        secondaryActionLabel: this.$t('Cancel'),
        secondaryActionHandler: this.closeWithoutSavingAnnotation
      };
    },
    editAnnPopupData() {
      return {
        popupHeaderTitle: this.$t(`ContextNote.header.change`),
        secondaryActionLabel: this.$t('Delete'),
        secondaryActionHandler: this.deleteAnnotation
      };
    },
    popupData() {
      return this.isAnnotationExist
        ? this.editAnnPopupData
        : this.addAnnPopupData;
    },
    categoryId: {
      get() {
        return this.currentEditingAnnotationData.categoryId;
      },
      set(categoryId) {
        const category = this.getCategoryById(categoryId);
        let annotationData = {
          ...this.currentEditingAnnotationData,
          category,
          categoryId
        };
        this.changeEditingAnnotationData(annotationData);
      }
    },
    isFullscreen() {
      const { narrow = false, normal = false } =
        this.$store.getters['MediaDetectorStore/mediaSize'] || {};
      return narrow || normal;
    },
    contentFontSize() {
      return getContentFontSize(this.$store);
    }
  },
  watch: {
    editContent(val) {
      return val;
    },
    locator() {
      this.shouldSaveAnnotation = false;
    },
    categories(newCategories, oldCategories) {
      if (!oldCategories?.length) {
        this.initCreateAnnotationPopup();
      }

      if (!this.isCurrentCategoryStillExist(newCategories)) {
        this.categoryId = this.isAnnotationExist
          ? this.getDefaultCategory()?.id
          : this.categories[0].id;
      }
    }
  },
  mounted() {
    this.initCreateAnnotationPopup();
  },
  destroyed() {
    if (this.isCreateAnnotationPopupOpened()) {
      this.close();
    }
    document
      .querySelector('html')
      .classList.remove(AppConstantsUtil.VUETIFY_DISABLE_SCROLL_CLASS);
  },
  methods: {
    getDefaultCategory() {
      return this.$store.getters['CategoriesStore/getDefaultCategory'];
    },
    closeSmallPopups() {
      if (this.isCategoriesPopupOpened()) {
        this.$store.dispatch('ManagePopupStore/closePopup', {
          name: PopupNamesEnum.CREATE_CATEGORY
        });
      }
      if (this.isTagsPopupOpened) {
        this.$store.dispatch('ManagePopupStore/closePopup', {
          name: PopupNamesEnum.TAGS_POPUP
        });
      }
    },
    changeEditingAnnotationData(annotationData) {
      this.$store.dispatch(
        'AnnotationsStore/changeEditingAnnotationData',
        annotationData
      );
    },
    selectedLocator() {
      return this.$store.getters['MaterialsStore/getSelectedLocator'];
    },
    getBackgroundColor(category) {
      const isNightMode = this.isNightMode();
      if (category?.underline && !isNightMode) {
        return;
      }
      return isNightMode ? category.nightColor : category.color;
    },
    isNightMode() {
      return (
        this.$store.getters['ReadingSettingsStore/getThemeName'] ===
        ThemeClassNamesEnum.NIGHT
      );
    },
    selectedText() {
      const locators = this.selectedLocator();
      return this.$store.getters['BookStore/getTextWithContextByLocators'](
        locators
      );
    },
    isCreateAnnotationPopupOpened() {
      return this.$store.getters['ManagePopupStore/isPopupOpened'](
        PopupNamesEnum.CREATE_ANNOTATION_POPUP
      );
    },
    isCurrentCategoryStillExist(categories) {
      return categories.some(category => {
        return category.id === this.categoryId;
      });
    },
    bookId() {
      const openParams = this.$store.getters[
        'OpenParameterStore/getPublicationOpenParameters'
      ];
      return openParams?.publicationId;
    },
    async getCurrentAnnotationData() {
      try {
        if (this.isAnnotationExist) {
          const currentAnnotation = this.popupContext.annotation;
          const highlightedQuote = await this.$_getHighlightedQuote(
            this.bookId(),
            currentAnnotation
          );
          const annotationData = cloneDeep(currentAnnotation);
          return {
            ...annotationData,
            highlightedQuote
          };
        }

        const selectedText = this.selectedText();
        return {
          ...this.currentEditingAnnotationData,
          categoryId: this.categories[0].id,
          category: this.categories[0],
          highlightedQuote: { text: selectedText?.text || '' }
        };
      } catch (error) {
        logger.error(`Get current annotation data failed with ${error}`);
      }
    },
    async initCreateAnnotationPopup() {
      try {
        if (!this.categories?.length) {
          logger.error(`Init create annotation popup failed: no categories`);
          return;
        }

        const annotationData = await this.getCurrentAnnotationData();
        this.changeEditingAnnotationData(annotationData);
        this.editorContent = this.currentEditingAnnotationData.note;
        this.dialog = true;
        document
          .querySelector('html')
          .classList.add(AppConstantsUtil.VUETIFY_DISABLE_SCROLL_CLASS);
      } catch (error) {
        logger.error(`Init create annotation popup failed with ${error}`);
      }
    },
    onCategoryClick(category) {
      this.categoryId = category.id;
    },
    getCategoryName(categoryId) {
      const { label, name = '' } = this.getCategoryById(categoryId);
      return label ? this.$t(label) : name;
    },
    getCategoryById(categoryId) {
      return this.$store.getters['CategoriesStore/getCategoryById'](categoryId);
    },
    getStyleByAnnCategory(categoryId) {
      return this.$store.getters['CategoriesStore/getAnnClassByCategoryId'](
        categoryId
      );
    },
    closeWithoutSavingAnnotation() {
      this.shouldSaveAnnotation = false;
      this.close();
    },
    async confirmCreation() {
      await this.saveItem();
      this.closeWithoutSavingAnnotation();
    },
    async saveItem() {
      try {
        let action = '';
        let locator = {};

        if (this.isAnnotationExist) {
          action = 'AnnotationsStore/processChangeAnnotation';
          locator = new Locator.InTextRangeLocator(
            this.currentEditingAnnotationData.start,
            this.currentEditingAnnotationData.end
          );
        } else {
          action = 'AnnotationsStore/processAddAnnotation';
          locator = this.selectedLocator();
        }

        const note = this.$refs?.editor?.editorContent || '';
        await this.$store.dispatch('NoteHashtagsStore/addNoteHashtagsForUser', {
          noteHashtags: this.$refs?.multiselect?.selectedItems
        });

        const noteHashtags = this.$store.getters[
          'NoteHashtagsStore/getUpdatedHashtags'
        ];
        this.$store.dispatch(action, {
          annotation: this.currentEditingAnnotationData,
          locator,
          categoryId: this.categoryId,
          note,
          noteHashtags
        });

        this.$store.dispatch('NoteHashtagsStore/clearUpdatedNoteHashtags');
      } catch (err) {
        logger.error(
          `Error while saving annotation in CreateAnnotationPopup: ${err.stack}`
        );
      }
    },
    deleteAnnotation() {
      this.$store.dispatch('AnnotationsStore/deleteAnnotation', {
        annotation: this.popupContext.annotation
      });

      this.closeWithoutSavingAnnotation();
    },
    openTagsPopup() {
      this.$store.dispatch('ManagePopupStore/openPopup', {
        name: PopupNamesEnum.TAGS_POPUP,
        popupContext: {
          attachedToElClass: 'tag-select',
          activeCategoryId: this.categoryId
        }
      });
    },
    isCategoriesPopupOpened() {
      return this.$store.getters['ManagePopupStore/isPopupOpened'](
        PopupNamesEnum.CREATE_CATEGORY
      );
    },
    close() {
      this.$store.dispatch('AnnotationsStore/clearEditingAnnotationData');
      this.closeSmallPopups();
      this.$store.dispatch('ManagePopupStore/closePopup', {
        name: PopupNamesEnum.CREATE_ANNOTATION_POPUP
      });
    }
  }
};
</script>

<style lang="less" scopped src="./CreateAnnotationPopup.less"></style>
