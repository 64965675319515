import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('utils/publicationUtils.js');

import dayJS from '@/dayJS';
import groupBy from 'lodash/groupBy';
import base62 from '@shared/utils/base62.mjs';
import AppStateEnum from '@/enums/AppStateEnum';
import CustomCategoriesEnum from '@shared/enums/CustomCategoriesEnum';
import difficultyStatus from '@shared/publication/difficultyStatus';
import PublicationsTypesEnum from '@shared/enums/PublicationsTypesEnum';
import LibraryCategoriesSortedEnum from '@/enums/LibraryCategoriesSortedEnum';

import { localize } from '@/i18n';

function isAuthorInTitle() {
  return false;
}

// http://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
function guid(dashes) {
  var dash = dashes ? '-' : '';

  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return (
    s4() +
    s4() +
    dash +
    s4() +
    dash +
    s4() +
    dash +
    s4() +
    dash +
    s4() +
    s4() +
    s4()
  );
}

const sortPublicationEnum = {
  WEIGTH: 'weight',
  DIFFICULTY: 'difficulty',
  AUTHOR: 'author',
  ALPHABET: 'alphabet',
  FAVORITES_DATE: 'favoritesDate',
  DATE: 'date',
  NEW_ORDER: 'newOrder',
  LAST_READING_TIME: 'lastReadingTime',
  PUBLISH_DATE: 'publishDate'
};

const weigthEnum = {
  DEFAULT_WEIGTH: 5,
  DEFAULT_COLLECTION_WEIGHT: 12,
  MIN_AUTHOR_WEIGTH: 10.99
};

function sortPublication(publications, type, sortOptions = {}) {
  switch (type) {
    case sortPublicationEnum.WEIGTH:
      return sortPublicationByWeight(publications);
    case sortPublicationEnum.AUTHOR:
      return sortPublicationByAuthor(publications);
    case sortPublicationEnum.DIFFICULTY:
      return sortPublicationByDifficulty(publications);
    case sortPublicationEnum.ALPHABET:
      return sortPublicationByAlphabet(publications);
    case sortPublicationEnum.DATE:
      return sortPublicationByDate(publications, sortOptions);
    case sortPublicationEnum.LAST_READING_TIME:
      return sortPublicationByLastReadingTime(publications);
    case sortPublicationEnum.FAVORITES_DATE:
      return sortPublicationByFavoritesDate(publications, sortOptions);
    case sortPublicationEnum.NEW_ORDER:
      return sortPublicationByNewOrder(publications, sortOptions);
    case sortPublicationEnum.PUBLISH_DATE:
      return sortPublicationByPublishDate(publications, sortOptions);
    default:
      logger.error('get unsupported publication sort type ' + type);
      break;
  }

  return publications;
}

function sortPublicationByWeight(publications) {
  const sortValGetter = function(publication) {
    const sortProp = 'weight';
    const val = publication[sortProp];
    if (!publication.audio) {
      return (val || 1) * 100;
    }
    return val;
  };
  const defaultValGetter = function(publication) {
    return publication.type === PublicationsTypesEnum.COLLECTION
      ? weigthEnum.DEFAULT_COLLECTION_WEIGHT
      : weigthEnum.DEFAULT_WEIGTH;
  };
  var groupSortProperty = ['author', 'name'];
  var direction = -1;
  return _sortInGroup(
    publications,
    sortValGetter,
    groupSortProperty,
    direction,
    defaultValGetter
  );
}
function sortPublicationByDifficulty(publications) {
  const sortValGetter = function(publication) {
    const sortProp = 'difficulty';
    const val = difficultyStatus.getSortWeight(publication[sortProp]);
    if (!publication.audio) {
      return difficultyStatus.getDefaultVal() + val;
    }
    return val;
  };
  var groupSortProperty = ['author', 'name'];
  var direction = -1;
  return _sortInGroup(
    publications,
    sortValGetter,
    groupSortProperty,
    direction,
    difficultyStatus.getDefaultVal()
  );
}

function sortPublicationByAlphabet(publications) {
  const sortValGetter = function(publication) {
    const sortProp = 'name';
    return publication[sortProp];
  };
  const groupSortProperty = 'name';
  const direction = -1;
  const defaultVal = '';
  return _sortInGroup(
    publications,
    sortValGetter,
    groupSortProperty,
    direction,
    defaultVal
  );
}

function sortPublicationByDate(publications, { ids }) {
  const bookIdMap = ids.reduce((idMap, id, index) => {
    idMap[id] = index;
    return idMap;
  }, {});
  const sortValGetter = function(publication) {
    return bookIdMap[publication.id] || 0;
  };
  const groupSortProperty = 'name';
  const direction = -1;
  const defaultVal = '';
  return _sortInGroup(
    publications,
    sortValGetter,
    groupSortProperty,
    direction,
    defaultVal
  );
}

function sortPublicationByNewOrder(publications, { newBookIds }) {
  const bookIdMap = newBookIds.reduce((idMap, id, index) => {
    idMap[id] = index;
    return idMap;
  }, {});
  const sortValGetter = function(publication) {
    return bookIdMap[publication.id] || 0;
  };
  return publications.sort(function(publicationA, publicationB) {
    return sortValGetter(publicationA) - sortValGetter(publicationB);
  });
}

function sortPublicationByLastReadingTime(publications) {
  return publications.sort(
    (recentItemA, recentItemB) =>
      recentItemB.lastReadingTime - recentItemA.lastReadingTime
  );
}

function sortPublicationByPublishDate(publications) {
  return publications.sort(
    (recentItemA, recentItemB) =>
      new Date(recentItemB.date) - new Date(recentItemA.date)
  );
}

function sortPublicationByFavoritesDate(publications, { favorites = {} }) {
  const sortValGetter = publication => favorites[publication.id] || 0;
  const groupSortProperty = 'name';
  const direction = 1;
  const defaultVal = '';
  const sorted = _sortInGroup(
    publications,
    sortValGetter,
    groupSortProperty,
    direction,
    defaultVal
  );
  return sorted;
}

function sortPublicationByAuthor(publications) {
  var direction = -1;
  publications.sort(function(publicationA, publicationB) {
    if (publicationB.author > publicationA.author) {
      return direction;
    }
    return -direction;
  });
  return publications;
}

function sortCompilations(publications) {
  var sortProp = 'modifiedAt';
  var groupSortProperty = 'title';
  var direction = 1;
  return _sortInGroup(
    publications,
    sortProp,
    groupSortProperty,
    direction,
    weigthEnum.DEFAULT_WEIGTH
  );
}

function _sortInGroup(
  array,
  groupProperty,
  subGroupProp,
  direction,
  defaultVal
) {
  const defaultValGetter = pub =>
    typeof defaultVal === 'function' ? defaultVal(pub) : defaultVal;
  const valGetter =
    typeof groupProperty === 'string'
      ? pub => pub[groupProperty] ?? defaultValGetter(pub)
      : groupProperty;
  const collator = new Intl.Collator(undefined, {
    usage: 'sort',
    numeric: true
  });

  array.sort(function(publicationA, publicationB) {
    let propA = valGetter(publicationA);
    let propB = valGetter(publicationB);

    if (propA === propB) {
      if (
        typeof subGroupProp === 'string' &&
        publicationB[subGroupProp] > publicationA[subGroupProp]
      ) {
        return direction;
      }
      if (Array.isArray(subGroupProp)) {
        if (subGroupProp.length > 2) {
          throw new Error(
            "3 sort props don't support, implement recursive function"
          );
        }
        const prop1 = subGroupProp[0];
        const prop2 = subGroupProp[1];
        const comparisonProp1 = collator.compare(
          publicationB[prop1],
          publicationA[prop1]
        );
        if (comparisonProp1 !== 0) {
          return comparisonProp1 * direction;
        }
        return (
          collator.compare(publicationB[prop2], publicationA[prop2]) * direction
        );
      }
      return -direction;
    }
    if (propB > propA) {
      return direction;
    }
    return -direction;
  });
  return array;
}

const sortCategoriesCollator = new Intl.Collator(undefined, {
  usage: 'sort',
  numeric: true
});
function sortByCategory(a, b, brand) {
  const categoriesOrder = LibraryCategoriesSortedEnum[brand];
  var indexA = categoriesOrder.indexOf(a.trim());
  var indexB = categoriesOrder.indexOf(b.trim());

  if (indexA === -1 && indexB === -1) {
    return sortCategoriesCollator.compare(a.trim(), b.trim());
  }
  if (indexA === -1) {
    return 1;
  }
  if (indexB === -1) {
    return -1;
  }

  return indexA - indexB;
}

function countPublicationItems(publications, includeCollectionBooks = true) {
  const set = new Set();
  publications.forEach(pub => {
    if (
      pub.type === PublicationsTypesEnum.COLLECTION &&
      pub.items?.length &&
      includeCollectionBooks
    ) {
      pub.items.forEach(p => set.add(p.id));
    } else if (
      pub.type === PublicationsTypesEnum.BOOK ||
      pub.type === PublicationsTypesEnum.SUGGESTED_BOOK
    ) {
      set.add(pub.id);
    }
  });
  return set.size;
}

function _getCategoryKey(category) {
  return `ManagePublications.category.title.${category}`;
}

function getCategoryLocalizationKey(category, $t = k => k) {
  if (!category || typeof category !== 'string') {
    return '';
  }
  const normalizedCategoryName = category.trim().toLowerCase();
  const localizationKey = _getCategoryKey(normalizedCategoryName);
  const localizedValue = $t(localizationKey);
  if (localizedValue && localizedValue !== localizationKey) {
    return localizedValue;
  }
  return category;
}

function getAuthorsRoutesParams(pub) {
  if (!pub?.author) {
    return null;
  }
  const getAuthorRoute = (slug, name) => {
    return {
      name: AppStateEnum.AUTHOR_PAGE,
      params: {
        authorSlug: slug,
        author: name
      }
    };
  };
  if (pub.authorList?.length) {
    return pub.authorList.map(author => {
      let slug = author.slug;
      slug = slug || base62.stringToBase62(pub.author.trim());
      return getAuthorRoute(slug, author.name || pub.author);
    });
  }
  return [getAuthorRoute(base62.stringToBase62(pub.author.trim()), pub.author)];
}

function checkIsCategoryKeyExists(category = '', $te) {
  const normalizedCategoryName = category.trim().toLowerCase();
  return typeof $te === 'function'
    ? $te(_getCategoryKey(normalizedCategoryName))
    : false;
}

function restoreWordCountFromReadingTime(readingTime = 0) {
  //id, name
  const wordsCount = Math.round(readingTime / 60000) * 140;
  // logger.warn(
  //   `restore words count by reading time wordsCount:${wordsCount} for publication ${id} name:${name}`
  // );
  return wordsCount;
}

function getPublishDate(date) {
  return date ? dayJS.get(date).format('DD MMM YYYY') : '';
}

function getPublicationLink(pub) {
  return pub.type === PublicationsTypesEnum.COLLECTION
    ? {
        name: AppStateEnum.COLLECTION,
        params: { id: pub.slug || pub.id }
      }
    : {
        name: AppStateEnum.PRESENT_PUBLICATION,
        params: { slug: pub.slug || pub.id }
      };
}

function getPubsLevelRangesLabel(publications, $t = k => k) {
  const levels = publications.map(p => p.difficulty);
  const min = Math.min(...levels);
  const max = Math.max(...levels);
  const label =
    min === max
      ? $t('ManagePublications.category.level', { level: max })
      : $t('ManagePublications.category.levels', {
          min,
          max
        });
  return label;
}

function groupPublicationsByCategory(pubs, store) {
  const brand = store.getters['ContextStore/brand'];
  let publications = groupBy(pubs, 'category');
  const sortedPublications = [];
  Object.keys(publications)
    .sort((a, b) => sortByCategory(a, b, brand))
    .forEach(name => {
      let newBookIds;
      let strategy = store.getters['ContextStore/librarySortStrategy'];
      if (name === CustomCategoriesEnum.RECENT) {
        strategy = sortPublicationEnum.LAST_READING_TIME;
        const getRecentBooks = store.getters['RecentBookStore/getRecentBooks'];
        const lastReadingTimeByIds = {};
        getRecentBooks.forEach(recentBook => {
          lastReadingTimeByIds[recentBook.publicationId] =
            recentBook.lastReadingTime;
        });
        publications[name].forEach(publication => {
          if (lastReadingTimeByIds[publication.id]) {
            publication.lastReadingTime = lastReadingTimeByIds[publication.id];
          }
        });
      }
      if (name === CustomCategoriesEnum.NEW) {
        strategy = sortPublicationEnum.NEW_ORDER;
        newBookIds = store.getters['LibraryStore/getNewBookIds'];
      }
      const sortedPubs = sortPublication(publications[name], strategy, {
        newBookIds
      });

      sortedPublications.push({
        levelLabel: _getLevelsLabel(name, sortedPubs),
        categoryName: name,
        publications: sortedPubs
      });
    });

  return sortedPublications;
}

function _getLevelsLabel(categoryName, pubs) {
  if (categoryName !== CustomCategoriesEnum.SUGGESTED_BOOK) {
    return;
  }
  return getPubsLevelRangesLabel(pubs, localize);
}

function createAuthorList(publications) {
  const authorMap =
    publications?.reduce((obj, pub) => {
      if (pub && pub?.author) {
        const author = pub.author.trim();
        const authorList = pub.authorList || [];

        if (authorList.length) {
          authorList.forEach(({ name, slug }) => {
            if (!name) {
              return;
            }
            if (!slug) {
              const hashSlug = base62.stringToBase62(name.trim());
              if (!obj.hasOwnProperty(hashSlug)) {
                obj[hashSlug] = {
                  author: name,
                  authorSlug: '',
                  number: 0
                };
              }
              obj[hashSlug].number += 1;
              return obj;
            }

            if (!obj.hasOwnProperty(slug)) {
              obj[slug] = {
                author: name,
                authorSlug: slug,
                number: 0
              };
            }
            obj[slug].number += 1;
          });
          return obj;
        }

        const hashSlug = base62.stringToBase62(pub.author.trim());
        if (!obj.hasOwnProperty(hashSlug)) {
          obj[hashSlug] = {
            author,
            authorSlug: '',
            number: 0
          };
        }
        obj[hashSlug].number += 1;
      }
      return obj;
    }, {}) || {};

  const authorListArr = Object.keys(authorMap).reduce((arr, slugKey) => {
    const { author, number, authorSlug } = authorMap[slugKey];
    arr.push({
      author,
      number,
      authorSlug
    });
    return arr;
  }, []);
  authorListArr.sort((authA, authB) => {
    return authB.number - authA.number;
  });

  return authorListArr;
}

export default {
  weigthEnum,
  countPublicationItems,
  sortPublicationEnum,
  sortPublication,
  sortByCategory,
  sortCompilations,
  isAuthorInTitle,
  getCategoryLocalizationKey,
  checkIsCategoryKeyExists,
  restoreWordCountFromReadingTime,
  guid,
  getPublishDate,
  getPublicationLink,
  getAuthorsRoutesParams,
  getPubsLevelRangesLabel,
  groupPublicationsByCategory,
  createAuthorList
};
