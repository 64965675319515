import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('WikiStore.js');
import WikiService from '@/services/WikiService';
import UnifiedSettingsService from '@/services/UnifiedSettingsService';

import englishUtils from '@shared/contentAnalyzer/english/englishUtils';
import englishWordsUtils from '@shared/contentAnalyzer/english/englishWordsUtils.mjs';
import TextUtils from '@shared/publication/dom-utils/text-utils.mjs';
import languageUtils from '@shared/contentAnalyzer/languageUtils.mjs';

import DictionaryPopupTabIds from '@/enums/DictionaryPopupTabIds';

import get from 'lodash/get';

const TRANSLATOR_SETTINGS = 'TranslatorSettings';
const TRANSLATOR_SETTING_KEY = 'translationLanguage';
const DEFAULT_LANG_CODE = 'en';

const PREPOSITIONS = ['a', 'an', 'the'];
class WikiExplanations {
  constructor(term) {
    this.term = term;
    this.wikiUrl = '';
    this.fullExplanation = '';
  }

  addFullExplanation(fullExplanation) {
    this.fullExplanation = fullExplanation;
  }

  setWikiUrl(wikiUrl) {
    this.wikiUrl = wikiUrl;
  }

  isEmpty() {
    return !this.fullExplanation;
  }
}

const initState = () => ({
  lastOpenedTabId: DictionaryPopupTabIds.EXPLORE,
  dictionaryEditorContent: {},
  supportedLanguages: [],
  supportedLanguagesByCode: {},
  translatorLanguage: {},
  definitions: {},
  currentDefinition: {},
  wikiExplanations: {},
  translations: {},
  currentTranslation: '',
  currentText: '',
  currentWords: [],
  exploreData: {
    term: '',
    sentence: '',
    translatorLanguage: {
      name: '',
      language: ''
    },
    sentenceLanguage: '',
    selectedLocator: {},
    publicationId: ''
  },
  currentExploreDefinition: {},
  exploreDefinitionsCache: {},
  isTranslateLanguageUpdating: false
});

const storeGetters = {
  isTranslateLanguageUpdating: state => {
    return state.isTranslateLanguageUpdating;
  },
  currentDefinition: state => {
    return state.currentDefinition || {};
  },
  getCachedExploreDefinitions: state => key => {
    return state.exploreDefinitionsCache[key];
  },
  currentExploreDefinition: state => {
    return state.currentExploreDefinition || {};
  },
  isExploreDefinitionEmpty: (state, getters) => {
    const definition = getters.currentExploreDefinition;
    const isEmpty =
      !definition?.term?.length &&
      !definition?.synonyms?.length &&
      !definition?.meaning?.length &&
      !definition?.translation?.length;

    return isEmpty;
  },
  currentExploreDefinitionComponentView: (state, getters) => {
    const definition = getters.currentExploreDefinition;
    const exploreData = getters.dataForExplore;
    const aiTranslation =
      typeof definition.translation === 'string'
        ? definition.translation
        : (definition.translation || []).join(', ');

    return {
      ...definition,
      synonyms: (definition.synonyms || []).join(', '),
      translation: aiTranslation,
      translatorLanguageName: (
        exploreData?.translatorLanguage?.language || ''
      ).toUpperCase()
    };
  },
  currentExploreDefinitionEditView: (state, getters) => {
    const currentExploreDefinitionView =
      getters.currentExploreDefinitionComponentView;
    let content = '';

    if (currentExploreDefinitionView.term) {
      content += `<p><strong>${currentExploreDefinitionView.term}</strong></p>`;
    }
    if (currentExploreDefinitionView.synonyms) {
      content += `<p><em style="color: #838485;">${currentExploreDefinitionView.synonyms}</em></p>`;
    }
    if (currentExploreDefinitionView.meaning) {
      content += `<p>${currentExploreDefinitionView.meaning}</p>`;
    }
    if (currentExploreDefinitionView.translation) {
      let translation = currentExploreDefinitionView.translation;
      const isAITranslation =
        currentExploreDefinitionView.synonyms &&
        currentExploreDefinitionView.meaning;

      if (isAITranslation) {
        translation = `<strong>${currentExploreDefinitionView.translatorLanguageName}: </strong>${currentExploreDefinitionView.translation}`;
      }
      content += `<p>${translation}</p>`;
    }

    return content;
  },
  wikiExplanations: state => {
    return state.wikiExplanations || {};
  },
  getCurrentTranslation: state => {
    return state.currentTranslation;
  },
  getLastOpenedDictionaryTabId: state => {
    return state.lastOpenedTabId;
  },
  getTranslatorLanguage: state => {
    return state.translatorLanguage;
  },
  translatorLanguages: (state, getters, rootState, rootGetters) => {
    return state.supportedLanguages.filter(
      lang => lang.language !== rootGetters['BookStore/getBookLanguage']
    );
  },
  getSupportedLanguages: state => {
    return state.supportedLanguages;
  },
  defaultLanguage: state => {
    const translatorLanguage = state.translatorLanguage?.language;
    const isAllowedLanguage = translatorLanguage;
    return isAllowedLanguage ? translatorLanguage : DEFAULT_LANG_CODE;
  },
  getWordDictionaryDefinition: state => {
    return state.currentDefinition;
  },
  noteTextFromDefinition: state => {
    const shortDefinition = state.currentDefinition.shortView[0][0].lines[0];
    let noteText = `${shortDefinition.define}`;
    if (shortDefinition.examples.length) {
      noteText += ` Example: ${shortDefinition.examples[0]}`;
    }
    return noteText;
  },
  termForDefinition: state => {
    return state.currentWords[0];
  },
  dataForExplore: state => {
    return state.exploreData;
  },
  isValidWordsForTermDefinition: state => {
    const len = state.currentWords.length;
    return len > 0 && len <= 1;
  },
  termForExplanation: state => {
    return state.currentWords.join(' ');
  },
  isValidWordsForWikiTerm: state => {
    const len = state.currentWords.length;
    return len > 0 && len <= 3;
  },
  getEditorContentById: state => id => {
    return state.dictionaryEditorContent[id] || null;
  }
};

const actions = {
  async initTranslatorLanguages({ commit, rootGetters, state, dispatch }) {
    const languages = await WikiService.getTranslatorLanguages();
    commit('setTranslatorLanguages', languages);
    let selectedLanguage = UnifiedSettingsService.getSetting(
      TRANSLATOR_SETTINGS,
      TRANSLATOR_SETTING_KEY
    );
    const bookLang = rootGetters['BookStore/getBookLanguage'];
    if (!selectedLanguage || selectedLanguage.language === bookLang) {
      selectedLanguage = state.supportedLanguagesByCode[DEFAULT_LANG_CODE];

      if (!selectedLanguage || selectedLanguage.language === bookLang) {
        const langCode = rootGetters['ContextStore/getSystemLanguage'];
        selectedLanguage = state.supportedLanguagesByCode[langCode];
      }
    }
    const skipSettingSync = rootGetters['ContextStore/isBlogApp'];
    dispatch('setTranslatorLanguage', { selectedLanguage, skipSettingSync });
  },
  createExploreData(
    { commit, rootGetters },
    {
      selectedLocator,
      publicationId,
      selectedPara,
      sentenceLanguage,
      translatorLanguage
    }
  ) {
    const isOnline = rootGetters['ContextStore/isOnline'];
    if (!isOnline) {
      return;
    }
    if (!selectedLocator || !selectedLocator.toJSON || !publicationId) {
      logger.error(
        `Try create explore data for dictionary with invalid selectedLocator: ${selectedLocator} publicationId: ${publicationId}`
      );
      return;
    }
    const selectedText = TextUtils.extractContentByRangeLocator(
      selectedLocator
    );
    const term = _getFirstSentence(selectedText?.trim());
    if (!term) {
      logger.error(
        `Did not find term to create explore data for dictionary by selectedLocator: ${selectedLocator?.toJSON()}`
      );
      return;
    }
    const paraText = TextUtils.extractContent(selectedPara)?.trim() || '';
    const sentences = languageUtils.parseSentences(paraText, sentenceLanguage);

    let previousSentenceLogicalCharOffset = 0;
    let sentence = '';
    for (let index = 0; index < sentences.length; index++) {
      let sentencesRealOffsets = TextUtils.collectWordsStableOffsets(
        sentences[index] || ''
      );
      const lastSentenceLogicalCharOffset =
        sentencesRealOffsets[sentencesRealOffsets.length - 1][1] +
        previousSentenceLogicalCharOffset;
      const selectedLogicalCharOffset =
        selectedLocator.startLocator.logicalCharOffset;
      if (lastSentenceLogicalCharOffset > selectedLogicalCharOffset) {
        sentence = sentences[index];
        break;
      }
      previousSentenceLogicalCharOffset +=
        sentencesRealOffsets[sentencesRealOffsets.length - 1][1];
    }
    commit('setExploreData', {
      term,
      sentence,
      translatorLanguage,
      sentenceLanguage,
      selectedLocator,
      publicationId
    });
  },
  async processExploreData(
    { commit, getters },
    {
      term,
      sentence,
      translatorLanguage,
      sentenceLanguage,
      selectedLocator,
      publicationId
    }
  ) {
    const serializedWordLocator = selectedLocator?.toJSON() || '';
    const key = `${term}_${publicationId}_${serializedWordLocator}_${translatorLanguage?.name}`;
    const cachedDefinition = getters.getCachedExploreDefinitions(key);
    if (cachedDefinition) {
      commit('setExploreDefinition', cachedDefinition);
      return;
    }
    const res = await WikiService.getExploreDefinition({
      term,
      sentence,
      translatorLanguage,
      sentenceLanguage
    });
    if (!res.data) {
      commit('addExploreDefinition', { key, definitions: {} });
      return;
    }
    const definitions = res.data;
    commit('addExploreDefinition', { key, definitions });
  },
  async processSelectedTerm({ commit, state }, term) {
    if (state.definitions[term]) {
      commit('setCurrentDefinition', state.definitions[term]);
      return;
    }
    const res = await WikiService.getWiktionaryDefinition(term);
    if (!res.data) {
      commit('addTermDefinition', { term, definitions: {} });
      return;
    }
    const definitions = res.data;
    commit('addTermDefinition', { term, definitions });
  },
  cancelAllRequests() {
    WikiService.cancelAllRequests();
  },
  removeWikiExplanation({ commit }) {
    commit('removeWikiExplanation');
  },
  async addWikiExplanation({ commit, state }, term) {
    if (state.wikiExplanations[term]) {
      return;
    }
    commit('initWikiExplanations', term);
    const res = await WikiService.getWikiExplanation(term);
    if (!res.data || !res.data.length) {
      return;
    }
    const pages = res.data;
    pages.forEach(page => {
      if (page.extract) {
        commit('addWikiExplanation', page);
      } else {
        commit('addWikiExplanation', { extract: '', wikiUrl: '' });
      }
    });
  },
  async addTranslation(
    { commit, state, getters, rootGetters, dispatch },
    { targetLang, skipSync = false }
  ) {
    targetLang = targetLang || getters.defaultLanguage;
    const selectedLanguage = state.supportedLanguagesByCode[targetLang];
    if (!selectedLanguage) {
      logger.warn(`no language found for translation targetLang:${targetLang}`);
      return;
    }
    const skipSettingSync = skipSync || rootGetters['ContextStore/isBlogApp'];
    commit('updateTranslateSetting', true);
    dispatch('setTranslatorLanguage', { selectedLanguage, skipSettingSync });
    commit('updateTranslateSetting', false);
    const term = _getFirstSentence(state.currentText);
    const bookLang = rootGetters['BookStore/getBookLanguage'];
    const locator = rootGetters['MaterialsStore/getSelectedLocator'];
    const paraId = get(locator, 'startLocator.prefixedParagraphId', '');
    if (!paraId) {
      logger.warn(`No paraId in translation: ${paraId}`);
      return;
    }
    const paraMeta = rootGetters['BookStore/getPublicationParaMeta'](paraId);
    const sourceLang = paraMeta.language || bookLang;
    const res = await WikiService.getTranslation(term, targetLang, sourceLang);
    let translation = '';

    if (!res.data || Object.keys(res.data).length === 0) {
      logger.warn(`no data in translation response`);
      translation = term;
    } else {
      let translatedText = get(
        res,
        'data.translations[0].translatedText',
        null
      );
      translation = _extractCleanTranslation(translatedText);
    }

    commit('setTranslation', { term, translation, targetLang });
  },
  changeEditorContent({ commit }, data) {
    commit('changeEditorContent', data);
  },
  resetEditorContent({ commit }) {
    commit('resetEditorContent');
  },
  async setTranslatorLanguage(
    { commit },
    { selectedLanguage, skipSettingSync }
  ) {
    await UnifiedSettingsService.setSetting(
      TRANSLATOR_SETTINGS,
      TRANSLATOR_SETTING_KEY,
      selectedLanguage,
      skipSettingSync
    );
    commit('setTranslatorLanguage', selectedLanguage);
  }
};

function _getFirstSentence(text) {
  return text && text.split('.')[0];
}

const mutations = {
  updateTranslateSetting(state, isUpdating) {
    state.isTranslateLanguageUpdating = isUpdating;
  },
  setTranslatorLanguageForExploreData(state, lang) {
    state.exploreData.translatorLanguage = lang;
  },
  setTranslatorLanguage(state, lang) {
    state.translatorLanguage = lang;
  },
  setTranslatorLanguages(state, languages) {
    state.supportedLanguages = languages;
    for (let lang of languages) {
      state.supportedLanguagesByCode[lang.language] = lang;
    }
  },
  setLastOpenedTabId(state, tabId) {
    state.lastOpenedTabId = tabId;
  },
  setCurrentText(state, text) {
    state.currentText = text;
    let words = englishWordsUtils.splitByWords(text) || [];
    if (_isEnglish(words)) {
      words = words.map(word => englishUtils.replaceDiacritic(word));
    }
    if (words.length < 3) {
      words = words.filter(
        word => !PREPOSITIONS.some(prep => prep === word.toLowerCase())
      );
    }
    state.currentWords = words;
  },
  addWikiExplanation(state, { extract, wikiUrl }) {
    const explanation = _extractCleanExplanation(extract);
    state.wikiExplanations.addFullExplanation(explanation);
    state.wikiExplanations.setWikiUrl(wikiUrl);
  },
  removeWikiExplanation(state) {
    state.wikiExplanations = {};
  },
  initWikiExplanations(state, term) {
    state.wikiExplanations = new WikiExplanations(term);
  },
  setCurrentWord(state, word) {
    state.currentWord = word;
  },
  addTermDefinition(state, { term, definitions = {} }) {
    state.definitions[term] = definitions;
    state.currentDefinition = definitions;
  },
  setCurrentDefinition(state, definition) {
    state.currentDefinition = definition;
  },
  addExploreDefinition(state, { key, definitions = {} }) {
    state.exploreDefinitionsCache[key] = definitions;
    state.currentExploreDefinition = definitions;
  },
  setExploreDefinition(state, definition) {
    state.currentExploreDefinition = definition;
  },
  setExploreData(state, exploreData) {
    state.exploreData = exploreData;
  },
  setTranslation(state, { term, translation, targetLang }) {
    state.currentTranslation = translation;
    if (!state.translations[targetLang]) {
      state.translations[targetLang] = {};
    }
    state.translations[targetLang][term] = translation;
  },
  changeEditorContent(state, { id, content }) {
    state.dictionaryEditorContent[id] = content;
  },
  resetEditorContent(state) {
    state.dictionaryEditorContent = {};
  }
};

function _extractCleanExplanation(explanation) {
  const reTeXCommands = /{\\displaystyle.*}/gm;
  return explanation.replace(reTeXCommands, '');
}

function _extractCleanTranslation(translation) {
  translation = translation.replace(/&#39;/g, `'`);
  translation = translation.replace(/&quot;/g, `"`);
  return translation;
}

function _isEnglish(englishWords) {
  const numberRe = /^\d+$/;
  const wordsWithoutNumbers = englishWords.filter(word => !numberRe.test(word));
  return !!wordsWithoutNumbers.length;
}

export default {
  state: initState,
  getters: storeGetters,
  actions,
  mutations
};
