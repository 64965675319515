<template>
  <div class="search-component-block">
    <div class="search-element-wrapper">
      <BaseSpriteIcon :icon-name="iconSearchName" />
    </div>
    <div class="search-input-wrapper">
      <input
        v-model.trim="fieldModel"
        type="text"
        tabindex="0"
        name="searchField"
        :placeholder="$t('SearchBanner.searchField.placeholder')"
        :maxlength="150"
        @input="onInput"
      />
    </div>
    <div v-if="fieldModel" class="search-element-wrapper">
      <BaseButton fab small elevation="0" color="#fff" @click="clearField">
        <BaseSpriteIcon :icon-name="iconClearName" custom-class="-big-icon" />
      </BaseButton>
    </div>
    <div class="search-element-wrapper">
      <LanguagesSwitcher
        :languages-list="languagesList"
        :current-lang="language"
        :z-index="12"
        @onLanguageChanged="onLanguageChanged"
      >
        <template #activator="{ on }">
          <BaseButton fab small elevation="0" color="#fff" v-on="on">
            <BaseSpriteIcon
              :icon-name="iconHiddenMenuName"
              custom-class="-big-icon"
            />
          </BaseButton>
        </template>
      </LanguagesSwitcher>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';

import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon';
import BaseButton from '@/components/base/BaseButton/BaseButton';
import LanguagesSwitcher from '@/components/base/LanguagesSwitcher/LanguagesSwitcher';
import AppStateEnum from '@/enums/AppStateEnum';

export default {
  name: 'FakeSearchControl',
  components: {
    BaseSpriteIcon,
    BaseButton,
    LanguagesSwitcher
  },
  data() {
    return {
      iconSearchName: 'ico-search',
      iconHiddenMenuName: 'ico-hidden-menu',
      iconClearName: 'ico-clear',
      fieldModel: '',
      languagesList: this.$store.getters[
        'ContextStore/getLibraryLanguages'
      ].filter(lang => lang !== 'pt'),
      language: this.$store.getters['ContextStore/currentLanguageGetter'],
      MIN_SEARCH_LENGTH: 3
    };
  },
  methods: {
    clearField() {
      this.fieldModel = '';
    },
    onLanguageChanged({ language }) {
      this.$store.commit('ContextStore/setCurrentLanguage', language);
      if (
        AppStateEnum.MANAGE_PUBLICATION !== this.$route.name &&
        AppStateEnum.MANAGE_PUBLICATION_LANGUAGE !== this.$route.name
      ) {
        return;
      }

      if (
        language === 'en' &&
        AppStateEnum.MANAGE_PUBLICATION !== this.$route.name
      ) {
        this.$router.push({
          name: AppStateEnum.MANAGE_PUBLICATION
        });
      } else {
        this.$router.push({
          name: AppStateEnum.MANAGE_PUBLICATION_LANGUAGE,
          params: { pathMatch: language }
        });
      }
    },
    onInput: debounce(function(event) {
      const newValue = event.target.value;
      if (newValue.length >= this.MIN_SEARCH_LENGTH) {
        this.performSearch(newValue);
      }
    }, 300),
    performSearch(query) {
      if (query?.length >= this.MIN_SEARCH_LENGTH) {
        this.$store.commit('SwSearchStore/setShouldTriggerInput', false);
        this.$store.dispatch('SwSearchStore/setSearchText', query);
        this.$router.push({ name: AppStateEnum.SEARCH });
      }
    }
  }
};
</script>

<style lang="less">
.search-component-block {
  display: flex;
  box-shadow: inset 0 0 0 1px #cbd5e1;
  border-radius: 22px;
  min-height: 44px;
  align-items: center;
  transition: box-shadow 0.2s ease;

  &:has(input:focus) {
    box-shadow: inset 0 0 0 2px var(--primary-color);
  }

  > * {
    flex: 0 0 auto;

    &:nth-child(2) {
      flex: 1 1 auto;
    }
  }

  .search-element-wrapper {
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input {
    width: 100%;

    #app & {
      &::-webkit-input-placeholder {
        color: #94a3b8;
      }
    }
  }

  .-big-icon {
    width: 24px;
    height: 24px;
  }
}
</style>
