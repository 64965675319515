import StudyClassTypes from '@/enums/StudyClassTypes';
import AssetResourcesEnum from '@/enums/AssetResourcesEnum';
import publicationUtils from '@/services/utils/publicationUtils';
import formatUtils from '@shared/utils/formatUtils';

import CompilationsService from '@/services/CompilationsService';
import CustomCategoriesEnum from '@shared/enums/CustomCategoriesEnum';

class Publication {
  toParsedJson() {
    const keys = Object.keys(this);
    const parsedJson = keys.reduce((obj, key) => {
      const val = this[key];
      if (typeof val !== 'function') {
        obj[key] = val;
      }
      return obj;
    }, {});
    return parsedJson;
  }

  toJSON() {
    const parsedJson = this.toParsedJson();
    return JSON.stringify(parsedJson);
  }
}
class Book extends Publication {
  constructor(attrs) {
    super();
    this.assetsSource = attrs.assetsSource;
    this.isContentDownloaded = attrs.isContentDownloaded || false;
    this.isAudioDownloaded = attrs.isAudioDownloaded || false;
    this.audio = attrs.audio;
    this.slug = attrs.slug || '';
    this.author = formatUtils.authorFormatting(attrs.author);
    this.authorList = attrs.authorList || attrs.author_list;
    this.category = attrs.category || '';
    this.bookCategory = attrs.bookCategory || '';
    this.cover = attrs.cover;
    this.collection = attrs.collection || null;
    this.date = attrs.date;
    this.difficulty = attrs.difficulty;
    this.id = attrs.id;
    this.ilmId = attrs.ilmId || '';
    this.language = attrs.language;
    this.level = attrs.level;
    this.name = attrs.name;
    this.readingTime = Math.abs(attrs.readingTime);
    this.shortDescription = attrs.shortDescription;
    this.genres = attrs.genres;
    this.type = attrs.type;
    this.version = attrs.version;
    this.weight = attrs.weight;
    this.wordsCount =
      attrs.wordsCount ||
      publicationUtils.restoreWordCountFromReadingTime(
        attrs.readingTime,
        attrs.id,
        attrs.name
      );
    this.toc = attrs.toc || [];
    this.publish = attrs.publish || {};
    this.relatedStudyGuides = attrs.relatedStudyGuides || [];
    this.accessStatus = attrs.accessStatus;
  }

  getPublishVersion() {
    return this.publish?.version?.value || '';
  }

  getPublishDate() {
    return publicationUtils.getPublishDate(this.date);
  }

  toParsedJson() {
    return {
      assetsSource: this.assetsSource,
      isContentDownloaded: this.isContentDownloaded,
      isAudioDownloaded: this.isAudioDownloaded,
      audio: this.audio,
      slug: this.slug,
      author: this.author,
      authorList: this.authorList,
      category: this.category,
      cover: this.cover,
      collection: this.collection,
      date: this.date,
      difficulty: this.difficulty,
      id: this.id,
      ilmId: this.ilmId,
      language: this.language,
      level: this.level,
      name: this.name,
      readingTime: this.readingTime,
      shortDescription: this.shortDescription,
      genres: this.genres,
      type: this.type,
      version: this.version,
      weight: this.weight,
      wordsCount: this.wordsCount,
      toc: this.toc,
      publish: this.publish,
      relatedStudyGuides: this.relatedStudyGuides,
      accessStatus: this.accessStatus
    };
  }

  updateByMeta(meta) {
    this.audio = meta.audio;
    this.author = meta.author;
    this.authorList = meta.authorList || meta.author_list;
    this.category = meta.category;
    this.cover = meta.cover;
    this.collection = meta.collection || null;
    this.date = meta.date;
    this.difficulty = meta.difficulty;
    this.ilmId = meta.ilmId || '';
    this.language = meta.language;
    this.level = meta.level;
    this.name = meta.name;
    this.readingTime = meta.readingTime;
    this.shortDescription = meta.shortDescription;
    this.genres = meta.genres;
    this.version = meta.version;
    this.weight = meta.weight;
    this.wordsCount = meta.wordsCount;
    this.toc = meta.toc || [];
    return this;
  }

  updateAccessStatus(newStatus) {
    this.accessStatus = newStatus;
    return this;
  }

  setRelatedStudyGuides(studyGuides) {
    this.relatedStudyGuides = studyGuides;
    return this;
  }

  setCollectionId(id) {
    this.collection = id;
    return this;
  }

  setCategory(category) {
    this.category = category;
    return this;
  }

  setAccessStatus(accessStatus) {
    this.accessStatus = accessStatus;
    return this;
  }

  setIlmId(ilmId) {
    this.ilmId = ilmId;
    return this;
  }

  setPublishInfo(publish) {
    this.publish = publish;
    return this;
  }
}

class SuggestedBook extends Book {
  constructor(book) {
    super(book);
    this.category = CustomCategoriesEnum.SUGGESTED_BOOK;
    this.bookCategory = book.bookCategory || book.category;
  }
}

class Collection extends Publication {
  constructor(attrs) {
    super();
    this._fillData(attrs);
    this._fillItem(attrs.items);
    this.wordsCount = attrs.wordsCount;
    this.readingTime = attrs.readingTime;
    this.bookCategory = attrs.bookCategory || '';
  }

  getPublishVersion() {
    return this.publish?.version?.value || '';
  }

  getPublishDate() {
    return publicationUtils.getPublishDate(this.date);
  }

  updateAccessStatus(newStatus) {
    this.accessStatus = newStatus;
  }

  setAccessStatus(accessStatus) {
    this.accessStatus = accessStatus;
  }

  toParsedJson() {
    const items = this.items.map(item => {
      return item.toParsedJson();
    });
    return {
      audio: this.audio,
      assetsSource: this.assetsSource,
      author: this.author,
      authorList: this.authorList,
      category: this.category,
      cover: this.cover,
      difficulty: this.difficulty,
      id: this.id,
      items,
      ilmId: this.ilmId,
      language: this.language,
      level: this.level,
      name: this.name,
      date: this.date,
      slug: this.slug,
      version: this.version,
      readingTime: this.readingTime,
      shortDescription: this.shortDescription,
      genres: this.genres,
      type: this.type,
      weight: this.weight,
      publish: this.publish,
      wordsCount: this.wordsCount,
      accessStatus: this.accessStatus
    };
  }
  merge(collection) {
    if (!collection?.items?.length) {
      return;
    }
    this._fillData(collection);
    const localItems = this.items.filter(
      ({ assetsSource }) => assetsSource === AssetResourcesEnum.FS
    );
    const items = collection.items.reduce((result, pub) => {
      const localPub = localItems.find(local => local.id === pub.id);
      let newPub = localPub || pub;
      if (!(newPub instanceof Book)) {
        newPub = new Book(newPub);
        newPub.setCollectionId(this.id).setCategory(this.category);
      }
      result.push(newPub);
      return result;
    }, []);
    const itemsMap = items.reduce((iMap, item) => {
      iMap[item.id] = item;
      return iMap;
    }, {});
    const localItemsOutOfRemote = localItems.reduce((result, pub) => {
      if (!itemsMap.hasOwnProperty(pub.id)) {
        result.push(pub);
      }
      return result;
    }, []);
    this._fillItem([...items, ...localItemsOutOfRemote]);
    this._reCalcCounters(items);
  }
  _fillData(attrs) {
    this.assetsSource = this.assetsSource || attrs.assetsSource;
    this.author = attrs.author;
    this.audio = attrs.audio;
    this.category = attrs.category;
    this.cover = attrs.cover;
    this.difficulty = attrs.difficulty;
    this.id = attrs.id;
    this.ilmId = attrs.ilmId || '';
    this.language = attrs.language;
    this.level = attrs.level;
    this.name = attrs.name;
    this.date = attrs.date;
    this.slug = attrs.slug || '';
    this.version = attrs.version;
    this.shortDescription = attrs.shortDescription;
    this.genres = attrs.genres;
    this.type = attrs.type;
    this.weight = attrs.weight;
    this.accessStatus = attrs.accessStatus;
    this.publish = attrs.publish || {};
  }
  _fillItem(items) {
    this.items = items.map(item => {
      const book = new Book(item);
      book.setCollectionId(this.id).setCategory(this.category);
      return book;
    });
  }
  _reCalcCounters(items) {
    let wordsCount = 0;
    let readingTime = 0;
    items.forEach(pub => {
      wordsCount += pub.wordsCount;
      readingTime += pub.readingTime;
    });
    this.wordsCount = wordsCount;
    this.readingTime = readingTime;
  }
}

class Compilation extends Publication {
  constructor(attrs) {
    super();
    this.audio = attrs.audio || false;
    this.slug = attrs.slug || '';
    this.assetsSource = attrs.assetsSource;
    this.isContentDownloaded = attrs.isContentDownloaded || false;
    this.isAudioDownloaded = attrs.isAudioDownloaded || false;
    this.createdAt = attrs.createdAt;
    this.id = attrs.id;
    this.default = attrs.default;
    this.items = attrs.items.filter(item => !!item) || [];
    this.map = attrs.map;
    this.modifiedAt = attrs.modifiedAt;
    this.selectionsCount = CompilationsService.getSelectionsCount(this.items);
    this.title = attrs.title;
    this.description = attrs.description;
    this.type = attrs.type;
    this.wordsCount = attrs.wordsCount;
    this.isShared = attrs.isShared;
    this.coverFileName = attrs.coverFileName || '';
  }

  toParsedJson() {
    return {
      audio: this.audio,
      isShared: this.isShared,
      assetsSource: this.assetsSource,
      default: this.default,
      isContentDownloaded: this.isContentDownloaded,
      isAudioDownloaded: this.isAudioDownloaded,
      createdAt: this.createdAt,
      id: this.id,
      items: this.items,
      map: this.map,
      modifiedAt: this.modifiedAt,
      selectionsCount: this.selectionsCount,
      slug: this.slug,
      description: this.description,
      title: this.title,
      type: this.type,
      wordsCount: this.wordsCount,
      coverFileName: this.coverFileName || ''
    };
  }

  updateByMeta(meta) {
    this.audio = meta.audio;
    this.default = meta.default;
    this.modifiedAt = meta.modifiedAt;
    this.items = meta.items;
    this.selectionsCount = meta.items.length;
    this.title = meta.title;
    this.description = meta.description;
    this.coverFileName = meta.coverFileName;
  }

  setDefault(isDefault) {
    this.default = isDefault;
  }

  setCoverFileName(fileName) {
    this.coverFileName = fileName;
  }
}

class StudyGuide extends Publication {
  constructor(attrs) {
    super();
    this.assetsSource = attrs.assetsSource;
    this.audio = attrs.audio;
    this.author = attrs.author;
    this.authors = attrs.authors;
    this.bitRate = attrs.bitRate;
    this.bookId = attrs.bookId;
    this.bookSize = attrs.bookSize;
    this.bookVocabularyLevel = attrs.bookVocabularyLevel;
    this.category = attrs.category;
    this.cover = attrs.cover;
    this.coverFile = attrs.coverFile;
    this.description = attrs.description;
    this.difficulty = attrs.difficulty;
    this.exercises = attrs.exercises;
    this.fileName = attrs.fileName;
    this.hasThumbnail = attrs.hasThumbnail;
    this.id = attrs._id;
    this.isOriginal = attrs.isOriginal;
    this.language = attrs.language;
    this.level = attrs.level;
    this.mediaSize = attrs.mediaSize;
    this.mimetype = attrs.mimetype;
    this.name = attrs.name;
    this.originalFileName = attrs.originalFileName;
    this.paraCount = attrs.paraCount;
    this.paragraphsNumber = attrs.paragraphsNumber;
    this.publicationStatus = attrs.publicationStatus;
    this.readingTime = attrs.readingTime;
    this.sourceURL = attrs.sourceURL;
    this.status = attrs.status;
    this.statusModifiedAt = attrs.statusModifiedAt;
    this.studyGuideCopyId = attrs.studyGuideCopyId;
    this.toc = attrs.toc;
    this.totalCharacters = attrs.totalCharacters;
    this.type = attrs.type;
    this.userIds = attrs.userIds;
    this.version = attrs.version;
    this.weight = attrs.weight;
    this.wordsCount = attrs.wordsCount;
    this.wordsPerMinute = attrs.wordsPerMinute;
  }

  setStatus(status) {
    this.publicationStatus = status;
  }

  setStudyGuideCopyId(studyGuideCopyId) {
    this.studyGuideCopyId = studyGuideCopyId;
  }
}

class RelatedStudyGuides extends Publication {
  constructor(id, relatedStudyGuides) {
    super();
    this.id = id;
    this.type = 'RELATED_STUDYGUIDES';
    this._fillRelatedStudyGuides(relatedStudyGuides);
  }

  _fillRelatedStudyGuides(relatedStudyGuides) {
    Object.entries(relatedStudyGuides).forEach(([key, value]) => {
      this[key] = value;
    });
  }

  getByBookId(bookId) {
    return this[bookId];
  }
}

class CollectionItemView {
  constructor(attrs) {
    this.assetsSource = attrs.assetsSource;
    this.author = attrs.author;
    this.name = attrs.name;
    this.showDifficulty = false;
    this.difficulty = attrs.difficulty;
    this.genres = attrs.genres;
    this.id = attrs.id;
    this.categoryLabel = publicationUtils.getCategoryLocalizationKey(
      attrs.category
    );
    this.cover = attrs.cover;
    this.readingTime = attrs.readingTime;
  }
}

class Syllabus extends Publication {
  constructor(attrs) {
    super();
    this.assetsSource = attrs.assetsSource;
    this.audio = attrs.audio;
    this.author = attrs.author;
    this.authorId = attrs.authorId;
    this.category = attrs.category;
    this.description = attrs.description;
    this.id = attrs.id;
    this.items = attrs.items;
    this.language = attrs.language;
    this.lastReadingTime = attrs.lastReadingTime;
    this.level = attrs.level;
    this.matches = attrs.matches;
    this.name = attrs.name;
    this.personal = attrs.personal;
    this.progress = attrs.progress;
    this.publicationStatus = attrs.publicationStatus;
    this.publicationType = attrs.publicationType;
    this.readingDuration = attrs.readingDuration;
    this.readingProgress = attrs.readingProgress;
    this.readingTime = attrs.readingTime;
    this.status = attrs.status;
    this.tableOfContents = attrs.tableOfContents;
    this.type = attrs.type;
    this.userId = attrs.userId;
    this.wordsPerMinute = attrs.wordsPerMinute;
  }

  setStatus(status) {
    this.publicationStatus = status;
  }
}

class LibrarySet extends Publication {
  constructor(attrs) {
    super(attrs);
    this.id = attrs.id;
    this.name = attrs.name;
    this.createdAt = attrs.createdAt;
    this.statusModifiedAt = attrs.statusModifiedAt;
    this.description = attrs.description || '';
    this.publicationIds = attrs.publicationIds || [];
    this.readingTime = attrs.readingTime || 0;
    this.category = attrs.category;
    this.publicationStatus = attrs.publicationStatus;
    this.isOriginal = attrs.isOriginal;
    this.copyId = attrs.copyId;
    this.originalId = attrs.originalId;
    this.type = attrs.type;
    this.price = attrs.price;
    this.prices = attrs.prices;
    this.autoExtendable = attrs.autoExtendable;
  }

  getPublicationIds() {
    return this.publicationIds;
  }

  setStatus(status) {
    this.publicationStatus = status;
  }

  setReadingTime(readingTime) {
    this.readingTime = readingTime;
  }

  toParsedJson() {
    return {
      id: this.id,
      name: this.name,
      createdAt: this.createdAt,
      statusModifiedAt: this.statusModifiedAt,
      description: this.description,
      publicationIds: this.publicationIds,
      readingTime: this.readingTime,
      category: this.category,
      publicationStatus: this.publicationStatus,
      isOriginal: this.isOriginal,
      copyId: this.copyId,
      originalId: this.originalId,
      type: this.type,
      price: this.price,
      prices: this.prices,
      autoExtendable: this.autoExtendable
    };
  }
}

class StudyCourse extends Publication {
  constructor(attrs) {
    super();
    this.assetsSource = attrs.assetsSource;
    this.allowDiscussions = attrs.allowDiscussions;
    this.allowSkipBooks = attrs.allowSkipBooks;
    this.classId = attrs.classId;
    this.classType = attrs.classType;
    this.cover = attrs.cover;
    this.description = attrs.description;
    this.expectedDailyWork = attrs.expectedDailyWork;
    this.expectedDuration = attrs.expectedDuration;
    this.id = attrs.classId;
    this.items = attrs.items;
    this.joinEndDate = attrs.joinEndDate;
    this.modifiedAt = attrs.modifiedAt;
    this.name = attrs.name;
    this.publicationId = attrs.publicationId;
    this.publicationType = attrs.publicationType;
    this.registeredAt = attrs.registeredAt;
    this.scheduledAt = attrs.scheduledAt;
    this.status = attrs.status;
    this.students = attrs.students;
    this.studyWeekDays = attrs.studyWeekDays;
    this.teachers = attrs.teachers;
    this.type = attrs.type;
  }
}

class StudyCourseView {
  constructor(studyCourse) {
    this.classId = studyCourse.classId;
    this.name = studyCourse.name;
    this.teachers = [];
    this.students = (studyCourse.students || []).length || 0;
    this.description = studyCourse.description;
    this.type = studyCourse.type;
    this.isSelfStudy =
      studyCourse.classType === StudyClassTypes.INDEPENDENT_STUDY;
    this.isInvited = false;
    this.readingProgress = 0;
    this.remainingTime = 0;
    this.isCompleted = this.readingProgress === 100;
  }
}

export {
  Book,
  SuggestedBook,
  Collection,
  CollectionItemView,
  RelatedStudyGuides,
  Compilation,
  StudyGuide,
  Syllabus,
  LibrarySet,
  StudyCourse,
  StudyCourseView
};
