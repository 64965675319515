<template>
  <div class="annotations-search-filter">
    <div class="joint-container">
      <FilterInput
        ref="filterInput"
        class="annotations-search-input"
        clearable
        :placeholder="$t('Find note...')"
        @onFilter="onFilterByText"
      />
      <div class="selections-block">
        <v-select
          v-model="category"
          :items="externalData.items"
          :menu-props="{
            bottom: true,
            offsetY: true,
            left: true,
            contentClass: `tags-filter-popup ${
              isTypesFilterVisible ? 'active' : ''
            }`
          }"
          item-text="text"
          class="select-block"
          hide-details
          solo
          @click="toggleTypesFilter"
          @change="onSelectChange"
        >
          <template slot="selection" slot-scope="data">
            <div
              v-if="data.item.value"
              class="circle-icon mr-3"
              :style="{ 'background-color': itemColor(data.item) }"
            ></div>
            <img v-else :src="allTagsIcon" class="all-tags mr-3" />
            <span class="category-name">{{ data.item.text }}</span>
          </template>
          <template #item="{item}">
            <div
              v-if="item.value"
              class="circle-icon mr-3"
              :style="{ 'background-color': itemColor(item) }"
            ></div>
            <img v-else :src="allTagsIcon" class="all-tags mr-3" />
            <span class="category-name">{{ item.text }}</span>
          </template>
        </v-select>
        <BaseButton
          text
          block
          class="tags-filter-button black--text"
          :class="{ expanded: isTagsFilterVisible }"
          @click="toggleTagsFilter"
        >
          <span class="tags-button-name">
            <BaseSpriteIcon icon-name="ico-tag-notes" />
            {{
              chips.length ? $t('Annotations.tags') : $t('Annotations.allTags')
            }}
          </span>
          <BaseSpriteIcon icon-name="ico-arrow-chevron" />
        </BaseButton>
      </div>
      <div v-if="chips.length" class="tags-filter-chips">
        <v-chip
          v-for="c in chips"
          :key="c.id"
          class="tags-filter-chip"
          :close="c.hasCloseButton"
          close-icon="$crossIcon"
          style="background-color: #E2E8F0;"
          :outlined="c.hasCloseButton"
          v-on="c.handlers"
        >
          {{ c.label }}
        </v-chip>
      </div>
      <div v-if="isTagsFilterVisible" class="tags-filter">
        <BaseTextField
          v-model="filterTagsText"
          rounded
          clearable
          hide-details
          :placeholder="$t('Annotations.tags.filterText')"
          clear-icon="$crossIcon"
          :class="['tags-filter-input']"
          @input="tagsInputHandler"
        ></BaseTextField>
        <v-checkbox
          v-for="tag in filteredTags"
          :key="tag"
          v-model="tags[tag]"
          :value="tags[tag]"
          hide-details
          color="primary"
          class="tags-filter-checkbox"
          @change="changeTags(tag, $event)"
        >
          <template #label>
            <span class="checkbox-label">{{ tag }}</span>
          </template>
        </v-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import FilterInput from '@/components/views/FilterInput/FilterInput';
import images from '@/assets/images';
import ThemeClassNamesEnum from '@/enums/ThemeClassNamesEnum';
import BaseButton from '@/components/base/BaseButton/BaseButton.vue';
import BaseTextField from '@/components/base/BaseTextField/BaseTextField.vue';
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon.vue';

const collator = new Intl.Collator(undefined, {
  usage: 'sort',
  numeric: true
});

export default {
  name: 'FilterItem',
  components: {
    FilterInput,
    BaseButton,
    BaseSpriteIcon,
    BaseTextField
  },
  props: { externalData: Object, tagsList: Array },
  data() {
    return {
      allTagsIcon: '',
      clearIcon: 'ico-clear',
      arrowIcon: 'ico-arrow-chevron',
      category: '',
      filterText: '',
      isTypesFilterVisible: false,
      isTagsFilterVisible: false,
      tags: {},
      filteredTags: [],
      filterTagsText: '',
      debouncedInput: debounce(this.updateTags, 500)
    };
  },
  computed: {
    themeName() {
      return this.$store.getters['ReadingSettingsStore/getThemeName'];
    },
    chips() {
      const tagsChips = Object.entries(this.tags)
        .reduce((result, [key, val]) => {
          if (val) {
            result.push(this.$_getTagsChip(key));
          }
          return result;
        }, [])
        .sort((a, b) => collator.compare(a.id, b.id));
      return tagsChips;
    }
  },
  watch: {
    tagsList() {
      this.updateTags();
    },
    themeName() {
      this.$nextTick(() => {
        this.allTagsIconMode();
      });
    }
  },
  mounted() {
    document.addEventListener('click', this.closeFiltersOnClickOutside);
    this.category = this.externalData?.items[0];
    this.allTagsIconMode();
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeFiltersOnClickOutside);
  },
  methods: {
    closeFiltersOnClickOutside(event) {
      const isClickInsideComponent = this.$el?.contains(event.target);
      const isClickOnFilterInput = this.$refs.filterInput?.$el?.contains(
        event.target
      );

      if (!isClickInsideComponent || isClickOnFilterInput) {
        this.isTypesFilterVisible = false;
        this.isTagsFilterVisible = false;
      }
    },
    toggleTypesFilter() {
      if (this.isTagsFilterVisible) {
        this.isTagsFilterVisible = false;
      }
      this.isTypesFilterVisible = !this.isTypesFilterVisible;
    },
    toggleTagsFilter() {
      if (this.isTypesFilterVisible) {
        this.isTypesFilterVisible = false;
      }
      this.isTagsFilterVisible = !this.isTagsFilterVisible;
    },
    $_getTagsChip(val) {
      const handlers = {};
      const handler = this.$_closeChipGenre.bind(this, val);
      handlers.click = handler;
      handlers['click:close'] = handler;
      const chip = {
        id: val,
        label: val,
        hasCloseButton: true,
        handlers
      };
      return chip;
    },
    $_closeChipGenre(name) {
      this.tags = { ...this.tags, [name]: false };
      this.onFilterByTags();
    },
    changeTags(tag, val) {
      this.tags = { ...this.tags, [tag]: val };
      this.onFilterByTags();
    },
    tagsInputHandler() {
      this.debouncedInput(this.filterTagsText);
    },
    updateTags(text) {
      if (!text) {
        this.filteredTags = this.tagsList;
        return;
      }
      this.filteredTags = this.tagsList?.filter(tag =>
        tag.toLowerCase().includes(text.toLowerCase())
      );
    },
    filterEmit(category, tags) {
      this.$emit('onAction', {
        category,
        tags,
        filterText: this.filterText
      });
    },
    getFilterTags() {
      const tags = Object.keys(this.tags).filter(
        tag => this.tags[tag] === true
      );
      return tags;
    },
    getFilterCategories() {
      const category = this.category.hasOwnProperty('value')
        ? this.category.value
        : this.category;
      return category;
    },
    onFilterByTags() {
      const tags = this.getFilterTags();
      const category = this.getFilterCategories();
      this.filterEmit(category, tags);
    },
    onFilterByText({ filterText }) {
      this.filterText = filterText;
      const category = this.getFilterCategories();
      const tags = this.getFilterTags();
      this.filterEmit(category, tags);
    },
    onSelectChange(category) {
      this.category = category;
      const tags = this.getFilterTags();
      this.filterEmit(category, tags);
    },
    allTagsIconMode() {
      switch (this.themeName) {
        case ThemeClassNamesEnum.NIGHT:
          return (this.allTagsIcon = images.multiCirclesDark);
        case ThemeClassNamesEnum.SEPIA:
          return (this.allTagsIcon = images.multiCircles);
        default:
          return (this.allTagsIcon = images.multiCircles);
      }
    },
    itemColor(item) {
      return this.themeName === ThemeClassNamesEnum.NIGHT
        ? item.nightColor
        : item.color;
    }
  }
};
</script>

<style lang="less" src="./FilterItem.less"></style>
