// import preloader from '@/preloader';
import CompilationSelectionTypes from '@/enums/CompilationSelectionTypes';
import MaterialTypes from '@shared/enums/MaterialTypes';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import LayoutManager from '@/services/utils/LayoutManager';

import BookDownloadedIncorrect from '@/components/views/BookDownloadedIncorrect/BookDownloadedIncorrect';
import BookNotDownloadedPopup from '@/components/views/BookNotDownloadedPopup/BookNotDownloadedPopup';
import AppMenuWrapper from '@/components/views/AppMenu/AppMenuWrapper';
import LoadingView from '@/components/views/LoadingView/LoadingView.vue';
import ConfirmPopup from '@/components/views/ConfirmPopup/ConfirmPopup';
import DailyGoalPopup from '@/components/views/DailyGoalPopup/DailyGoalPopup';
import SwitchCompilationToaster from '@/components/views/SwitchCompilationToaster/SwitchCompilationToaster.vue';
import ErrorToaster from '@/components/views/ErrorToaster/ErrorToaster';
import Toaster from '@/components/views/Toaster/Toaster';
import PlayButtonPopup from '@/components/views/PlayButtonPopup/PlayButtonPopup';
import CreateSectionPopup from '@/components/views/Compilations/CreateSectionPopup';
import MoveToSectionPopup from '@/components/views/Compilations/MoveToSectionPopup';
import ExtrasPopup from '@/components/views/ExtrasPopup/ExtrasPopup';
import PaymentOfflinePopup from '@/components/views/PaymentPopups/PaymentOfflinePopup';
import CongratulationPopup from '@/components/views/PaymentPopups/CongratulationPopup';
import ConfirmationTaskPopup from '@/components/views/ConfirmationTaskPopup/ConfirmationTaskPopup.vue';
import CreateAnnotationPopup from '@/components/views/CreateAnnotationPopup/CreateAnnotationPopup';
import TableOfContentsPopup from '@/components/views/TableOfContentsPopup/TableOfContentsPopup';
import BookmarkPopup from '@/components/views/BookmarkPopup/BookmarkPopup';
import ReadingSettingsBottomPopup from '@/components/views/ReadingSettingsBottomPopup/ReadingSettingsBottomPopup';
import ExtendingMenu from '@/components/views/ExtendingMenu/ExtendingMenu.vue';
import CompilationItemMenu from '@/components/views/CompilationItem/CompilationItemMenu/CompilationItemMenu';
import CompilationDeletePopup from '@/components/views/CompilationItem/CompilationDeletePopup/CompilationDeletePopup';
import CompilationMakeDefaultPopup from '@/components/views/CompilationItem/CompilationMakeDefaultPopup/CompilationMakeDefaultPopup';
import TagsPopup from '@/components/views/TagsPopup/TagsPopup.vue';
import DeleteTagPopup from '@/components/views/DeleteTagPopup/DeleteTagPopup.vue';
import CategoryPopup from '@/components/views/CategoryPopup/CategoryPopup';
import AnnInfoPopup from '@/components/views/AnnInfoPopup/AnnInfoPopup';
import SearchDummyPopup from '@/components/views/SearchDummy/SearchDummy.vue';
import UserSegmentationPopup from '@/components/views/UserSegmentationPopup/UserSegmentationPopup.vue';

const APP_POPUP_MIXIN_ID = 'appPopupMixinId';

// dynamic import
const AccountDeletedSuccessPopup = () =>
  import('@/components/views/DeleteAccountView/AccountDeletedSuccessPopup.vue');
const AddToCompilationPopup = () =>
  import('@/components/views/AddToCompilationPopup/AddToCompilationPopup.vue');
const AffiliateDetailsPopup = () =>
  import(
    '@/components/pages/Affiliates/AffiliateDetailsPopup/AffiliateDetailsPopup'
  );
const AssessmentContainer = () =>
  import(
    '@/components/views/Assessment/AssessmentContainer/AssessmentContainer'
  );
const CancelDeleteAccountPopup = () =>
  import('@/components/views/DeleteAccountView/CancelDeleteAccountPopup.vue');
const CompilationCoverChangerPopup = () =>
  import(
    '@/components/views/CompilationCoverChangerPopup/CompilationCoverChangerPopup'
  );
const Connect = () => import('@/components/views/ConnectPopup/Connect.vue');
const CreateCompilationPopup = () =>
  import(
    '@/components/views/CreateCompilationPopup/CreateCompilationPopup.vue'
  );
const CreateCampaignPopup = () =>
  import(
    '@/components/pages/Campaigns/CreateCampaignPopup/CreateCampaignPopup.vue'
  );
const DictionaryPopup = () =>
  import('@/components/views/DictionaryPopup/DictionaryPopup.vue');
const ContactUsPopup = () =>
  import('@/components/views/ContactUsPopup/ContactUsPopup');
const CreateStudyCourse = () =>
  import('@/components/views/CreateStudyCourse/CreateStudyCourse');
const ExternalBlogPopup = () =>
  import('@/components/views/ExternalBlog/ExternalBlogPopup');
const ExternalBlogPostPopup = () =>
  import('@/components/views/ExternalBlogPost/ExternalBlogPostPopup');
const ImageCropPopup = () => import('@/components/views/Avatar/ImageCropPopup');
const LanguagePopup = () =>
  import('@/components/views/LanguagePopup/LanguagePopup');
const PaymentActionPopup = () =>
  import('@/components/views/PaymentPopups/PaymentActionPopup');
const ProfilePopup = () =>
  import('@/components/views/Profile/ProfilePopup/ProfilePopup');
const RecentBooksWrapper = () =>
  import(
    '@/components/views/RecentBooks/RecentBooksWrapper/RecentBooksWrapper'
  );
const SalesPopup = () => import('@/components/views/SalesPopup/SalesPopup');
const SharedCompilationToaster = () =>
  import(
    '@/components/views/SharedCompilationToaster/SharedCompilationToaster.vue'
  );
const ReturnToCourseToaster = () =>
  import('@/components/views/ReturnToCourseToaster/ReturnToCourseToaster.vue');
const SocialSharingPopup = () =>
  import('@/components/views/SocialSharingPopup/SocialSharingPopup');
const ShortenerPopup = () =>
  import('@/components/views/ShortenerPopup/ShortenerPopup');
const SupportPopup = () =>
  import('@/components/views/SupportPopup/SupportPopup');
const TranslatePopup = () =>
  import('@/components/views/TranslatePopup/TranslatePopup.vue');
const TypoPopup = () => import('@/components/views/TypoPopup/TypoPopup');
const TestEditor = () => import('@/components/views/TestEditor/TestEditor.vue');
const VocabularyAssessmentDialog = () =>
  import(
    '@/components/views/VocabularyAssessmentDialog/VocabularyAssessmentDialog.vue'
  );
const ActivityMenu = () =>
  import('@/components/pages/UserActivity/ActivityMenu.vue');
const ShareActivityPopup = () =>
  import('@/components/pages/UserActivity/ShareActivityPopup.vue');

import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('appPopupMixin.js');

let endPopUpSubscription;

function $_subscribe() {
  endPopUpSubscription = this.$store.subscribeAction({
    after: action => {
      const type = action.type;

      //todo thre
      // "!/toaster/gi.test(type)" temp crutch, need popups refactoring
      if (
        type.startsWith('ManagePopupStore') &&
        !/(toaster|finishLoader|startLoader)/gi.test(type)
      ) {
        this.popupContext = action.payload;
      }

      switch (type) {
        case 'ManagePopupStore/startLoader':
          this.$_startLoader();
          break;
        case 'ManagePopupStore/finishLoader':
          this.$_finishLoader();
          break;
        case 'ManagePopupStore/openToaster': {
          this.currentToaster = Toaster.name;
          this.toasterContext = {
            shouldPresent: true,
            ...action.payload
          };
          break;
        }
        case 'ManagePopupStore/openSharedCompilationToaster':
          this.toasterContext = action.payload;
          this.currentToaster = SharedCompilationToaster;
          break;
        case 'ManagePopupStore/openReturnToCourseToaster':
          this.toasterContext = action.payload;
          this.currentToaster = ReturnToCourseToaster;
          break;
        case 'ManagePopupStore/closeReturnToCourseToaster':
          if (this.currentToaster === ReturnToCourseToaster) {
            this.toasterContext = {};
            this.currentToaster = null;
          }
          break;

        case 'ManagePopupStore/closeReturnToReadingToaster':
          if (this.toasterContext.persistent) {
            this.toasterContext = {};
            this.currentToaster = null;
          }
          break;

        default:
          break;
      }
      if (!this.popupContext) {
        logger.warn(
          `set popupContext default empty object for open popup ${type}`
        );
        this.popupContext = {};
      }
    }
  });
}
export default {
  components: {
    CreateAnnotationPopup,
    AppMenuWrapper,
    BookNotDownloadedPopup,
    BookDownloadedIncorrect,
    LanguagePopup,
    Connect,
    ImageCropPopup,
    LoadingView,
    SwitchCompilationToaster,
    SharedCompilationToaster,
    ErrorToaster,
    Toaster,
    ContactUsPopup,
    ConfirmPopup,
    DictionaryPopup,
    DailyGoalPopup,
    CreateCompilationPopup,
    AddToCompilationPopup,
    CreateSectionPopup,
    MoveToSectionPopup,
    ExtrasPopup,
    CreateCampaignPopup,
    AffiliateDetailsPopup,
    AccountDeletedSuccessPopup,
    CancelDeleteAccountPopup,
    PaymentActionPopup,
    PaymentOfflinePopup,
    AssessmentContainer,
    ExternalBlogPopup,
    TypoPopup,
    CreateStudyCourse,
    ExternalBlogPostPopup,
    SocialSharingPopup,
    ShortenerPopup,
    ProfilePopup,
    SalesPopup,
    SupportPopup,
    TestEditor,
    TableOfContentsPopup,
    BookmarkPopup,
    ExtendingMenu,
    CompilationItemMenu,
    CompilationDeletePopup,
    CompilationMakeDefaultPopup,
    TagsPopup,
    DeleteTagPopup,
    TranslatePopup,
    CongratulationPopup,
    ConfirmationTaskPopup,
    RecentBooksWrapper,
    PlayButtonPopup,
    ReadingSettingsBottomPopup,
    CompilationCoverChangerPopup,
    CategoryPopup,
    AnnInfoPopup,
    SearchDummyPopup,
    ActivityMenu,
    ShareActivityPopup,
    VocabularyAssessmentDialog,
    UserSegmentationPopup
  },
  methods: {
    $_subscribe,
    $_processConfirm({ type, data }) {
      switch (type) {
        case MaterialTypes.ESSAY_TASK:
          this.$store.dispatch('MaterialsStore/deleteEssayTask', {
            essay: data
          });
          break;
        case MaterialTypes.QUIZ:
          this.$store.dispatch('MaterialsStore/deleteQuiz', {
            quizToDelete: data
          });
          break;
        case MaterialTypes.FLASHCARD:
          this.$store.dispatch('MaterialsStore/deleteFlashcard', {
            flashcardToDelete: data
          });
          break;
        case CompilationSelectionTypes.SELECTION:
        case CompilationSelectionTypes.SECTION:
          this.$store.dispatch('CompilationsStore/removeSelection', {
            compilationId: data.compilationId,
            paraId: data.paraId
          });
          break;
        default:
          logger.warn(`Unsupported confirmHandler task type: ${type}`);
      }
    },
    $_startLoader() {
      //todo thre
      // this.popupContext = { isResolved: false }
      this.$_clearLoaderTimeouts();
      this.loadingPopup = LoadingView.name;
    },
    $_clearLoaderTimeouts() {
      clearTimeout(this.stopLoaderTimeout);
    },
    $_finishLoader() {
      if (this.loadingPopup !== LoadingView.name) {
        return;
      }
      this.$_clearLoaderTimeouts();
      //todo thre
      // this.popupContext.isResolved = true;
      this.stopLoaderTimeout = setTimeout(() => {
        this.loadingPopup = null;

        this.$nextTick(() => {
          const popupElements = [...document.querySelectorAll('.v-dialog')];
          const isPopupOpened = popupElements.some(
            popup => popup.style.display !== 'none'
          );
          if (isPopupOpened) {
            document
              .querySelector('html')
              .classList.add(AppConstantsUtil.VUETIFY_DISABLE_SCROLL_CLASS);
          }
        });
      }, 1000);
    },
    $_isPopupOpened() {
      return this.$store.getters['ManagePopupStore/isPopupOpened'];
    }
  },
  data() {
    return {
      loadingPopup: null,
      popupContext: {},
      currentToaster: null,
      toasterContext: {},
      stopLoaderTimeout: null
    };
  },
  computed: {
    narrow() {
      return this.$store.getters['MediaDetectorStore/mediaSize'].narrow;
    },
    normal() {
      return this.$store.getters['MediaDetectorStore/mediaSize'].normal;
    },
    wide() {
      return this.$store.getters['MediaDetectorStore/mediaSize'].wide;
    }
  },
  mounted() {
    this.$_subscribe();
    // preloader([SalesPopup], 4000);
    // preloader(
    //   [
    //     Connect,
    //     ContactUsPopup,
    //     ExternalBlogPopup,
    //     TypoPopup,
    //     ExternalBlogPostPopup,
    //     SocialSharingPopup,
    //     ProfilePopup,
    //     AssessmentContainer,
    //     PaymentActionPopup,
    //     SharedCompilationToaster
    //   ],
    //   10000
    // );
  },
  destroyed() {
    LayoutManager.unregister(APP_POPUP_MIXIN_ID);
    if (endPopUpSubscription) {
      endPopUpSubscription();
    }
  }
};
