import pick from 'lodash/pick';
import RecentBookFactory from '@/classes/factories/RecentBooks/RecentBookFactory';
import AppModeEnum from '@/enums/AppModeEnum';
import PublicationsTypesEnum from '@shared/enums/PublicationsTypesEnum';
import RequestService from '@/services/RequestService';
import LoggerFactory from '@/services/utils/LoggerFactory';
import UnifiedSettingsService from '@/services/UnifiedSettingsService';

const logger = LoggerFactory.getLogger('RecentBookStore.js');

const _getInitState = () => ({
  recentBookItems: [],
  recentStudyMaterials: [],
  recentStudyCourses: [],
  recentCompilations: [],
  lastRecentItem: null
});

const recentItemLimit = 4;
const statePropByPubType = {
  [PublicationsTypesEnum.BOOK]: 'recentBookItems',
  [PublicationsTypesEnum.STUDY_GUIDE]: 'recentStudyMaterials',
  [PublicationsTypesEnum.SYLLABUS]: 'recentStudyMaterials',
  [PublicationsTypesEnum.STUDY_COURSE]: 'recentStudyCourses',
  [PublicationsTypesEnum.COMPILATION]: 'recentCompilations'
};

const stateMapByRecentType = {
  books: 'recentBookItems',
  compilations: 'recentCompilations'
  // studyGuides: 'recentStudyMaterials',
  // studyCourse: 'recentStudyMaterials',
  // studyClass: 'recentStudyCourses',
};

function _createRecentItems(serializedRecentItems, rootGetters) {
  const recentItems = [];
  serializedRecentItems.forEach(serializedRecentItem => {
    const publication = rootGetters[
      'LibraryStore/getPublicationBySlugWithFallbackById'
    ](serializedRecentItem.getId());
    if (!publication) {
      return null;
    }
    const recentItem = RecentBookFactory.desirilizeRecentItem(
      serializedRecentItem,
      publication
    );
    recentItems.push(recentItem);
  });
  return recentItems;
}

function _mergeSerializedAndFullItem(rootGetters, id, serializedRecentItem) {
  const fullItem = rootGetters[
    'LibraryStore/getPublicationBySlugWithFallbackById'
  ](id);

  return RecentBookFactory.mergedRecentItem(serializedRecentItem, fullItem);
}
// getters
const storeGetters = {
  getLastRecentBookItem: (state, getters, rootState, rootGetters) => {
    const firstRecentBookItems = state.recentBookItems
      ? state.recentBookItems[0]
      : null;
    const lastRecentItem = state.lastRecentItem || firstRecentBookItems;
    if (!lastRecentItem) {
      return null;
    }
    const publication = rootGetters[
      'LibraryStore/getPublicationBySlugWithFallbackById'
    ](lastRecentItem.getId());
    if (!publication) {
      return null;
    }
    return RecentBookFactory.desirilizeRecentItem(lastRecentItem, publication);
  },
  getLastRecentBook: (state, getters, rootState, rootGetters) => {
    const lastRecentItem = state.recentBookItems
      ? state.recentBookItems[0]
      : null;
    if (!lastRecentItem) {
      return null;
    }
    const publication = rootGetters[
      'LibraryStore/getPublicationBySlugWithFallbackById'
    ](lastRecentItem.getId());
    if (!publication) {
      return null;
    }
    return RecentBookFactory.desirilizeRecentItem(lastRecentItem, publication);
  },
  getRecentStudyMaterials: (state, getters, rootState, rootGetters) => () => {
    return _createRecentItems(state.recentStudyMaterials, rootGetters);
  },
  getRecentBooks: (state, getters, rootState, rootGetters) => {
    return _createRecentItems(state.recentBookItems, rootGetters);
  },
  getRecentBookIds: state => {
    return state.recentBookItems.map(item => item.getId());
  },
  getStudyCourses: (state, getters, rootState, rootGetters) => () => {
    return _createRecentItems(state.recentStudyCourses, rootGetters);
  },
  getCompilations: (state, getters, rootState, rootGetters) => () => {
    return _createRecentItems(state.recentCompilations, rootGetters);
  }
};

async function _addRecentItem(updateRecentItem, forceOnline) {
  const response = await RequestService.request(
    'post',
    'UserPublication',
    'update',
    {
      userPublication: updateRecentItem
    },
    {
      forceOnline
    }
  );
  return response.data;
}

async function _removeRecentItem(updateRecentItem, forceOnline) {
  const response = await RequestService.request(
    'post',
    'UserPublication',
    'remove',
    {
      userPublication: updateRecentItem
    },
    {
      forceOnline
    }
  );
  return response.data;
}

function _getRecentItemsByPublicationId(publicationId, state) {
  const items = [];
  for (const itemName in state) {
    const recentItems = state[itemName];
    if (!Array.isArray(recentItems)) {
      continue;
    }
    for (const recentItem of recentItems) {
      const findItem = recentItem.compareByPublicationId(publicationId);
      if (findItem) {
        items.push(recentItem);
      }
    }
  }
  return items;
}

function _createUpdateRecent(rootGetters, payload) {
  const { publicationId } = payload;
  const { id, type } = rootGetters[
    'LibraryStore/getPublicationBySlugWithFallbackById'
  ](publicationId);
  switch (type) {
    case PublicationsTypesEnum.BOOK:
      return RecentBookFactory.createUpdateRecentBook(id);
    case PublicationsTypesEnum.COMPILATION:
      return RecentBookFactory.createUpdateRecentCompilation(id);
    case PublicationsTypesEnum.STUDY_COURSE:
      return RecentBookFactory.createUpdateRecentStudyCourse(id);
  }

  return null;
}

function updateRecentBookCache(recentItems) {
  const isBooks = recentItems.every(
    recentItem => recentItem?.publicationType === PublicationsTypesEnum.BOOK
  );
  if (isBooks) {
    UnifiedSettingsService.setSetting(
      'recentBookDumpData',
      'recentItems',
      recentItems
    );
  }
}

// actions
const actions = {
  fillRecentBooksFromDb({ commit }) {
    const rawRecentItems = UnifiedSettingsService.getSetting(
      'recentBookDumpData',
      'recentItems'
    );

    if (rawRecentItems?.length) {
      const recentItems = rawRecentItems.map(serializedRecentItem => {
        return RecentBookFactory.deserializeRecentItemFromObj(
          serializedRecentItem
        );
      });
      commit('fillRecentItems', { recentItems });
    }
  },
  updateRecentBookItems({ commit, rootGetters }, payload) {
    const { meta, bookId } = payload;
    let recentBookItem;
    switch (meta.type) {
      case PublicationsTypesEnum.BOOK:
      case PublicationsTypesEnum.COMPILATION:
        recentBookItem = RecentBookFactory.createSerializedRecentBookItemFromMeta(
          meta
        );
        break;
      case PublicationsTypesEnum.STUDY_COURSE:
        break;
    }

    const lastRecentItem = _mergeSerializedAndFullItem(
      rootGetters,
      bookId,
      recentBookItem
    );
    commit('setLastRecentItem', {
      lastRecentItem: lastRecentItem
    });
  },
  updateLastRecentItem({ commit }, payload) {
    const { lastRecentItem } = payload;
    commit('setLastRecentItem', {
      lastRecentItem: lastRecentItem
    });
  },
  async addRecentItem({ commit, rootGetters }, payload) {
    const isInIFrame = rootGetters['ContextStore/inIframe'];
    if (isInIFrame) {
      return;
    }
    const { publicationId, studyClassId, appMode } = payload;
    const updateRecentItem = _createUpdateRecent(rootGetters, {
      publicationId
    });
    let serializedRecentItem;
    const forceOnline = appMode === AppModeEnum.EDITOR;

    serializedRecentItem = await _addRecentItem(updateRecentItem, forceOnline);

    const mergedSerializedRecentItem = _mergeSerializedAndFullItem(
      rootGetters,
      publicationId || studyClassId,
      serializedRecentItem
    );
    commit('setLastRecentItem', {
      lastRecentItem: mergedSerializedRecentItem
    });
    commit('addRecentItems', {
      recentItems: [mergedSerializedRecentItem],
      allItems: false
    });
  },

  async removeRecentItem({ state, commit, rootGetters }, payload) {
    const { publicationId, studyClassId, appMode } = payload;
    const updateRecentItem = _createUpdateRecent(rootGetters, {
      publicationId
    });
    let serializedRecentItem;
    const forceOnline = appMode === AppModeEnum.EDITOR;

    serializedRecentItem = await _removeRecentItem(
      updateRecentItem,
      forceOnline
    );
    const mergedSerializedRecentItem = _mergeSerializedAndFullItem(
      rootGetters,
      publicationId || studyClassId,
      serializedRecentItem
    );
    commit('removeRecentItem', {
      recentItem: mergedSerializedRecentItem
    });
    const lastRecentItem = _getLatestRecentItem(state);
    commit('setLastRecentItem', {
      lastRecentItem
    });
  },

  async fillRecentItemStore({ state, commit, rootGetters }, payload) {
    const { appMode, recentBooks } = payload;
    const forceOnline = appMode === AppModeEnum.EDITOR;
    let data = recentBooks;
    const query = null;
    const oldRecentItemsByType = _getAllExistingRecentItems(state);
    if (!data) {
      const response = await RequestService.request(
        'get',
        'UserPublication',
        'getRecentBooks',
        query,
        {
          forceOnline
        }
      );
      data = response.data;
    }
    if (!data) {
      return;
    }
    data.books = $_prepareDataForStore(
      data.books,
      rootGetters,
      'publicationId'
    );
    // data.studyClass = $_prepareDataForStore(
    //   data.studyClass,
    //   rootGetters,
    //   'studyClassId'
    // );
    data.compilations = $_prepareDataForStore(
      data.compilations,
      rootGetters,
      'compilationId'
    );
    const recentItemsByTypes = pick(data, [
      'books',
      // 'studyGuides',
      // 'studyCourse',
      // 'studyClass',
      'compilations'
    ]);

    const latestItemSet = [];
    let isChanged = false;
    for (const [key, recentItems] of Object.entries(recentItemsByTypes)) {
      let needRecentItemsUpdate =
        !oldRecentItemsByType ||
        _isRecentItemsChanged(recentItems, oldRecentItemsByType[key]);
      const firstRecentItem = recentItems.find(recentItem => recentItem);
      if (firstRecentItem) {
        latestItemSet.push(firstRecentItem);
      }
      if (needRecentItemsUpdate && firstRecentItem) {
        isChanged = true;
        commit('fillRecentItems', {
          recentItems: recentItems
        });
      }
    }
    if (isChanged) {
      commit('setLastRecentItem', {
        lastRecentItem: findTheLatestItem(latestItemSet)
      });
    }
  }
};

function _isRecentItemsChanged(recentItems, oldRecentItems) {
  let isRecentItemChanged = false;
  if (recentItems.length !== oldRecentItems.length) {
    return true;
  }
  for (let i = 0; i < recentItems.length; i++) {
    const recent = recentItems[i];
    const old = oldRecentItems.find(
      oldRecent =>
        getIdByPubType(oldRecent) === getIdByPubType(recent) &&
        oldRecent.lastReadingTime === recent.lastReadingTime
    );
    if (!old) {
      isRecentItemChanged = true;
      break;
    }
  }
  return isRecentItemChanged;
}

function _getAllExistingRecentItems(state) {
  const props = Object.entries(stateMapByRecentType);
  let recentItems;
  for (const [key, prop] of props) {
    if (state[prop]) {
      if (!recentItems) {
        recentItems = {};
      }
      recentItems[key] = state[prop];
    }
  }
  return recentItems;
}

function findTheLatestItem(latestItemSet) {
  let latestItem = latestItemSet[0];
  latestItemSet.forEach(item => {
    if (item.lastReadingTime > latestItem.lastReadingTime) {
      latestItem = item;
    }
  });

  return latestItem;
}

function getIdByPubType(item) {
  switch (item.publicationType || item.type) {
    case PublicationsTypesEnum.BOOK:
      return item.publicationId;
    case PublicationsTypesEnum.STUDY_COURSE:
      return item.studyClassId;
    case PublicationsTypesEnum.COMPILATION:
      return item.compilationId;
  }
}

function getStatePropByPubType(type) {
  return statePropByPubType[type];
}

function _findRecentItemIndex(recentItems, recentItem) {
  return recentItems.findIndex(
    currentRecentBookItem =>
      getIdByPubType(currentRecentBookItem) === getIdByPubType(recentItem)
  );
}

function _getLatestRecentItem(state) {
  let latestRecentItem, latestItemByType;
  const stateProps = Object.values(statePropByPubType);
  stateProps.forEach(stateProp => {
    latestItemByType = state[stateProp].reduce(
      (minDateItem, item) =>
        minDateItem.lastReadingTime > item.lastReadingTime ? minDateItem : item,
      state[stateProp][0]
    );
    const isMoreThanLatestRecentItem =
      latestItemByType &&
      (!latestRecentItem || latestRecentItem < latestItemByType);
    if (isMoreThanLatestRecentItem) {
      latestRecentItem = latestItemByType;
    }
  });
  return latestRecentItem;
}

function $_cutItemListToLimit(recentItemList) {
  return recentItemList.splice(0, recentItemLimit);
}
function $_prepareDataForStore(recentItemList, rootGetters, idName) {
  if (recentItemList?.length > recentItemLimit) {
    recentItemList = $_cutItemListToLimit(recentItemList);
  }
  return recentItemList.reduce((accumulator, book) => {
    if (book) {
      if (book.type && !book.publicationType) {
        logger.warn(
          'Use "publicationType" in Recent book factory instead of "type". Also "type" will be converted into "publicationType" after any book opening'
        );
      }
      const recentBook = _mergeSerializedAndFullItem(
        rootGetters,
        book[idName],
        {
          ...book,
          publicationType: book.publicationType || book.type
        }
      );
      if (recentBook) {
        accumulator.push(recentBook);
      }
    }
    return accumulator;
  }, []);
}
// mutations
const mutations = {
  addRecentItems(state, payload) {
    const { recentItems, allItems } = payload;
    if (!recentItems || !recentItems.length) {
      return;
    }
    recentItems.forEach(recentItem => {
      if (!recentItem) {
        return;
      }
      const stateProp = getStatePropByPubType(recentItem.publicationType);
      const currentIndex = _findRecentItemIndex(state[stateProp], recentItem);

      if (currentIndex === -1) {
        if (allItems) {
          state[stateProp].push(recentItem);
        } else {
          state[stateProp].unshift(recentItem);
        }
      } else {
        state[stateProp].splice(currentIndex, 1);
        state[stateProp].unshift(recentItem);
      }

      if (state[stateProp].length > recentItemLimit) {
        state[stateProp] = $_cutItemListToLimit(state[stateProp]);
      }
    });

    updateRecentBookCache(recentItems);
  },
  removeRecentItem(state, payload) {
    const { recentItem } = payload;

    if (!recentItem) {
      return;
    }

    const stateProp = getStatePropByPubType(recentItem.publicationType);
    const currentIndex = _findRecentItemIndex(state[stateProp], recentItem);
    if (currentIndex !== -1) {
      state[stateProp].splice(currentIndex, 1);
    }
  },

  fillRecentItems(state, { recentItems }) {
    if (!recentItems || !recentItems.length) {
      return;
    }
    const recentByType = {};
    recentItems.forEach(recentItem => {
      const stateProp = getStatePropByPubType(recentItem.publicationType);
      recentByType[stateProp] = recentByType[stateProp] || [];
      recentByType[stateProp].push(recentItem);
      if (recentByType[stateProp].length > recentItemLimit) {
        recentByType[stateProp] = $_cutItemListToLimit(recentByType[stateProp]);
      }
    });
    for (const [key, val] of Object.entries(recentByType)) {
      state[key] = val;
    }
    updateRecentBookCache(recentItems);
  },
  setLastRecentItem(state, payload) {
    const { lastRecentItem } = payload;
    state.lastRecentItem = lastRecentItem;
  },
  actualizeRecentBookItem(state, payload) {
    const { meta, publicationId } = payload;
    const recentItems = _getRecentItemsByPublicationId(publicationId, state);
    if (!recentItems.length) {
      return;
    }
    recentItems.forEach(recentItem => {
      recentItem.updateByMeta(meta);
    });
    if (
      state.lastRecentItem &&
      state.lastRecentItem.compareByPublicationId(publicationId)
    ) {
      state.lastRecentItem.updateByMeta(meta);
      this.commit('RecentBookStore/setLastRecentItem', {
        lastRecentItem: state.lastRecentItem
      });
    }
  },
  resetRecentBooks(state) {
    const newState = _getInitState();
    Object.keys(newState).forEach(key => {
      state[key] = newState[key];
    });
  }
};

export default {
  state: _getInitState,
  getters: storeGetters,
  actions,
  mutations
};
