import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import PublicationsTypesEnum from '@shared/enums/PublicationsTypesEnum';
import CompilationsService from '@/services/CompilationsService';
import Utils from '@/services/utils/Utils';
import formatUtils from '@shared/utils/formatUtils';

class MetaView {
  constructor(meta) {
    this.direction = Utils.getDirection(meta.language);
    this.type = meta.type;
    this.audio = false;
    this.name = '';
  }
}

class BookMetaView extends MetaView {
  constructor(meta) {
    super(meta);
    this.id = meta.fileName;
    this.name = meta.name;
    this.author = meta.author;
    this.authorList = meta.authorList || meta.author_list;
    this.category = meta.category;
    this.level = meta.level;
    this.genres = meta.genres;
    this.description = meta.shortDescription || meta.description;
    this.showDescription =
      (meta.shortDescription || '').length || (meta.description || '').length;
    this.audio = meta.audio;
    this.readingTime = meta.readingTime;
    this.difficulty = meta.difficulty;
    this.mediaSize = meta.mediaSize;
  }
}

class PublicationParaInfo {
  constructor(paraId, slug, meta, paraNum) {
    this.id = meta.fileName;
    this.locator = paraId;
    this.paraNum = paraNum;
    this.name = meta.name;
    this.slug = slug;
    this.author = meta.author;
  }
}

class CompilationEditableMeta {
  constructor(compilationMetaView) {
    this.title = compilationMetaView.name;
    this.description = compilationMetaView.description;
    this.coverFileName = compilationMetaView.coverFileName;
  }
}

class CompilationMetaView extends MetaView {
  constructor(meta) {
    super(meta);
    this.id = meta.id;
    this.name = meta.name;
    this.author = meta.author;
    this.category = meta.category;
    this.level = meta.level;
    this.description = meta.description;
    this.showDescription = meta.description.length;
    this.audio = meta.audio;
    this.readingTime = meta.readingTime;
    this.modifiedAt = meta.modifiedAt;
    this.paragraphsNumber = meta.paragraphsNumber;
    this.coverFileName = meta.coverFileName;
  }

  updateEditableInfo(info) {
    this.name = info.title;
    this.description = info.description;
    this.showDescription = info.description.length;
    this.coverFileName = info.coverFileName;
  }

  getModifiedAtLocaleString() {
    return CompilationsService.convertModifiedAtToLocaleString(this.modifiedAt);
  }

  getEditableInfo() {
    return new CompilationEditableMeta(this);
  }
}

class CompilationMeta {
  constructor(compilation) {
    this.id = compilation._id;
    this.name = compilation.title;
    this.default = compilation.default || false;
    this.author = compilation.author || '';
    this.category = compilation.category || '';
    this.level = compilation.level || '';
    this.description = compilation.description || '';
    this.audio = compilation.audio;
    this.readingTime = compilation.readingTime;
    this.modifiedAt = compilation.modifiedAt;
    this.language = 'en';
    this.toc = [];
    this.wordsCount = 0;
    this.readingTime = 0;
    this.paragraphsNumber = 0;
    this.type = PublicationsTypesEnum.COMPILATION;
    this.coverFileName = compilation.coverFileName;
  }

  setWordsCount(wordsCount) {
    this.wordsCount = wordsCount;
    this.updateReadingTime();
  }

  updateReadingTime() {
    this.readingTime = Math.round(
      (this.wordsCount / AppConstantsUtil.DEFAULT_AUDIO_SPEED) * 60 * 1000
    );
  }

  updateByEditableMeta(editableMeta) {
    this.name = editableMeta.title;
    this.description = editableMeta.description || '';
  }

  updateByEditableMetaCover(coverFileName) {
    this.coverFileName = coverFileName;
  }

  setParagraphsNumber(paragraphsNumber) {
    this.paragraphsNumber = paragraphsNumber;
  }

  setAudio(paragraphs) {
    this.audio = paragraphs.some(para => !!para.audio);
  }

  setModifiedAt(modifiedAt) {
    this.modifiedAt = modifiedAt;
  }
}
class BookMeta {
  constructor(rawMeta) {
    this.assetsSource = '';
    this.id = rawMeta.fileName;
    this.originalFileName = rawMeta.originalFileName;
    this.fileName = rawMeta.fileName;
    this.toc = rawMeta.toc;
    this.authors = rawMeta.authors;
    this.wordsCount = rawMeta.wordsCount;
    this.name = rawMeta.name;
    this.weight = rawMeta.weight;
    this.slug = rawMeta.slug || '';
    this.totalCharacters = rawMeta.totalCharacters;
    this.description = rawMeta.description;
    this.shortDescription = rawMeta.shortDescription;
    this.genres = rawMeta.genres;
    this.readingTime = rawMeta.readingTime;
    this.collection = rawMeta.collection;
    this.author = formatUtils.authorFormatting(rawMeta.author);
    this.authorList = rawMeta.authorList || rawMeta.author_list;
    this.religion = rawMeta.religion;
    this.category = rawMeta.category;
    this.language = rawMeta.language;
    this.coverFile = rawMeta.coverFile;
    this.level = rawMeta.level;
    this.publish = rawMeta.publish;
    this.ilmId = rawMeta.ilmId;
    this.cover = rawMeta.cover;
    this.difficulty = rawMeta.difficulty;
    this.bookVocabularyLevel = rawMeta.bookVocabularyLevel;
    this.paragraphsNumber = rawMeta.paragraphsNumber;
    this.audio = rawMeta.audio;
    this.date = rawMeta.date;
    this.mediaSize = rawMeta.mediaSize;
    this.version = rawMeta.version;
    this.wordsPerMinute = rawMeta.wordsPerMinute;
    this.type = PublicationsTypesEnum.BOOK;
    this.bookHasTrimmedSilence = rawMeta.hasOwnProperty('trimmed_silence')
      ? rawMeta.trimmed_silence
      : true;
    this.isServer = process.server;
  }

  setAssetsSource(assetsSource) {
    this.assetsSource = assetsSource;
    return this;
  }

  toJSON() {
    return JSON.stringify({
      id: this.id,
      assetsSource: this.assetsSource,
      originalFileName: this.originalFileName,
      fileName: this.fileName,
      toc: this.toc,
      authors: this.authors,
      wordsCount: this.wordsCount,
      name: this.name,
      weight: this.weight,
      slug: this.slug,
      totalCharacters: this.totalCharacters,
      description: this.description,
      shortDescription: this.shortDescription,
      genres: this.genres,
      readingTime: this.readingTime,
      collection: this.collection,
      author: this.author,
      authorList: this.authorList,
      religion: this.religion,
      category: this.category,
      language: this.language,
      coverFile: this.coverFile,
      level: this.level,
      publish: this.publish,
      ilmId: this.ilmId,
      isServer: this.isServer,
      cover: this.cover,
      difficulty: this.difficulty,
      bookVocabularyLevel: this.bookVocabularyLevel,
      paragraphsNumber: this.paragraphsNumber,
      audio: this.audio,
      date: this.date,
      mediaSize: this.mediaSize,
      version: this.version,
      wordsPerMinute: this.wordsPerMinute,
      type: this.type,
      bookHasTrimmedSilence: this.bookHasTrimmedSilence
    });
  }
}

class ContentObject {
  constructor(rawContent) {
    this.chapterName = rawContent.chapterName || '';
    this.direction = rawContent.direction;
    this.hasContent = rawContent.hasContent;
    this.id = rawContent.id;
    this.clientSortId = rawContent.clientSortId;
    this.index = rawContent.index;
    this.isChapter = rawContent.isChapter || false;
    this.isFirst = rawContent.isFirst;
    this.isLast = rawContent.isLast;
    this.isSection = rawContent.isSection || false;
    this.offset = rawContent.offset || null;
    this.paraNum = rawContent.paraNum || '';
    this.paragraph = rawContent.paragraph;
    this.position = rawContent.position || null;
    this.start = rawContent.start || null;
    this.words = rawContent.words || 0;
  }
}

class ContentObjectView extends ContentObject {
  constructor(contentObject) {
    super(contentObject);
  }
}

class ContentObjectBuilder {
  setChapterName(chapterName) {
    this.chapterName = chapterName;
    return this;
  }
  setDirection(direction) {
    this.direction = direction;
    return this;
  }
  setHasContent(hasContent) {
    this.hasContent = hasContent;
    return this;
  }
  setId(id) {
    this.id = id;
    return this;
  }
  setClientSortId(clientSortId) {
    this.clientSortId = clientSortId;
    return this;
  }
  setIndex(index) {
    this.index = index;
    return this;
  }
  setIsChapter(isChapter) {
    this.isChapter = isChapter;
    return this;
  }
  setIsFirst(isFirst) {
    this.isFirst = isFirst;
    return this;
  }
  setIsLast(isLast) {
    this.isLast = isLast;
    return this;
  }
  setIsSection(isSection) {
    this.isSection = isSection;
    return this;
  }
  setOffset(offset) {
    this.offset = offset;
    return this;
  }
  setParaNum(paraNum) {
    this.paraNum = paraNum;
    return this;
  }
  setParagraph(paragraph) {
    this.paragraph = paragraph;
    return this;
  }
  setPosition(position) {
    this.position = position;
    return this;
  }
  setStart(start) {
    this.start = start;
    return this;
  }
  setWords(words) {
    this.words = words;
    return this;
  }

  build() {
    return new ContentObject(this);
  }
}

class BookItemPosition {
  constructor(items = [], bookId = '') {
    const hasItems = items.length !== 0;
    const itemIndex = items.findIndex(item => item.id === bookId);

    const isFirst = itemIndex === 0;
    const isLast = itemIndex === items.length - 1;
    this.prevBookSlug =
      isFirst || !hasItems
        ? ''
        : this._getItemIdentificator(items[itemIndex - 1]);
    this.nextBookSlug =
      isLast || !hasItems
        ? ''
        : this._getItemIdentificator(items[itemIndex + 1]);
  }

  _getItemIdentificator(item) {
    return item.slug || item.id;
  }
}

function createBookItemPosition(items, bookId) {
  return new BookItemPosition(items, bookId);
}

function createBookMetaView(meta) {
  return new BookMetaView(meta);
}

function createPublicationParaInfo(paraId, slug, meta, paraNum) {
  return new PublicationParaInfo(paraId, slug, meta, paraNum);
}

function createCompilationMetaView(meta) {
  return new CompilationMetaView(meta);
}

function createMeta(rawMeta) {
  return new BookMeta(rawMeta);
}
function createCompilationMeta(compilation) {
  return new CompilationMeta(compilation);
}

function createContentObject(rawContent) {
  return new ContentObject(rawContent);
}

function getContentObjectBuilder() {
  return new ContentObjectBuilder();
}

function createContentObjectView(contentObject) {
  return new ContentObjectView(contentObject);
}

export default {
  createBookItemPosition,
  createBookMetaView,
  createPublicationParaInfo,
  createCompilationMetaView,
  createMeta,
  createCompilationMeta,
  createContentObject,
  createContentObjectView,
  getContentObjectBuilder
};
