import PublicationsTypesEnum from '@shared/enums/PublicationsTypesEnum';
import SerializedRecentItem from './SerializedRecentItem';
import publicationUtils from '@/services/utils/publicationUtils';
import AssetResourcesEnum from '@/enums/AssetResourcesEnum';

class RecentItem {
  constructor(buildData) {
    this.name = buildData.name;
    this.lastReadingTime = buildData.lastReadingTime;
    this.publicationType = buildData.publicationType;
    this.componentName = '';
  }

  setComponentName(componentName) {
    this.componentName = componentName;
  }
}
// eslint-disable-next-line
class RecentItemBuilder {
  setName(name) {
    this.name = name;
    return this;
  }

  setLastReadingTime(lastReadingTime) {
    this.lastReadingTime = lastReadingTime;
    return this;
  }

  setType(type) {
    this.publicationType = type;
    return this;
  }

  build() {
    return new RecentItem(this);
  }
}

class RecentItemBook extends RecentItem {
  constructor(buildData) {
    super(buildData);
    this.id = buildData.id;
    this.publicationId = buildData.publicationId;
    this.author = buildData.author;
    this.isAuthorInTitle = publicationUtils.isAuthorInTitle(buildData);
    this.lastReadingTime = buildData.lastReadingTime;
    this.publicationType = buildData.publicationType;
    this.componentName = '';
    this.cover = buildData.cover;
    this.assetsSource = buildData.assetsSource || AssetResourcesEnum.REMOTE;
  }

  getId() {
    return this.publicationId;
  }

  compareByPublicationId(publicationId) {
    return this.publicationId === publicationId;
  }

  updateByMeta(meta) {
    this.author = meta.author;
    this.name = meta.name;
    this.cover = meta.cover;
    this.isAuthorInTitle = publicationUtils.isAuthorInTitle(meta);
  }
}

class RecentItemBookBuilder extends RecentItemBuilder {
  setId(id) {
    this.id = id;
    return this;
  }
  setPublicationId(publicationId) {
    this.publicationId = publicationId;
    return this;
  }

  setAuthor(author) {
    this.author = author;
    return this;
  }

  setCover(cover) {
    this.cover = cover;
    return this;
  }

  setAssetsSource(assetsSource) {
    this.assetsSource = assetsSource;
    return this;
  }

  build() {
    return new RecentItemBook(this);
  }
}

class RecentItemSyllabus extends RecentItemBook {
  constructor(buildData) {
    super(buildData);
  }
}
class RecentItemSyllabusBuilder extends RecentItemBookBuilder {
  build() {
    return new RecentItemSyllabus(this);
  }
}

class RecentItemStudyCourse extends RecentItem {
  constructor(buildData) {
    super(buildData);
    this.studyClassId = buildData.studyClassId;
    this.teachers = buildData.teachers;
  }

  getId() {
    return this.studyClassId;
  }
}

class RecentItemStudyCourseBuilder extends RecentItemBuilder {
  setStudyClassId(studyClassId) {
    this.studyClassId = studyClassId;
    return this;
  }

  setTeachers(teachers) {
    this.teachers = teachers;
    return this;
  }

  build() {
    return new RecentItemStudyCourse(this);
  }
}

class RecentItemCompilation extends RecentItem {
  constructor(buildData) {
    super(buildData);
    this.compilationId = buildData.compilationId;
    this.selectionsCount = buildData.selectionsCount;
    this.wordsCount = buildData.wordsCount;
    this.lastReadingTime = buildData.lastReadingTime;
    this.coverFileName = buildData.coverFileName;
    this.default = buildData.default;
  }

  compareByPublicationId(compilationId) {
    return this.compilationId === compilationId;
  }

  getId() {
    return this.compilationId;
  }

  updateByMeta(meta) {
    this.selectionsCount = meta.selectionsCount;
    this.wordsCount = meta.wordsCount;
    this.name = meta.title;
  }
}

class RecentItemCompilationBuilder extends RecentItemBuilder {
  setCompilationId(compilationId) {
    this.compilationId = compilationId;
    return this;
  }

  setSelectionsCount(selectionsCount) {
    this.selectionsCount = selectionsCount;
    return this;
  }

  setWordsCount(wordsCount) {
    this.wordsCount = wordsCount;
    return this;
  }

  setLastReadingTime(lastOpenedAt) {
    this.lastReadingTime = lastOpenedAt;
    return this;
  }

  setCoverFileName(coverFileName) {
    this.coverFileName = coverFileName;
    return this;
  }

  setDefault(isDefault) {
    this.default = isDefault;
    return this;
  }

  build() {
    return new RecentItemCompilation(this);
  }
}
class UpdateRecent {
  constructor(buildData) {
    switch (buildData.publicationType) {
      case PublicationsTypesEnum.BOOK:
        this.publicationId = buildData.publicationId;
        break;
      case PublicationsTypesEnum.STUDY_COURSE:
        this.studyClassId = buildData.studyClassId;
        break;
      case PublicationsTypesEnum.COMPILATION:
        this.compilationId = buildData.compilationId;
        break;
      default:
        break;
    }
    this.publicationType = buildData.publicationType;
  }
}

class UpdateRecentBuilder {
  setPublicationId(publicationId) {
    this.publicationId = publicationId;
    return this;
  }

  setStudyClassId(studyClassId) {
    this.studyClassId = studyClassId;
    return this;
  }

  setCompilationId(compilationId) {
    this.compilationId = compilationId;
    return this;
  }

  setPublicationType(publicationType) {
    this.publicationType = publicationType;
    return this;
  }

  build() {
    return new UpdateRecent(this);
  }
}

function createUpdateRecentSyllabus(syllabusId) {
  return _createUpdateRecent(syllabusId, PublicationsTypesEnum.SYLLABUS);
}

function createUpdateRecentBook(publicationId) {
  return _createUpdateRecent(publicationId, PublicationsTypesEnum.BOOK);
}

function createUpdateRecentStudyCourse(classId) {
  return _createUpdateRecent(classId, PublicationsTypesEnum.STUDY_COURSE);
}

function createUpdateRecentCompilation(compilationId) {
  return _createUpdateRecent(compilationId, PublicationsTypesEnum.COMPILATION);
}

function _createUpdateRecent(id, type) {
  const builder = new UpdateRecentBuilder();
  switch (type) {
    case PublicationsTypesEnum.BOOK:
      builder.setPublicationId(id).setPublicationType(type);
      break;
    case PublicationsTypesEnum.STUDY_COURSE:
      builder.setStudyClassId(id).setPublicationType(type);
      break;
    case PublicationsTypesEnum.COMPILATION:
      builder.setCompilationId(id).setPublicationType(type);
      break;
    default:
      break;
  }
  return builder.build();
}

function deserializeRecentItemFromObj(serializedRecentItem) {
  let builder;
  switch (serializedRecentItem.publicationType) {
    case PublicationsTypesEnum.BOOK:
      builder = new RecentItemBookBuilder();
      builder
        .setId(serializedRecentItem._id)
        .setPublicationId(serializedRecentItem.publicationId)
        .setAuthor(serializedRecentItem.author)
        .setName(serializedRecentItem.name)
        .setCover(serializedRecentItem.cover)
        .setAssetsSource(serializedRecentItem.assetsSource)
        .setLastReadingTime(serializedRecentItem.lastReadingTime)
        .setType(serializedRecentItem.publicationType);
      break;
    case PublicationsTypesEnum.COMPILATION:
      builder = new RecentItemCompilationBuilder();
      builder
        .setCompilationId(serializedRecentItem.compilationId)
        .setName(serializedRecentItem.name)
        .setSelectionsCount(serializedRecentItem.selectionsCount)
        .setWordsCount(serializedRecentItem.wordsCount)
        .setLastReadingTime(serializedRecentItem.lastReadingTime)
        .setCoverFileName(serializedRecentItem.coverFileName)
        .setDefault(serializedRecentItem.default)
        .setType(serializedRecentItem.publicationType);
      break;
    default:
      break;
  }
  return builder.build();
}

function desirilizeRecentItem(serializedRecentItem, publication) {
  let builder;
  switch (serializedRecentItem.publicationType) {
    case PublicationsTypesEnum.SYLLABUS:
      builder = new RecentItemSyllabusBuilder();
      builder
        .setId(serializedRecentItem._id)
        .setPublicationId(serializedRecentItem.publicationId)
        .setAuthor(publication.author)
        .setName(publication.name)
        .setCover(publication.cover)
        .setLastReadingTime(serializedRecentItem.lastReadingTime)
        .setType(serializedRecentItem.publicationType);
      break;
    case PublicationsTypesEnum.BOOK:
      builder = new RecentItemBookBuilder();
      builder
        .setId(serializedRecentItem._id)
        .setPublicationId(serializedRecentItem.publicationId)
        .setAuthor(serializedRecentItem.author)
        .setName(serializedRecentItem.name)
        .setCover(serializedRecentItem.cover)
        .setAssetsSource(publication.assetsSource)
        .setLastReadingTime(serializedRecentItem.lastReadingTime)
        .setType(serializedRecentItem.publicationType);
      break;
    case PublicationsTypesEnum.STUDY_COURSE:
      builder = new RecentItemStudyCourseBuilder();
      builder
        .setStudyClassId(serializedRecentItem.studyClassId)
        .setName(publication.name)
        .setLastReadingTime(
          serializedRecentItem.lastOpenedAt ||
            serializedRecentItem.lastReadingTime
        )
        .setTeachers(publication.teachers)
        .setType(serializedRecentItem.publicationType);
      break;
    case PublicationsTypesEnum.COMPILATION:
      builder = new RecentItemCompilationBuilder();
      builder
        .setCompilationId(serializedRecentItem.compilationId)
        .setName(publication.title)
        .setSelectionsCount(publication && publication.selectionsCount)
        .setWordsCount(publication && publication.wordsCount)
        .setLastReadingTime(serializedRecentItem.lastReadingTime)
        .setCoverFileName(publication.coverFileName)
        .setDefault(publication.default)
        .setType(serializedRecentItem.publicationType);
      break;
    default:
      break;
  }
  return builder.build();
}

function mergedRecentItem(serializedRecentItem, publication) {
  if (!serializedRecentItem || !publication) {
    return null;
  }
  let builder;
  const lastOpenedAt = new Date().getTime();
  switch (serializedRecentItem.publicationType) {
    case PublicationsTypesEnum.STUDY_COURSE:
      builder = new RecentItemStudyCourseBuilder();
      builder
        .setStudyClassId(serializedRecentItem.studyClassId)
        .setName(publication.name)
        .setLastReadingTime(serializedRecentItem.lastOpenedAt)
        .setTeachers(publication && publication.teachers)
        .setType(serializedRecentItem.publicationType);
      break;
    case PublicationsTypesEnum.BOOK:
      builder = new RecentItemBookBuilder();
      builder
        .setId(null)
        .setPublicationId(serializedRecentItem.publicationId)
        .setAuthor(publication.author)
        .setName(publication.name)
        .setCover(publication.cover)
        .setLastReadingTime(serializedRecentItem.lastOpenedAt)
        .setType(serializedRecentItem.publicationType);
      break;
    case PublicationsTypesEnum.COMPILATION:
      builder = new RecentItemCompilationBuilder();
      builder
        .setCompilationId(serializedRecentItem.compilationId || publication.id)
        .setName(publication.title)
        .setSelectionsCount(publication && publication.selectionsCount)
        .setWordsCount(publication && publication.wordsCount)
        .setLastReadingTime(serializedRecentItem.lastOpenedAt || lastOpenedAt)
        .setType(serializedRecentItem.publicationType);
      break;
    default:
      break;
  }
  return builder.build();
}

export default {
  createUpdateRecentSyllabus,
  createUpdateRecentBook,
  createUpdateRecentStudyCourse,
  createUpdateRecentCompilation,
  desirilizeRecentItem,
  deserializeRecentItemFromObj,
  mergedRecentItem,
  createSerializedRecentBookItemFromMeta:
    SerializedRecentItem.createSerializedRecentBookItemFromMeta
};
