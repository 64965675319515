<template>
  <nuxt-link
    :to="activityRoute"
    :title="$t('Accessibility.toDashboard.tooltip')"
    :aria-label="$t('Accessibility.toDashboard')"
  >
    <CircleProgress
      class="toolbar-activity-circle"
      :progress="progress"
      :text="day"
      text-color="var(--secondary-accent-color, #FC6539)"
      :size="44"
      clockwise
      :stroke-width="3.5"
      stroke-color="#FC6539"
      shade-color="#E2E8F0"
    />
  </nuxt-link>
</template>

<script>
import dayJS from '@/dayJS';
import { mapGetters } from 'vuex';
import AppStateEnum from '@/enums/AppStateEnum';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import CircleProgress from '@/components/base/CircleProgress/CircleProgress.vue';

export default {
  name: 'ToolbarActivityControl',
  components: {
    CircleProgress
  },
  data() {
    return {
      activityRoute: { name: AppStateEnum.ACTIVITY_DASHBOARD }
    };
  },
  computed: {
    ...mapGetters('ContextStore', ['getSystemLanguage']),
    day() {
      const day = new Date().getDay();
      const weekDays = dayJS.getWeekdaysMin(this.getSystemLanguage);
      const currentDay = weekDays.find(d => d.dayIndex === day);
      return currentDay.name;
    },
    currentActivity() {
      const formattedTodayDate = dayJS
        .get()
        .subtract(4, 'hours')
        .format(AppConstantsUtil.USER_ACTIVITY_DATE_FORMAT);
      const activity =
        this.$store.getters['ActivityStore/getUserActivity'](
          formattedTodayDate
        ) || [];
      return activity?.[0];
    },
    currentGoal() {
      const day = new Date().getDay();
      const settings = this.$store.getters[
        'ActivityStore/getUserActivitySettings'
      ];
      if (!settings) {
        return;
      }
      const todaySettings = settings.weekdays.find(
        weekday => weekday.day === day
      );
      return todaySettings?.goal ?? 0;
    },
    progress() {
      let goal = this.currentGoal ?? 0;
      const progress =
        goal && this.currentActivity
          ? (this.currentActivity.minutesRead / goal) * 100
          : 0;
      return progress;
    }
  }
};
</script>

<style lang="less">
.toolbar-activity-circle {
  .progress-text {
    font-size: 12px;
    font-weight: 700;
  }
  .sepia-theme-template & {
    --transparent-color: #d0cbc2 !important;
  }
  .night-theme-template & {
    --transparent-color: #686868 !important;
    --current-color: #fbc333 !important;
    --text-color: #fbc333 !important;
  }
}
</style>
