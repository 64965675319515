import RequestService from '@/services/RequestService';
import RestService from '@/services/RestService';
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('RequestService.js');

async function sendTypoReport(data) {
  try {
    await RequestService.request('post', 'Publications', 'typoReport', data);
  } catch (error) {
    logger.error(error);
  }
}

async function generateSuggestions() {
  try {
    await RequestService.request('post', 'Publications', 'generateSuggestions');
  } catch (error) {
    logger.error(`generateSuggestions failed with ${error}`);
  }
}

async function fetchPublicationsCountByCategories() {
  try {
    return await RestService.restRequest(
      'get',
      'Publications',
      'getPublicationsCountByCategories'
    );
  } catch (error) {
    logger.error(`fetchPublicationsCountByCategories failed with ${error}`);
  }
}

export default {
  sendTypoReport,
  generateSuggestions,
  fetchPublicationsCountByCategories
};
