import Vue from 'vue';
import StoreKeys from '@/enums/StoreKeyEnum';
import AssetResourcesEnum from '@/enums/AssetResourcesEnum';
import ManagePublicationsStates from '@/enums/ManagePublicationsStatesEnum';
import PublicationsTypes from '@shared/enums/PublicationsTypesEnum';
import CustomCategoriesEnum from '@shared/enums/CustomCategoriesEnum';
import PublicationVisibilityStatusesEnum from '@/enums/PublicationVisibilityStatusesEnum';

import base62 from '@shared/utils/base62.mjs';
import urlUtils from '@shared/utils/url';
import languageUtils from '@shared/contentAnalyzer/languageUtils.mjs';
import publicationUtils from '@/services/utils/publicationUtils';

import {
  Book,
  SuggestedBook,
  Collection,
  Compilation,
  StudyGuide,
  Syllabus,
  LibrarySet,
  StudyCourse,
  StudyCourseView
} from '@/classes/factories/library/PublicationsConstructors.js';

class LibraryView {
  constructor() {
    this.current = null;
    this.publicationsList = {};
    this.publicationsPositionMap = {};
    this.publicationIdsBySlug = {};
  }

  toJSON() {
    const jsonPublicationsList = {};
    for (const lang of Object.keys(this.publicationsList)) {
      const publications = this.publicationsList[lang];
      for (let i = 0; i < publications.length; i++) {
        const publication = publications[i];
        jsonPublicationsList[lang] = jsonPublicationsList[lang] || [];
        jsonPublicationsList[lang].push(publication.toParsedJson());
      }
    }
    return JSON.stringify({
      publicationsList: jsonPublicationsList,
      publicationsPositionMap: this.publicationsPositionMap,
      publicationIdsBySlug: this.publicationIdsBySlug
    });
  }

  [Symbol.iterator]() {
    const publicationsList = this.publicationsList;

    let langIndex = 0;
    let index = 0;
    let done = false;
    const langs = Object.keys(publicationsList);
    let langsKey = langs[langIndex];

    function updateIndexes() {
      if (typeof publicationsList[langsKey][index] === 'undefined') {
        if (typeof langs[langIndex + 1] === 'undefined') {
          done = true;
        } else {
          langIndex += 1;
          langsKey = langs[langIndex];
          index = 0;
        }
      }
    }

    return {
      next() {
        updateIndexes();

        return {
          done: done,
          value: publicationsList[langsKey][index++]
        };
      }
    };
  }

  sortByWeight(publicationsList) {
    const sortType = publicationUtils.sortPublicationEnum.WEIGTH;
    for (const lang in publicationsList) {
      publicationsList[lang] = publicationUtils.sortPublication(
        publicationsList[lang],
        sortType
      );
    }
    return publicationsList;
  }

  /**
   * @param {Array.<Object>} libraryData
   * @param {String} publicationsType
   */
  fill(libraryData, publicationsType, source, isAdminPage) {
    this.updatedPublications = [];
    for (const lang in libraryData) {
      for (const pub of libraryData[lang]) {
        if (this._filter(pub, publicationsType)) {
          const pubObject = this._createPublicationObject(pub);
          const savedPub = this.getById(pubObject.id);
          if (
            savedPub &&
            savedPub.type === PublicationsTypes.COLLECTION &&
            savedPub.version !== pubObject.version &&
            typeof savedPub.merge === 'function'
          ) {
            savedPub.merge(pubObject);
            const collectionRecalcedReadingTime = this._recalcReadingTime(
              savedPub
            );
            this.updatedPublications.push(collectionRecalcedReadingTime);
          }
          if (!savedPub) {
            if (process.env.IS_EDITOR) {
              Vue.set(
                this.publicationsList,
                lang,
                this.publicationsList[lang] || []
              );
            } else {
              if (!this.publicationsList[lang]) {
                this.publicationsList[lang] = [];
              }
            }
            const pubWithRecalcedReadingTime = this._recalcReadingTime(pub);
            this.publicationsList[lang].push(
              this._createPublicationObject(pubWithRecalcedReadingTime)
            );
          } else if (
            source === AssetResourcesEnum.REMOTE &&
            savedPub.updateAccessStatus
          ) {
            savedPub.updateAccessStatus(pubObject.accessStatus);
          }
        }
      }
    }

    if (!isAdminPage) {
      this.sortByWeight(this.publicationsList);
    }

    this.publicationsPositionMap = this._generatePositionMap();
  }

  mergeWith(otherLibraryView) {
    for (const lang in otherLibraryView.publicationsList) {
      if (!this.publicationsList[lang]) {
        this.publicationsList[lang] = [];
      }
      for (const pub of otherLibraryView.publicationsList[lang]) {
        this.publicationsList[lang].push(pub);
      }
    }

    this.publicationsPositionMap = this._generatePositionMap();
  }

  getAuthorListByCategory({ publications }) {
    const authorList = publicationUtils.createAuthorList(publications);
    return authorList;
  }

  _recalcReadingTime(pub) {
    if (pub.type !== PublicationsTypes.COLLECTION) {
      return pub;
    }
    const totalReadingTime = pub.items.reduce(
      (result, p) => (result += p.readingTime || 0),
      0
    );
    const newCollectionObj = this._createPublicationObject({
      ...pub,
      readingTime: totalReadingTime
    });
    return newCollectionObj;
  }

  addPublication(rawPublication) {
    const pubObject = this._createPublicationObject(rawPublication);
    const lang = pubObject.language || 'common';
    if (!this.publicationsList.hasOwnProperty(lang)) {
      this.publicationsList[lang] = [];
    }
    this.publicationsPositionMap[pubObject.id] = new PositionMap(
      lang,
      this.publicationsList[lang].length
    );
    this.publicationsList[lang].push(pubObject);
    return pubObject;
  }

  resetToDefault(excludePublicationIds) {
    const pubObjects = excludePublicationIds.map(pubId => this.getById(pubId));

    this.current = null;
    this.publicationsList = {};
    this.publicationsPositionMap = {};
    this.publicationIdsBySlug = {};

    pubObjects.forEach(pub => {
      this.addPublication(pub);
    });
  }

  getById(pubId) {
    let pub = null;
    if (this.publicationsPositionMap[pubId]) {
      const { lang, index, folderIndex } = this.publicationsPositionMap[pubId];
      pub = this.publicationsList[lang][index];
      if (folderIndex !== null) {
        pub = pub.items[folderIndex];
      }
    }

    return pub;
  }

  getBySlug(slug) {
    let pub = null;
    const pubId = this.publicationIdsBySlug[slug];
    if (pubId) {
      pub = this.getById(pubId);
    }
    return pub;
  }

  removePublication(publicationId) {
    if (!this.publicationsPositionMap.hasOwnProperty(publicationId)) {
      return;
    }
    const positionMap = this.publicationsPositionMap[publicationId];
    this.publicationsList[positionMap.lang].splice(positionMap.index, 1);
    delete this.publicationsPositionMap[publicationId];
    this.publicationsPositionMap = this._generatePositionMap();
  }

  replacePublication(publicationId, rawPublication) {
    if (!this.publicationsPositionMap.hasOwnProperty(publicationId)) {
      return;
    }
    const pubObject = this._createPublicationObject(rawPublication);
    const positionMap = this.publicationsPositionMap[publicationId];
    let pubForUpdate = pubObject;
    if (pubObject.collection) {
      const collection = this.publicationsList[positionMap.lang][
        positionMap.index
      ];
      collection.items.splice(positionMap.folderIndex, 1, pubObject);
      pubForUpdate = this._createPublicationObject(collection);
    }
    this.publicationsList[positionMap.lang].splice(
      positionMap.index,
      1,
      pubForUpdate
    );
  }

  search(criteria, user) {
    let searcher;

    switch (criteria.state) {
      case ManagePublicationsStates.COMPILATIONS:
        searcher = new CompilationsSearcher(
          this.publicationsList[criteria.lang],
          this.publicationsList['common']
        );
        break;
      case ManagePublicationsStates.MY_MATERIALS:
        searcher = new MaterialSearcher(
          this.publicationsList[criteria.lang],
          this.publicationsList['common']
        );
        break;
      case ManagePublicationsStates.INSIDE_SYLLABUS:
        searcher = new SearcherInsideSyllabus(
          this.publicationsList[criteria.lang],
          this.publicationsList['common']
        );
        break;
      case ManagePublicationsStates.INSIDE_LIBRARY_SET:
        searcher = new SearcherInsideLibrarySet(
          this.publicationsList[criteria.lang],
          this.publicationsList['common']
        );
        break;
      case ManagePublicationsStates.STUDY:
        searcher = new StudyCourseStudySearcher(
          this.publicationsList[criteria.lang],
          this.publicationsList['common']
        );
        break;
      case ManagePublicationsStates.RECENT_BOOKS:
        searcher = new RecentBookSearcher(
          this.publicationsList[criteria.lang],
          this.publicationsList['common']
        );
        break;
      case ManagePublicationsStates.NEW_BOOKS:
        searcher = new NewBookSearcher(
          this.publicationsList[criteria.lang],
          this.publicationsList['common']
        );
        break;
      case ManagePublicationsStates.FAVORITES:
        searcher = new FavoriteBookSearcher(this.publicationsList);
        break;
      case ManagePublicationsStates.COLLECTION:
        searcher = new CollectionBookSearcher(
          this.publicationsList[criteria.lang],
          this.publicationsList['common']
        );
        break;
      case ManagePublicationsStates.BOOK_LIST:
        searcher = new BookListSearcher(
          this.publicationsList[criteria.lang],
          this.publicationsList['common']
        );
        break;
      case ManagePublicationsStates.LIBRARY_SET:
        searcher = new LibrarySetSearcher(
          this.publicationsList[criteria.lang],
          this.publicationsList['common']
        );
        break;
      default:
        searcher = new BooksSearcher(
          this.publicationsList[criteria.lang],
          this.publicationsList['common']
        );
        break;
    }

    const pubs = searcher.search(criteria, user);

    return pubs;
  }

  getViewData() {
    return this.publicationsList;
  }

  _generatePositionMap() {
    // const resultMap = {};
    const resultMapById = {};
    const publicationIdsBySlug = {};

    for (const lang in this.publicationsList) {
      for (let i = 0; i < this.publicationsList[lang].length; i++) {
        const pub = this.publicationsList[lang][i];
        if (pub.type === ManagePublicationsStates.COLLECTION) {
          for (let j = 0; j < pub.items.length; j++) {
            const id = pub.items[j].id;
            const slug = pub.items[j].slug || '';
            if (slug) {
              publicationIdsBySlug[slug] = id;
            }
            resultMapById[id] = new PositionMap(lang, i, j);
          }
        }
        resultMapById[pub.id] = new PositionMap(lang, i);
        if (pub.slug) {
          publicationIdsBySlug[pub.slug] = pub.id;
        }
      }
    }

    this.publicationIdsBySlug = publicationIdsBySlug;
    return resultMapById;
  }

  /***
   * @param {Object} publication
   * @param {String} publicationsType
   * @returns {Boolean}
   */
  _filter(publication, publicationsType) {
    return StoreKeys[publicationsType] === StoreKeys[publication.type];
  }

  _createPublicationObject(pub) {
    const constructorByType = {
      [PublicationsTypes.BOOK]: Book,
      [PublicationsTypes.SUGGESTED_BOOK]: SuggestedBook,
      [PublicationsTypes.COLLECTION]: Collection,
      [PublicationsTypes.STUDY_GUIDE]: StudyGuide,
      [PublicationsTypes.SYLLABUS]: Syllabus,
      [PublicationsTypes.LIBRARY_SET]: LibrarySet,
      [PublicationsTypes.STUDY_COURSE]: StudyCourse,
      [PublicationsTypes.COMPILATION]: Compilation
    };

    const pubObject = new constructorByType[pub.type](pub);

    return pubObject;
  }
}

class PositionMap {
  constructor(lang, index, folderIndex = null) {
    this.lang = lang;
    this.index = index;
    this.folderIndex = folderIndex;
  }
}

class Searcher {
  constructor(...pubLists) {
    this.pubList = [];
    for (const publications of pubLists) {
      [].push.apply(this.pubList, publications);
    }
  }
  search() {
    throw new Error('Strategy#execute has to be overridden.');
  }
}

function normalizeStr(str, lang) {
  return languageUtils.replaceDiacritic(str.toLowerCase(), lang);
}

function isValidCategory(pub, category) {
  if (!category) {
    return true;
  }
  return pub.category == category;
}

function searchByNameAuthorCategory(pub, term) {
  const lang = pub.language;
  return (
    normalizeStr(pub.name, lang).indexOf(term) !== -1 ||
    (pub.author && normalizeStr(pub.author, lang).indexOf(term) !== -1) ||
    normalizeStr(pub.category, lang).indexOf(term) !== -1
  );
}

function searchByAuthor(pub, author) {
  if (!author) {
    return true;
  }
  if (!pub?.authorList?.length) {
    return base62.stringToBase62(pub.author.trim()) === author;
  }
  return pub.authorList.some(a => {
    if (a.slug) {
      return a.slug === author;
    }
    return base62.stringToBase62(a.name.trim()) === author;
  });
}

class SearcherInsideSyllabus extends Searcher {
  search(criteria) {
    let searchResults = [];
    const term = normalizeStr(criteria.term, criteria.lang);
    for (const pub of this.pubList) {
      if (
        pub.hasOwnProperty('accessStatus') &&
        pub.accessStatus !== PublicationVisibilityStatusesEnum.AVAILABLE
      ) {
        continue;
      }
      if (searchByNameAuthorCategory(pub, term)) {
        searchResults.push(pub);
      }
    }

    return searchResults;
  }
}

class SearcherInsideLibrarySet extends Searcher {
  search(criteria) {
    let searchResults = [];
    const term = normalizeStr(criteria.term, criteria.lang);
    const category = criteria.category;

    for (const pub of this.pubList) {
      if (!this.isAllowedBook(pub, category)) {
        continue;
      }

      if (pub.type === PublicationsTypes.COLLECTION && pub.items) {
        for (const item of pub.items) {
          if (
            this.isAllowedBook(item, category) &&
            searchByNameAuthorCategory(item, term)
          ) {
            searchResults.push(item);
          }
        }
        continue;
      }

      if (searchByNameAuthorCategory(pub, term)) {
        searchResults.push(pub);
      }
    }

    return searchResults;
  }

  isAllowedBook(pub, category) {
    const isAvailableStatus =
      pub.hasOwnProperty('accessStatus') &&
      pub.accessStatus === PublicationVisibilityStatusesEnum.AVAILABLE;

    if (pub.type === PublicationsTypes.COLLECTION) {
      return isAvailableStatus;
    }

    return (
      pub.audio &&
      isAvailableStatus &&
      pub.type === PublicationsTypes.BOOK &&
      isValidCategory(pub, category)
    );
  }
}

class StudyCourseStudySearcher extends Searcher {
  search() {
    return this.pubList.map(pub => new StudyCourseView(pub));
  }
}
class BooksSearcher extends Searcher {
  search(criteria) {
    return this.searchInPubLanguageList(criteria, this.pubList);
  }

  searchInPubLanguageList(criteria, languagePubList) {
    let searchResults = [];
    const term = normalizeStr(criteria.term, criteria.lang);

    for (let pub of languagePubList) {
      if (
        criteria.isCollectionIncluded &&
        pub.type === PublicationsTypes.COLLECTION
      ) {
        searchResults.push.apply(
          searchResults,
          this.searchInPubLanguageList(criteria, pub.items)
        );
      }
      if (
        this.isAllowedBook(pub, criteria) &&
        searchByNameAuthorCategory(pub, term) &&
        searchByAuthor(pub, criteria.authorSlug)
      ) {
        searchResults.push(pub);
      }
    }

    return searchResults;
  }

  isAllowedBook(pub, criteria) {
    const pubCategory = urlUtils.encodeStringToPath(pub.category.trim());
    const isCriteriaLang = pub.language === criteria.lang || !criteria.lang;
    const isOriginal = !pub.hasOwnProperty('isOriginal') || pub.isOriginal;
    const isAllowPubState =
      !criteria.stateFilter || pub.accessStatus === criteria.stateFilter;
    const isSyllabus = pub.type === PublicationsTypes.SYLLABUS;
    const isOfflineOnly =
      !criteria.offline ||
      (pub.isContentDownloaded && (!pub.audio || pub.isAudioDownloaded));
    const isAudioOnly = !criteria.audio || (criteria.audio && pub.audio);
    const isWithoutAudioOnly =
      !criteria.withoutAudio || (criteria.withoutAudio && !pub.audio);
    const ignoreAudio = criteria.audio && criteria.withoutAudio;
    const isCategory = !criteria.category || criteria.category === pub.category;
    const isInCategories =
      !criteria.categories?.length ||
      criteria.categories.some(
        c => urlUtils.encodeStringToPath(c) === pubCategory
      );
    const isInGenres =
      !criteria.genres?.length ||
      criteria.genres.find(
        g =>
          pub.genres?.length &&
          pub.genres.some(
            pubGen =>
              urlUtils.encodeStringToPath(pubGen) ===
              urlUtils.encodeStringToPath(g)
          )
      );
    const isInDuration =
      !criteria.durations?.length ||
      criteria.durations.find(
        ([min, max]) => pub.readingTime >= min && pub.readingTime < max
      );
    const difficulty = parseInt(pub.difficulty);
    const isInDifficultyRange =
      !criteria.difficultyRange ||
      (criteria.difficultyRange[0] <= difficulty &&
        criteria.difficultyRange[1] >= difficulty);
    const isExcludedFromCategories =
      criteria.excludedCategories?.length &&
      criteria.excludedCategories.includes(pub.category);
    return (
      isCriteriaLang &&
      isOriginal &&
      isAllowPubState &&
      !isSyllabus &&
      (ignoreAudio || (isAudioOnly && isWithoutAudioOnly)) &&
      isOfflineOnly &&
      isCategory &&
      isInGenres &&
      isInCategories &&
      isInDuration &&
      isInDifficultyRange &&
      !isExcludedFromCategories
    );
  }
}

class LibrarySetSearcher extends Searcher {
  search() {
    let searchResults = [];
    for (const pub of this.pubList) {
      if (this.isAllowedBook(pub)) {
        searchResults.push(pub);
      }
    }

    return searchResults;
  }

  isAllowedBook(pub) {
    const isOriginal = !pub.hasOwnProperty('isOriginal') || pub.isOriginal;
    return isOriginal && pub.type === PublicationsTypes.LIBRARY_SET;
  }
}

class BookListSearcher extends BooksSearcher {
  search(criteria) {
    let searchResults = [];
    const term = normalizeStr(criteria.term, criteria.lang);
    for (const pub of this.pubList) {
      if (
        !criteria.isCollectionIncluded &&
        pub.type === PublicationsTypes.COLLECTION
      ) {
        continue;
      }
      if (pub.type === PublicationsTypes.COLLECTION) {
        const collectionItems = pub.items.filter(collectionItem => {
          return (
            this.isAllowedBook(collectionItem, criteria) &&
            searchByNameAuthorCategory(collectionItem, term)
          );
        });
        if (
          collectionItems.length ||
          (this.isAllowedBook(pub, criteria) &&
            searchByNameAuthorCategory(pub, term))
        ) {
          const collection = new Collection({ ...pub, items: collectionItems });
          searchResults.push(collection);
        }
        continue;
      }

      if (
        criteria.isBooksIncluded &&
        this.isAllowedBook(pub, criteria) &&
        searchByNameAuthorCategory(pub, term)
      ) {
        searchResults.push(pub);
      }
    }

    return searchResults;
  }
  isAllowedBook(pub, criteria) {
    const isCollectionAllowed =
      pub.type === PublicationsTypes.COLLECTION &&
      criteria.isCollectionIncluded;
    const isBookAllowed =
      pub.type === PublicationsTypes.BOOK &&
      (criteria.isBooksIncluded || pub.collection);
    return (
      (isCollectionAllowed || isBookAllowed) &&
      super.isAllowedBook(pub, criteria)
    );
  }
}

class RecentBookSearcher extends BooksSearcher {
  search(criteria, user, pubList) {
    let searchResults = [];
    const term = normalizeStr(criteria.term, criteria.lang);
    const allowBookList = criteria.allowBookList;
    if (allowBookList.length === 0) {
      return [];
    }
    const bookIdMap = allowBookList.reduce((idMap, id) => {
      idMap[id] = null;
      return idMap;
    }, {});
    const publications = pubList || this.pubList;
    for (let pub of publications) {
      if (pub.type === PublicationsTypes.COLLECTION) {
        const filteredCollectionItems = this.search(criteria, user, pub.items);
        if (filteredCollectionItems.length) {
          searchResults.push(...filteredCollectionItems);
        }
        continue;
      }
      if (!bookIdMap.hasOwnProperty(pub.id)) {
        continue;
      }
      pub = new Book({
        ...pub,
        category: CustomCategoriesEnum.RECENT,
        bookCategory: pub.category
      });
      if (!this.isAllowedBook(pub, criteria)) {
        continue;
      }
      if (searchByNameAuthorCategory(pub, term)) {
        searchResults.push(pub);
      }
    }

    return searchResults;
  }
}

class NewBookSearcher extends BooksSearcher {
  search(criteria) {
    let searchResults = [];
    const term = normalizeStr(criteria.term, criteria.lang);
    const allowBookList = criteria.allowBookList;
    if (allowBookList.length === 0) {
      return [];
    }
    const bookIdMap = allowBookList.reduce((idMap, id, index) => {
      idMap[id] = index;
      return idMap;
    }, {});

    for (let pub of this.pubList) {
      if (!bookIdMap.hasOwnProperty(pub.id)) {
        continue;
      }
      pub = {
        ...pub,
        category: CustomCategoriesEnum.NEW,
        bookCategory: pub.category
      };
      switch (pub.type) {
        case PublicationsTypes.COLLECTION:
          pub = new Collection(pub);
          break;
        default:
          pub = new Book(pub);
          break;
      }
      if (
        !this.isAllowedBook(pub, criteria) ||
        !searchByNameAuthorCategory(pub, term)
      ) {
        continue;
      }

      const bookPosition = bookIdMap[pub.id];
      searchResults[bookPosition] = pub;
      if (
        criteria.isCollectionIncluded &&
        pub.type === PublicationsTypes.COLLECTION
      ) {
        const collectionItems = pub.items.reduce((result, p) => {
          if (
            p &&
            this.isAllowedBook(p, criteria) &&
            searchByNameAuthorCategory(p, term)
          ) {
            result.push(p);
          }
          return result;
        }, []);
        searchResults.push(...collectionItems);
      }
    }

    searchResults = searchResults.filter(searchResult => !!searchResult);

    return searchResults;
  }
}

class FavoriteBookSearcher extends BooksSearcher {
  constructor(publicationsList) {
    super([]);
    this.pubListByLang = publicationsList;
  }

  search(criteria) {
    const searchResults = [];
    for (const lang in this.pubListByLang) {
      const languagePubList = this.pubListByLang[lang];
      searchResults.push.apply(
        searchResults,
        this.searchInPubLanguageList(criteria, languagePubList)
      );
    }
    return searchResults;
  }

  isAllowedBook(pub, criteria) {
    return (
      super.isAllowedBook(pub, criteria) &&
      criteria.allowBookList.includes(pub.id)
    );
  }
}

class CollectionBookSearcher extends BooksSearcher {
  search(criteria) {
    let searchResults = [];
    const term = normalizeStr(criteria.term, criteria.lang);
    const collection = this.pubList.find(
      pub => pub.id === criteria.collectionId
    );
    if (!collection) {
      return searchResults;
    }

    const collectionBooks = collection.items;
    for (let pub of collectionBooks) {
      if (
        this.isAllowedBook(pub, criteria) &&
        searchByNameAuthorCategory(pub, term)
      ) {
        searchResults.push(pub);
      }
    }

    return searchResults;
  }
}

class MaterialSearcher extends Searcher {
  search(criteria, user) {
    let searchResults = [];
    const term = normalizeStr(criteria.term, criteria.lang);
    const userId = user.id;
    const isAdmin = user.adminRole;
    const isEditor = user.editorRole;
    for (const pub of this.pubList) {
      if (!isEditor) {
        continue;
      }
      if (pub.hasOwnProperty('isOriginal') && !pub.isOriginal) {
        continue;
      }
      if (
        (this.isAuthor(pub, userId) || isAdmin) &&
        searchByNameAuthorCategory(pub, term)
      ) {
        searchResults.push(pub);
      }
    }

    return searchResults;
  }

  isAuthor(pub, userId) {
    if (pub.type === PublicationsTypes.STUDY_GUIDE) {
      return pub.userIds && pub.userIds.includes(userId);
    } else if (pub.type === PublicationsTypes.SYLLABUS) {
      return pub.userId && pub.userId === userId;
    }
    return false;
  }
}

class CompilationsSearcher extends Searcher {
  search(criteria) {
    let searchResults = [];
    const term = criteria.term.toLowerCase();
    for (const pub of this.pubList) {
      if (pub.title && pub.title.toLowerCase().indexOf(term) !== -1) {
        searchResults.push(pub);
      }
    }

    return searchResults;
  }
}

export default LibraryView;
