import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('PublicationsLoaderRemoteService.js');

import FeatureDetectorService from '@/services/FeatureDetector/FeatureDetectorService';
import RestService from '@/services/RestService';
import BuildValidateService from '@/services/BuildValidateService';
import RequestService from '@/services/RequestService';
import IndexedDBWrapper from '@/services/utils/IndexedDBWrapper';
import PromiseUtil from '@/services/utils/PromiseUtil';

import PublicationsTypes from '@shared/enums/PublicationsTypesEnum';
import AssetResourcesEnum from '@/enums/AssetResourcesEnum';
import get from 'lodash/get';
const libraryTableNames = {
  LIBRARY_ADMIN: 'library_admin',
  LIBRARY_READER: 'library'
};
const isEditor = process.env.IS_EDITOR;
const LIBRARY_JSON = 'library.json';
const LIBRARY_TABLE = isEditor
  ? libraryTableNames.LIBRARY_ADMIN
  : libraryTableNames.LIBRARY_READER;
const LIBRARY_SET_ID = 'librarySetJson';
const SUGGESTED_BOOK_ID = 'suggestedBookJson';
const CATEGORY_INFO_ID = 'categoryInfo';
const LIBRARY_HASH_ID = 'libraryHash';
const LIBRARY_JSON_ID = 'libraryJson';
const loaderContext = {
  serverUrl: ''
};
let initDefer = PromiseUtil.createDeferred(
  100_000,
  'wait init PublicationsLoaderRemoteService'
);
let dbWrapper;

const requestEnum = {
  BOOK_COLLECTION: 'bookCollectionRequest',
  SUGGESTED_BOOKS: 'suggestedBooksRequest',
  STUDY_GUIDE_SYLLABUS: 'StudyGuideSyllabusRequest',
  LIBRARY_SET: 'LibrarySet',
  STUDY_COURSE: 'StudyCourseRequest',
  COMPILATION: 'CompilationRequest'
};

async function init(Context) {
  loaderContext.isEditor = isEditor;
  loaderContext.mode = Context.mode;
  loaderContext.serverUrl = Context.serverUrl;
  await initIndexedDB(loaderContext.serverUrl);
  initDefer.resolve();
  return this;
}

async function initIndexedDB(name) {
  const tables = {
    [libraryTableNames.LIBRARY_ADMIN]: 'id',
    [libraryTableNames.LIBRARY_READER]: 'id'
  };
  const suffix = 'remoteCache';
  const version = 2;
  const { indexDB } = await FeatureDetectorService.getDetections();
  dbWrapper = new IndexedDBWrapper(name, suffix, indexDB).initTables(
    tables,
    version
  );
}

async function getDbWrapper() {
  await initDefer.promise;
  return dbWrapper;
}

async function loadPublicationCategoryInfo() {
  try {
    const response = await RestService.restRequest(
      'get',
      'Publications',
      'categoryInfo'
    );
    const categoryInfo = response?.data?.categoryInfo || [];
    categoryInfo.id = CATEGORY_INFO_ID;
    await _setLocalData(LIBRARY_TABLE, categoryInfo);
    delete categoryInfo.id;
    return categoryInfo;
  } catch (error) {
    logger.error(
      `Get error on load category info load local data error: ${error}`
    );
    const localData = await _getLocalData(LIBRARY_TABLE, CATEGORY_INFO_ID);
    delete localData.id;
    return localData;
  }
}

async function getPublicationsData({
  publicationsTypes,
  errorHandler,
  forceRemote,
  isSuggestionsEnabled,
  retry = 5,
  isGuestUser,
  isLoggingOut
}) {
  try {
    const response = await getRemoteDataByTypes(
      publicationsTypes,
      forceRemote,
      isSuggestionsEnabled,
      isGuestUser,
      isLoggingOut
    );
    return response;
  } catch (error) {
    const msg = error?.message || '';
    const isNetwokError = msg.includes('Network Error');
    if (isNetwokError) {
      logger.warn(`Network error: ${error}`);
    } else {
      logger.error(
        `get error on load publications types: ${publicationsTypes} with forceRemote: ${forceRemote} from remote error: ${error}`
      );
    }
    await PromiseUtil.wait(5000);
    retry -= 1;
    if (retry !== 0) {
      logger.warn(`Start retry load publications retry:${retry}`);
      return getPublicationsData({
        publicationsTypes,
        errorHandler,
        forceRemote,
        isSuggestionsEnabled,
        retry
      });
    }
    logger.warn(`Used all retry and pass empty repose`);
    const message = 'LibraryStore.RemoteBooks.notFound';
    errorHandler(error, message);
    return {};
  }
}

async function getRemoteDataByTypes(
  publicationsTypes,
  forceRemote,
  isSuggestionsEnabled,
  isGuestUser,
  isLoggingOut
) {
  const requestTypes = _getRequestNames(publicationsTypes);

  const promises = requestTypes.map(requestType =>
    _getPublicationsDataByType(
      requestType,
      forceRemote,
      isSuggestionsEnabled,
      isGuestUser,
      isLoggingOut
    )
  );
  const responses = await Promise.all(promises);
  return mergeByLang(responses);
}

function mergeByLang(dataSets) {
  const mergedSet = {
    relatedStudyGuides: {},
    publicationsData: [],
    suggestedBooks: []
  };
  for (const dataSet of dataSets) {
    const publicationsData = dataSet.publicationsData;
    Object.assign(mergedSet.relatedStudyGuides, dataSet.relatedStudyGuides);
    for (const lang in publicationsData) {
      if (publicationsData.hasOwnProperty(lang)) {
        const publications = publicationsData[lang];
        if (!mergedSet.publicationsData.hasOwnProperty(lang)) {
          mergedSet.publicationsData[lang] = [];
        }
        [].push.apply(mergedSet.publicationsData[lang], publications);
      }
    }
    const suggestedBooksData = dataSet.suggestedBooks;
    for (const lang in suggestedBooksData) {
      const suggestedBooks = suggestedBooksData[lang];
      if (!mergedSet.suggestedBooks.hasOwnProperty(lang)) {
        mergedSet.suggestedBooks[lang] = [];
      }
      [].push.apply(mergedSet.suggestedBooks[lang], suggestedBooks);
    }
  }
  return mergedSet;
}

function _getRequestNames(publicationsTypes) {
  const requestTypes = {};
  publicationsTypes.forEach(publicationsType => {
    switch (publicationsType) {
      case PublicationsTypes.BOOK:
      case PublicationsTypes.COLLECTION:
        requestTypes[requestEnum.BOOK_COLLECTION] = true;
        break;
      case PublicationsTypes.STUDY_GUIDE:
      case PublicationsTypes.SYLLABUS:
        requestTypes[requestEnum.STUDY_GUIDE_SYLLABUS] = true;
        break;
      case PublicationsTypes.LIBRARY_SET:
        requestTypes[requestEnum.LIBRARY_SET] = true;
        break;
      case PublicationsTypes.STUDY_COURSE:
        requestTypes[requestEnum.STUDY_COURSE] = true;
        break;
      case PublicationsTypes.COMPILATION:
        requestTypes[requestEnum.COMPILATION] = true;
        break;
      case PublicationsTypes.SUGGESTED_BOOK:
        requestTypes[requestEnum.SUGGESTED_BOOKS] = true;
        break;
      default:
        break;
    }
  });
  return Object.keys(requestTypes);
}

async function _getPublicationsDataByType(
  requestType,
  forceRemote,
  isSuggestionsEnabled,
  isGuestUser,
  isLoggingOut
) {
  let relatedStudyGuides = [];
  let publicationsData = [];
  let suggestedBooks = [];
  switch (requestType) {
    case requestEnum.BOOK_COLLECTION: {
      const remoteData = await getBooksDataRemote(forceRemote);
      relatedStudyGuides = remoteData.studyGuidesView;
      publicationsData = remoteData.libraryView;
      break;
    }
    case requestEnum.STUDY_GUIDE_SYLLABUS: {
      publicationsData = await getStudyMaterialsRemote();
      break;
    }
    case requestEnum.LIBRARY_SET: {
      publicationsData = await getLibrarySetRemote(forceRemote);
      break;
    }
    case requestEnum.STUDY_COURSE: {
      publicationsData = await getAllStudyClasses();
      break;
    }
    case requestEnum.COMPILATION: {
      publicationsData =
        isGuestUser || isLoggingOut
          ? await getAllDefaultCompilations()
          : await getAllCompilations();
      break;
    }
    case requestEnum.SUGGESTED_BOOKS: {
      suggestedBooks = await getAllSuggestedBooks(
        isSuggestionsEnabled,
        forceRemote
      );
      break;
    }
    default:
      break;
  }
  return {
    relatedStudyGuides,
    publicationsData,
    suggestedBooks
  };
}

async function getAllCompilations() {
  try {
    const response = await RequestService.request(
      'get',
      'Compilations',
      'getAllCompilations'
    );
    const data = response.data || [];

    const commonCompilations = data.map(comp => {
      comp.id = comp._id;
      comp.type = PublicationsTypes.COMPILATION;
      return comp;
    });
    return { common: commonCompilations };
  } catch (error) {
    logger.error(`Get remote common compilations failed with error: ${error}`);
    return { common: [] };
  }
}

async function getAllDefaultCompilations() {
  try {
    const response = await RestService.restRequest(
      'get',
      'Compilations',
      'retrieveDefaultCompilations'
    );
    const defaultCompilations = response.data || [];
    return { common: defaultCompilations };
  } catch (error) {
    logger.error(`Get remote default compilations failed with error: ${error}`);
    return { common: [] };
  }
}

async function getAllSuggestedBooks(isSuggestionsEnabled, forceRemote) {
  try {
    if (!isSuggestionsEnabled) {
      return Promise.resolve([]);
    }
    let localData = null;
    if (!forceRemote) {
      localData = await _getLocalData(LIBRARY_TABLE, SUGGESTED_BOOK_ID);
    }
    let response = {
      id: '',
      data: {}
    };

    const needRemoteData = !localData || forceRemote;
    if (needRemoteData) {
      response = await RestService.restRequest(
        'get',
        'Publications',
        'getSuggestions'
      );
      response.data.id = SUGGESTED_BOOK_ID;
      await _setLocalData(LIBRARY_TABLE, response.data);
    } else if (localData) {
      response.data = localData;
    }
    delete response.data?.id;
    return response.data;
  } catch (error) {
    logger.warn(`read book from remote failed with error: ${error}`);
    return [];
  }
}

function getAllStudyClasses() {
  return [];
}

async function getBooksDataRemote(forceRemote) {
  const fileName = LIBRARY_JSON;
  const isEditorRequest = loaderContext.isEditor;
  const data = {
    fileName,
    isEditor: isEditorRequest
  };
  let resp = {
    data: {
      id: '',
      studyGuidesView: {},
      libraryView: {}
    }
  };

  // TODO @yareg forceRemote doesn't work as expected, need rework it, if forceRemote === true we should ignore cache and get data strait fro the server
  let needRemoteData = process.server || forceRemote || isEditorRequest;
  let actualLibraryHash, savedLibraryHash;
  if (needRemoteData && !isEditorRequest) {
    ({ actualLibraryHash, savedLibraryHash } = await _getLibraryDataHash());
    if (actualLibraryHash && actualLibraryHash === savedLibraryHash) {
      needRemoteData = false;
    }
  }

  let localData = null;
  if (!needRemoteData) {
    localData = await _getLocalData(LIBRARY_TABLE, LIBRARY_JSON_ID);
  }
  if (!localData) {
    needRemoteData = true;
  }

  if (needRemoteData) {
    const localStoredata = {
      id: LIBRARY_HASH_ID,
      value: actualLibraryHash
    };
    await _setLocalData(LIBRARY_TABLE, localStoredata);

    let isValid;
    ({ resp, isValid } = await _getLibraryData(data));
    if (isValid) {
      await _setLocalData(LIBRARY_TABLE, resp.data);
    }
  } else {
    resp.data = localData;
  }

  for (const lang in resp.data.libraryView) {
    resp.data.libraryView[lang].map(pub => _setDefaultAssetsSource(pub));
  }

  return resp.data;
}

async function _getLibraryDataHash() {
  try {
    let [actualLibraryHash, savedLibraryHash] = await Promise.all([
      RestService.restRequest('get', 'Publications', 'libraryViewHash'),
      _getLocalData(LIBRARY_TABLE, LIBRARY_HASH_ID)
    ]);
    actualLibraryHash = actualLibraryHash?.data?.hash;
    savedLibraryHash = savedLibraryHash?.value;

    return { actualLibraryHash, savedLibraryHash };
  } catch (error) {
    _processRequestError(error);
  }
}

async function _getLibraryData(data, retryNum = 5) {
  try {
    const resp = await RestService.restRequest(
      'get',
      'Publications',
      'studyGuidesView',
      data
    );
    const isValid = BuildValidateService.checkLibraryView(resp, retryNum);
    if (!isValid && retryNum > 0) {
      await PromiseUtil.wait(5000);
      retryNum -= 1;
      logger.warn(
        `call retry in case invalid library view retryNum: ${retryNum}`
      );
      return _getLibraryData(data, retryNum);
    }
    resp.data.id = LIBRARY_JSON_ID;

    return { resp, isValid };
  } catch (error) {
    if (retryNum > 0) {
      await PromiseUtil.wait(5000);
      retryNum -= 1;
      logger.warn(
        `call retry in error case to load library view retryNum: ${retryNum} error: ${error}`
      );
      return _getLibraryData(data, retryNum);
    }
    _processRequestError(error);
  }
}

function _processRequestError(error) {
  if (!error || error.message !== 'Network Error') {
    logger.error(`read library set from remote failed with error: ${error}`);
  }
  throw error;
}

async function getLibrarySetRemote(forceRemote) {
  let response = {
    data: []
  };
  let localData = null;
  if (!forceRemote) {
    localData = await _getLocalData(LIBRARY_TABLE, LIBRARY_SET_ID);
  }
  const needRemoteData = !localData || forceRemote;
  if (needRemoteData) {
    try {
      const resp = await RestService.restRequest(
        'get',
        'LibrarySet',
        'librarySetSearch',
        {
          reqParams: {
            mode: loaderContext.mode
          }
        }
      );

      if (resp && Array.isArray(resp?.data)) {
        response = resp;
      } else if (
        get(resp, `data.statusMessages[0].text.key`) ===
        'server.auth.userNotFound.error'
      ) {
        logger.warn(`Wrong runId: ${JSON.stringify(resp || '', null, 2)}`);
      } else {
        logger.error(
          `get invalid library set response from remote : ${JSON.stringify(
            resp || '',
            null,
            2
          )}`
        );
      }
      response.data.id = LIBRARY_SET_ID;
      await _setLocalData(LIBRARY_TABLE, response.data);
    } catch (error) {
      if (!error || error.message !== 'Network Error') {
        logger.error(
          `read library set from remote failed with error: ${error}`
        );
      }
      if (needRemoteData) {
        throw error;
      }
      response.data = localData;
    }
  } else {
    response.data = localData;
  }
  const librarySetStructure = {};
  response.data.forEach(librarySet => {
    const language = librarySet.language || 'common';
    librarySet = _setDefaultAssetsSource(librarySet);
    librarySetStructure[language] = librarySetStructure[language] || [];
    librarySetStructure[language].push(librarySet);
  });
  return librarySetStructure;
}

function getStudyMaterialsRemote() {
  const requestData = {
    filter: '',
    itemsCount: 0,
    language: '',
    contentType: '',
    categories: '',
    personalPublications: false,
    isEditor: loaderContext.isEditor
  };
  return RestService.restRequest(
    'get',
    'Publications',
    'studyMaterials',
    requestData
  ).then(resp => {
    if (!resp.data.rows) {
      return {};
    }
    let studyMaterials = {};

    resp.data.rows.forEach(studyMaterial => {
      if (
        !studyMaterial.type ||
        (studyMaterial.type !== PublicationsTypes.STUDY_GUIDE &&
          studyMaterial.type !== PublicationsTypes.SYLLABUS)
      ) {
        return;
      }
      const language = studyMaterial.language || 'common';
      studyMaterial = _setDefaultAssetsSource(studyMaterial);
      studyMaterials[language] = studyMaterials[language] || [];
      studyMaterials[language].push(studyMaterial);
    });

    return studyMaterials;
  });
}

/**
 *
 * @param {Object} publication
 * @returns {Object}
 */
function _setDefaultAssetsSource(
  publication,
  defaultSource = AssetResourcesEnum.REMOTE
) {
  if (publication.type === PublicationsTypes.COLLECTION) {
    publication.items.map(item => (item.assetsSource = defaultSource));
  }
  publication.assetsSource = defaultSource;

  return publication;
}

async function _getLocalData(tableName, id) {
  const _dbWrapper = await getDbWrapper();
  return _dbWrapper.getById(id, tableName);
}

async function _setLocalData(tableName, data) {
  const _dbWrapper = await getDbWrapper();
  return _dbWrapper.savePublication(tableName, data);
}

async function clearCacheData() {
  try {
    await _removeCachedSuggesedBooks();
  } catch (error) {
    logger.error(
      `Get error om remove user cached data from indexedDb error: ${error}`
    );
  }
}

async function _removeCachedSuggesedBooks() {
  const _dbWrapper = await getDbWrapper();
  return _dbWrapper.removeById(LIBRARY_TABLE, SUGGESTED_BOOK_ID);
}

export default {
  init,
  getPublicationsData,
  clearCacheData,
  loadPublicationCategoryInfo
};
