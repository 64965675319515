<template>
  <div v-if="bookmarks.length">
    <div
      v-for="bookmark in bookmarks"
      :key="bookmark.id"
      class="bookmark-wrapper"
      :class="{
        active:
          bookmark.id === popupContext.activeId || bookmark.id === getBookmarkId
      }"
      @click="goToBookmark(bookmark)"
    >
      <div class="bookmark-content">
        <div class="bookmark-para">{{ bookmark.paraNum || '#' }}</div>
        <div class="bookmark-text">
          {{ bookmark.highlightedQuote || '' }}
        </div>
      </div>
      <div class="icon-block" @click.stop="deleteBookmark(bookmark)">
        <BaseSpriteIcon icon-name="ico-delete" />
      </div>
    </div>
  </div>
  <NoDataItem
    v-else
    :content-image="noDataContentImage"
    :content-title="$t('BookmarkPopup.empty.header')"
    :content-message="$t('BookmarkPopup.empty.content')"
  ></NoDataItem>
</template>

<script>
import BaseSpriteIcon from '@/components/base/BaseSpriteIcon/BaseSpriteIcon';
import NoDataItem from './../NoDataItem/NoDataItem';

import PopupNamesEnum from '@/enums/PopupNamesEnum';
import PopupEventEnum from '@/enums/PopupEventEnum';
import PublicationNavigateLogicService from '@/services/PublicationLogic/PublicationNavigateLogicService';
import SelectionConstantUtils from '@shared/publication/selection/SelectionConstantUtils.mjs';
import Locator from '@shared/publication/locator.mjs';
import images from '@/assets/images';

import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('BookmarksListTab.vue');

export default {
  name: 'BookmarksTab',
  components: { BaseSpriteIcon, NoDataItem },
  props: {
    popupContext: Object
  },
  data() {
    return {
      noDataContentImage: images.picBookmark
    };
  },
  computed: {
    openParaId() {
      return this.$store.getters['OpenParameterStore/getParaId'];
    },
    bookId() {
      const openParams = this.$store.getters[
        'OpenParameterStore/getPublicationOpenParameters'
      ];
      return openParams?.publicationId;
    },
    bookmarks() {
      const bookmarks = this.$store.getters['BookmarkStore/getBookBookmarks']({
        bookId: this.bookId
      });
      bookmarks.sort(
        (a, b) =>
          +a.locator.startLocator._paragraphNumber -
          +b.locator.startLocator._paragraphNumber
      );
      return bookmarks;
    },
    isBookmarkOpen() {
      return this.$store.getters['ManagePopupStore/isPopupOpened'](
        PopupNamesEnum.BOOKMARK_POPUP
      );
    },
    getBookmarkId() {
      return this.$store.getters['BookmarkStore/getBookmarkId'];
    },
    isWideScreen() {
      return this.$store.getters['MediaDetectorStore/mediaSize'].wide;
    }
  },
  watch: {
    bookmarks() {
      this.setBookmarksHighlightedQuote();
    }
  },
  mounted() {
    this.setBookmarksHighlightedQuote();
  },
  methods: {
    async setBookmarksHighlightedQuote() {
      const isEveryHighlighted = this.bookmarks.every(
        mark => mark.highlightedQuote
      );
      if (!isEveryHighlighted) {
        await this.$store.dispatch('BookmarkStore/addHighlightToBookmarks', {
          bookId: this.bookId
        });
      }
    },
    goToBookmark(bookmark) {
      const locator = bookmark.locator.startLocator;
      const isInsideReadingArea = this.$store.getters[
        'ProgressStore/isInsideReadingArea'
      ](locator);
      if (!isInsideReadingArea) {
        this.$_changePublication(bookmark.locator);
      }

      this.$store.dispatch('BookmarkStore/setBookmarkId', bookmark.id);

      if (!this.isWideScreen || this.isBookmarkOpen) {
        this.closeBookmarkPopup();
      }
    },
    $_changePublication({ startLocator, endLocator }) {
      if (
        !(startLocator instanceof Locator.InTextLocator) ||
        !(endLocator instanceof Locator.InTextLocator)
      ) {
        return;
      }

      try {
        const openLocator = new Locator.InTextRangeLocator(
          startLocator,
          endLocator
        ).toJSON();
        const openSamePara = this.openParaId === openLocator;
        PublicationNavigateLogicService.openPublication(
          this.$store,
          this.$router,
          {
            paragraphId: openLocator,
            openSamePara,
            openOffset: this.windowHalfHight()
          }
        );
      } catch (err) {
        logger.error(
          `Get error while navigate in book while view bookmark (${this.bookId}); error: ${err}`
        );
      }
    },
    windowHalfHight() {
      const toolbarHeight = SelectionConstantUtils.TOOLBAR_HEIGHT;
      const progressToolbarHeight =
        SelectionConstantUtils.PROGRESS_TOOLBAR_HEIGHT;
      const windowHalfHight =
        window.innerHeight / 2 - toolbarHeight - progressToolbarHeight;
      return -Math.round(windowHalfHight);
    },
    deleteBookmark(bookmark) {
      this.$store.dispatch('BookmarkStore/deleteBookmark', {
        bookmark
      });
    },
    closeBookmarkPopup() {
      this.$emit('closeHandler', {
        type: PopupEventEnum.CLOSE
      });
    }
  }
};
</script>

<style lang="less">
.bookmark-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 16px;
  font-weight: 500;
  border-bottom: 1px solid #e2e8f0;
  cursor: pointer;

  .sepia-theme-template & {
    border-bottom: 1px solid #0f172a14;
  }

  .night-theme-template & {
    border-bottom: 1px solid #ffffff14;
  }

  &:not(.active):hover {
    background: #f1f5f9;
    color: var(--black-color);

    .night-theme-template & {
      background: #ffffff14;
      color: var(--white-color);
    }
  }

  &.active {
    background-color: var(--primary-color);
    color: var(--white-color);

    .icon-block svg {
      fill: var(--white-color);
    }
  }

  .bookmark {
    &-content {
      display: flex;
      gap: 20px;
    }

    &-para {
      color: #94a3b8;
      width: 64px;
      flex-shrink: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-text {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .icon-block {
    display: flex;
    align-items: center;
    height: 22px;
    cursor: pointer;

    svg {
      flex-shrink: 0;
      fill: currentColor;
    }
  }
}
</style>
