const regex = /\d+(\.\d+)?/g;
const popupFontSettings = {
  minChangingPercentage: 100,
  maxChangingPercentage: 150,
  maxFontSizePercentage: 130,
  fontSizePercentageStep: 20
};

function getTextFontSize(store) {
  const fontStyles =
    store.getters['ReadingSettingsStore/getWrappingReadingSettingsStyles'];
  if (!fontStyles || !fontStyles.length || !fontStyles[0]['font-size']) {
    return null;
  }
  const match = fontStyles[0]['font-size'].match(regex);
  return match ? parseFloat(match[0]) : null;
}

function getBookFontSizePercentage(store) {
  return store.getters['ReadingSettingsStore/getFontSize'];
}

function getContentFontSizePercentage(bookFontSizePercentage) {
  if (bookFontSizePercentage < popupFontSettings.minChangingPercentage) {
    return null;
  }
  if (bookFontSizePercentage > popupFontSettings.maxChangingPercentage) {
    return popupFontSettings.maxFontSizePercentage;
  }
  return bookFontSizePercentage - popupFontSettings.fontSizePercentageStep;
}

export function getContentFontSize(store) {
  const textSize = getTextFontSize(store);
  const bookFontSizePercentage = getBookFontSizePercentage(store);
  const dictPercentage = getContentFontSizePercentage(bookFontSizePercentage);
  if (!textSize || !dictPercentage) {
    return null;
  }
  const calculatedFontSize = textSize * (dictPercentage / 100);
  return `${Math.max(calculatedFontSize, 0)}px`;
}
