import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _08147934 = () => interopDefault(import('../src/pages/library.vue' /* webpackChunkName: "" */))
const _720c51f8 = () => interopDefault(import('../src/pages/about.vue' /* webpackChunkName: "pages/about" */))
const _53ed1fd8 = () => interopDefault(import('../src/pages/account.vue' /* webpackChunkName: "pages/account" */))
const _0676bfa4 = () => interopDefault(import('../src/pages/affiliates/index.vue' /* webpackChunkName: "pages/affiliates/index" */))
const _c1e3ac32 = () => interopDefault(import('../src/pages/assessment.vue' /* webpackChunkName: "pages/assessment" */))
const _5771b17c = () => interopDefault(import('../src/pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _618c9fa0 = () => interopDefault(import('../src/pages/delete-account.vue' /* webpackChunkName: "pages/delete-account" */))
const _151d7e77 = () => interopDefault(import('../src/pages/extracts.vue' /* webpackChunkName: "pages/extracts" */))
const _065c45c1 = () => interopDefault(import('../src/pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _149c0fb6 = () => interopDefault(import('../src/pages/forParents.vue' /* webpackChunkName: "pages/forParents" */))
const _4e43a7ac = () => interopDefault(import('../src/pages/forStudents.vue' /* webpackChunkName: "pages/forStudents" */))
const _4aaf4e65 = () => interopDefault(import('../src/pages/forTeachers.vue' /* webpackChunkName: "pages/forTeachers" */))
const _a7a53352 = () => interopDefault(import('../src/pages/google-analytics.vue' /* webpackChunkName: "pages/google-analytics" */))
const _79500c1b = () => interopDefault(import('../src/pages/helpCenter.vue' /* webpackChunkName: "pages/helpCenter" */))
const _5b6bcfd4 = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _2a1a8f8e = () => interopDefault(import('../src/pages/manageAuthors.vue' /* webpackChunkName: "pages/manageAuthors" */))
const _17d86bf1 = () => interopDefault(import('../src/pages/offline-fallback.vue' /* webpackChunkName: "pages/offline-fallback" */))
const _094bd4c0 = () => interopDefault(import('../src/pages/onboarding.vue' /* webpackChunkName: "pages/onboarding" */))
const _7d2bc0f1 = () => interopDefault(import('../src/pages/pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _dfaa6928 = () => interopDefault(import('../src/pages/savedForOffline.vue' /* webpackChunkName: "pages/savedForOffline" */))
const _5280444d = () => interopDefault(import('../src/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _4591d0fc = () => interopDefault(import('../src/pages/state.vue' /* webpackChunkName: "pages/state" */))
const _765dd485 = () => interopDefault(import('../src/pages/subscription/index.vue' /* webpackChunkName: "pages/subscription/index" */))
const _16c5122e = () => interopDefault(import('../src/pages/thank_you_for_registration.vue' /* webpackChunkName: "pages/thank_you_for_registration" */))
const _4fad123b = () => interopDefault(import('../src/pages/verify_email.vue' /* webpackChunkName: "pages/verify_email" */))
const _71d9daab = () => interopDefault(import('../src/pages/category/favorites.vue' /* webpackChunkName: "pages/category/favorites" */))
const _2d161f24 = () => interopDefault(import('../src/pages/dashboard/reading-group.vue' /* webpackChunkName: "pages/dashboard/reading-group" */))
const _ce203ad4 = () => interopDefault(import('../src/pages/subscription/cards.vue' /* webpackChunkName: "pages/subscription/cards" */))
const _5a16c487 = () => interopDefault(import('../src/pages/subscription/history.vue' /* webpackChunkName: "pages/subscription/history" */))
const _a4865d46 = () => interopDefault(import('../src/pages/affiliates/_affiliateId.vue' /* webpackChunkName: "pages/affiliates/_affiliateId" */))
const _34467ace = () => interopDefault(import('../src/pages/author/_authorSlug/index.vue' /* webpackChunkName: "pages/author/_authorSlug/index" */))
const _3959e03e = () => interopDefault(import('../src/pages/category/_name.vue' /* webpackChunkName: "pages/category/_name" */))
const _c8d7f024 = () => interopDefault(import('../src/pages/collection/_id.vue' /* webpackChunkName: "pages/collection/_id" */))
const _144a758e = () => interopDefault(import('../src/pages/developlibraryset/_setId.vue' /* webpackChunkName: "pages/developlibraryset/_setId" */))
const _6a51223c = () => interopDefault(import('../src/pages/developstudycourse/_syllabusId.vue' /* webpackChunkName: "pages/developstudycourse/_syllabusId" */))
const _ecc630ee = () => interopDefault(import('../src/pages/genre/_name.vue' /* webpackChunkName: "pages/genre/_name" */))
const _87599a2a = () => interopDefault(import('../src/pages/manageauthor/_authorId.vue' /* webpackChunkName: "pages/manageauthor/_authorId" */))
const _120a4b8f = () => interopDefault(import('../src/pages/author/_authorSlug/bio.vue' /* webpackChunkName: "pages/author/_authorSlug/bio" */))
const _4fcebac6 = () => interopDefault(import('../src/pages/author/_authorSlug/faq.vue' /* webpackChunkName: "pages/author/_authorSlug/faq" */))
const _ae7adfd2 = () => interopDefault(import('../src/components/pages/LoginPage/LoginPage.vue' /* webpackChunkName: "" */))
const _dfe4e916 = () => interopDefault(import('../src/pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/bloggerAuth",
    redirect: "about",
    name: "bloggerAuth"
  }, {
    path: "/library_(\\w{2})",
    component: _08147934,
    name: "library_language"
  }, {
    path: "/about/",
    component: _720c51f8,
    pathToRegexpOptions: {"strict":true},
    name: "about"
  }, {
    path: "/account/",
    component: _53ed1fd8,
    pathToRegexpOptions: {"strict":true},
    name: "account"
  }, {
    path: "/affiliates/",
    component: _0676bfa4,
    pathToRegexpOptions: {"strict":true},
    name: "affiliates"
  }, {
    path: "/assessment/",
    component: _c1e3ac32,
    pathToRegexpOptions: {"strict":true},
    name: "assessment"
  }, {
    path: "/dashboard/",
    component: _5771b17c,
    pathToRegexpOptions: {"strict":true},
    name: "dashboard"
  }, {
    path: "/delete-account/",
    component: _618c9fa0,
    pathToRegexpOptions: {"strict":true},
    name: "delete-account"
  }, {
    path: "/extracts/",
    component: _151d7e77,
    pathToRegexpOptions: {"strict":true},
    name: "extracts"
  }, {
    path: "/faq/",
    component: _065c45c1,
    pathToRegexpOptions: {"strict":true},
    name: "faq"
  }, {
    path: "/forParents/",
    component: _149c0fb6,
    pathToRegexpOptions: {"strict":true},
    name: "forParents"
  }, {
    path: "/forStudents/",
    component: _4e43a7ac,
    pathToRegexpOptions: {"strict":true},
    name: "forStudents"
  }, {
    path: "/forTeachers/",
    component: _4aaf4e65,
    pathToRegexpOptions: {"strict":true},
    name: "forTeachers"
  }, {
    path: "/google-analytics/",
    component: _a7a53352,
    pathToRegexpOptions: {"strict":true},
    name: "google-analytics"
  }, {
    path: "/helpCenter/",
    component: _79500c1b,
    pathToRegexpOptions: {"strict":true},
    name: "helpCenter"
  }, {
    path: "/library/",
    component: _08147934,
    pathToRegexpOptions: {"strict":true},
    name: "library"
  }, {
    path: "/login/",
    component: _5b6bcfd4,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/manageAuthors/",
    component: _2a1a8f8e,
    pathToRegexpOptions: {"strict":true},
    name: "manageAuthors"
  }, {
    path: "/offline-fallback/",
    component: _17d86bf1,
    pathToRegexpOptions: {"strict":true},
    name: "offline-fallback"
  }, {
    path: "/onboarding/",
    component: _094bd4c0,
    pathToRegexpOptions: {"strict":true},
    name: "onboarding"
  }, {
    path: "/pricing/",
    component: _7d2bc0f1,
    pathToRegexpOptions: {"strict":true},
    name: "pricing"
  }, {
    path: "/savedForOffline/",
    component: _dfaa6928,
    pathToRegexpOptions: {"strict":true},
    name: "savedForOffline"
  }, {
    path: "/search/",
    component: _5280444d,
    pathToRegexpOptions: {"strict":true},
    name: "search"
  }, {
    path: "/state/",
    component: _4591d0fc,
    pathToRegexpOptions: {"strict":true},
    name: "state"
  }, {
    path: "/subscription/",
    component: _765dd485,
    pathToRegexpOptions: {"strict":true},
    name: "subscription"
  }, {
    path: "/thank_you_for_registration/",
    component: _16c5122e,
    pathToRegexpOptions: {"strict":true},
    name: "thank_you_for_registration"
  }, {
    path: "/verify_email/",
    component: _4fad123b,
    pathToRegexpOptions: {"strict":true},
    name: "verify_email"
  }, {
    path: "/category/favorites/",
    component: _71d9daab,
    pathToRegexpOptions: {"strict":true},
    name: "category-favorites"
  }, {
    path: "/dashboard/reading-group/",
    component: _2d161f24,
    pathToRegexpOptions: {"strict":true},
    name: "dashboard-reading-group"
  }, {
    path: "/subscription/cards/",
    component: _ce203ad4,
    pathToRegexpOptions: {"strict":true},
    name: "subscription-cards"
  }, {
    path: "/subscription/history/",
    component: _5a16c487,
    pathToRegexpOptions: {"strict":true},
    name: "subscription-history"
  }, {
    path: "/",
    component: _08147934,
    name: "library"
  }, {
    path: "/affiliates/:affiliateId/",
    component: _a4865d46,
    pathToRegexpOptions: {"strict":true},
    name: "affiliates-affiliateId"
  }, {
    path: "/author/:authorSlug/",
    component: _34467ace,
    pathToRegexpOptions: {"strict":true},
    name: "author-authorSlug"
  }, {
    path: "/category/:name?/",
    component: _3959e03e,
    pathToRegexpOptions: {"strict":true},
    name: "category-name"
  }, {
    path: "/collection/:id?/",
    component: _c8d7f024,
    pathToRegexpOptions: {"strict":true},
    name: "collection-id"
  }, {
    path: "/developlibraryset/:setId?/",
    component: _144a758e,
    pathToRegexpOptions: {"strict":true},
    name: "developlibraryset-setId"
  }, {
    path: "/developstudycourse/:syllabusId?/",
    component: _6a51223c,
    pathToRegexpOptions: {"strict":true},
    name: "developstudycourse-syllabusId"
  }, {
    path: "/genre/:name?/",
    component: _ecc630ee,
    pathToRegexpOptions: {"strict":true},
    name: "genre-name"
  }, {
    path: "/manageauthor/:authorId?/",
    component: _87599a2a,
    pathToRegexpOptions: {"strict":true},
    name: "manageauthor-authorId"
  }, {
    path: "/author/:authorSlug?/bio/",
    component: _120a4b8f,
    pathToRegexpOptions: {"strict":true},
    name: "author-authorSlug-bio"
  }, {
    path: "/author/:authorSlug?/faq/",
    component: _4fcebac6,
    pathToRegexpOptions: {"strict":true},
    name: "author-authorSlug-faq"
  }, {
    path: "/access_token*",
    component: _ae7adfd2,
    name: "access_token"
  }, {
    path: "/state*",
    component: _ae7adfd2
  }, {
    path: "/:slug?/",
    component: _dfe4e916,
    pathToRegexpOptions: {"strict":true},
    name: "slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
