import RestService from '@/services/RestService';

async function searchAuthors(params) {
  const response = await RestService.restRequest(
    'get',
    'Authors',
    'searchWithFilters',
    params
  );
  const data = response.data || {};
  return data?.authors ? data : { totalAuthors: 0, authors: [] };
}

async function uploadAuthorImage(params) {
  const response = await RestService.restRequest(
    'post',
    'Authors',
    'uploadAuthorImage',
    params
  );
  return response.data;
}

function deleteAuthorImage(authorId) {
  return RestService.restRequest('delete', 'Authors', 'deleteAuthorImage', {
    authorId
  });
}

function getAuthorPhoto(fileId) {
  const url = RestService.getUrlString('Authors', 'getFile').split(
    '?RunId='
  )[0];
  const authorUrl = new URL(url);
  authorUrl.searchParams.set('fileId', fileId);
  return authorUrl.href;
}

function persistAuthorProfile(authorData) {
  return RestService.restRequest('post', 'Authors', 'saveAuthor', authorData);
}

async function getAuthorProfileById(authorId) {
  const response = await RestService.restRequest('get', 'Authors', 'profile', {
    authorId: authorId
  });
  return response?.data || {};
}

async function getAuthorProfileBySlug(slug) {
  const response = await RestService.restRequest(
    'get',
    'Authors',
    'profileBySlug',
    {
      slug: slug
    }
  );
  return response?.data || {};
}

async function clearAuthorProfile(authorId) {
  const response = await RestService.restRequest(
    'post',
    'Authors',
    'clearAuthor',
    {
      authorId: authorId
    }
  );
  return response?.data || {};
}

export default {
  getAuthorPhoto,
  getAuthorProfileById,
  getAuthorProfileBySlug,
  persistAuthorProfile,
  clearAuthorProfile,
  searchAuthors,
  uploadAuthorImage,
  deleteAuthorImage
};
