class PublicationsSearcher {
  constructor(searchParams) {
    this.state = searchParams.state || '';
    this.lang = searchParams.currentLang || '';
    this.term = searchParams.searchTerm || '';
    this.authorSlug = searchParams.authorSlug || '';
    this.category = searchParams.category || '';
    this.stateFilter = searchParams.publicationsStateFilter || '';
    this.allowBookList = searchParams.allowBookList || [];
    this.categories = searchParams.categories || null;
    this.excludedCategories = searchParams.excludedCategories || null;
    this.durations = searchParams.durations || null;
    this.difficultyRange = searchParams.difficultyRange || null;
    this.collectionId = searchParams.collectionId || '';
    this.genres = searchParams.genres || [];
    this.isCollectionIncluded = searchParams.isCollectionIncluded ?? false;
    this.isBooksIncluded = searchParams.isBooksIncluded ?? true;
    this.audio = searchParams.hasOwnProperty('audio')
      ? searchParams.audio
      : false;
    this.withoutAudio = searchParams.hasOwnProperty('audio')
      ? searchParams.withoutAudio
      : false;
    this.offline = searchParams.hasOwnProperty('offline')
      ? searchParams.offline
      : false;
  }

  toString() {
    return (
      Object.keys(this).reduce((str, key) => {
        const val = this[key];
        if (!isFunction(val)) {
          str += key + ': "' + val + '", ';
        }
        return str;
      }, '{') + '}'
    );
  }
}

function isFunction(fn) {
  return {}.toString.call(fn) === '[object Function]';
}

class PublicationsSearcherBuilder {
  setState(state) {
    this.state = state;
    return this;
  }

  setLanguage(language) {
    this.currentLang = language;
    return this;
  }

  setAuthorSlug(authorSlug) {
    this.authorSlug = authorSlug;
    return this;
  }

  setFilter(filterValue) {
    this.searchTerm = filterValue;
    return this;
  }

  setAllowBookList(allowBookList) {
    this.allowBookList = allowBookList;
    return this;
  }

  setCategory(val) {
    this.category = val;
    return this;
  }

  setCategories(val) {
    this.categories = val;
    return this;
  }

  setExcludedCategories(val) {
    this.excludedCategories = val;
    return this;
  }

  setAudio(val) {
    this.audio = val;
    return this;
  }

  setWithoutAudio(val) {
    this.withoutAudio = val;
    return this;
  }

  setOffline(val) {
    this.offline = val;
    return this;
  }

  setDifficultyRange(val) {
    this.difficultyRange = val;
    return this;
  }

  setDurations(val) {
    this.durations = val;
    return this;
  }

  setPublicationsStateFilter(publicationsState) {
    this.publicationsStateFilter = publicationsState;
    return this;
  }

  setCollectionId(val) {
    this.collectionId = val;
    return this;
  }

  setCollectionIncluded(val) {
    this.isCollectionIncluded = val;
    return this;
  }

  setBooksIncluded(val) {
    this.isBooksIncluded = val;
    return this;
  }

  setGenres(val) {
    this.genres = val;
    return this;
  }

  build() {
    return new PublicationsSearcher(this);
  }
}

function getSearcherBuilder() {
  return new PublicationsSearcherBuilder();
}

function searcherToBuilder(searcher) {
  const newSearcher = new PublicationsSearcherBuilder();
  newSearcher
    .setState(searcher?.state)
    .setLanguage(searcher?.lang)
    .setAuthorSlug(searcher?.authorSlug)
    .setFilter(searcher?.term)
    .setCategory(searcher?.category)
    .setCategories(_copyArray(searcher?.categories))
    .setDurations(_copyArray(searcher?.durations))
    .setAudio(searcher?.audio)
    .setWithoutAudio(searcher?.withoutAudio)
    .setOffline(searcher?.offline)
    .setDifficultyRange(_copyArray(searcher?.difficultyRange))
    .setPublicationsStateFilter(searcher?.stateFilter)
    .setCollectionId(searcher?.collectionId)
    .setCollectionIncluded(searcher?.isCollectionIncluded)
    .setBooksIncluded(searcher?.isBooksIncluded)
    .setGenres(searcher?.genres)
    .setAllowBookList(_copyArray(searcher?.allowBookList))
    .setExcludedCategories(_copyArray(searcher?.excludedCategories));
  return newSearcher;
}

function _copyArray(arr) {
  return Array.isArray(arr) ? [...arr] : arr;
}

export default {
  getSearcherBuilder,
  searcherToBuilder
};
