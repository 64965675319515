import { localize as $t } from '@/i18n';
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import CongratulationTypesEnum from '@/enums/CongratulationTypesEnum';
import UnsupportedBrowserPopupService from '@/services/UnsupportedBrowserPopupService';

// initial state
const initState = () => ({
  openedPopups: [],
  isLoading: false
});

function getPopupDataIndex(state, checkName) {
  return state.openedPopups.findIndex(popup => popup.name === checkName);
}

function getPopupData(state, popupName) {
  const popupDataIndex = getPopupDataIndex(state, popupName);
  if (popupDataIndex === -1) {
    return null;
  }
  return state.openedPopups[popupDataIndex];
}

// getters
const storeGetters = {
  getOpenedPopups(state) {
    return state.openedPopups;
  },
  isLoading(state) {
    return state.isLoading;
  },
  isPopupOpened: state => popupName => {
    if (!popupName) {
      return state.openedPopups.length > 0;
    }
    const openedPopupData = getPopupData(state, popupName);
    return Boolean(openedPopupData);
  },
  isPopupHidden: state => popupName => {
    if (!popupName) {
      return false;
    }
    const openedPopupData = getPopupData(state, popupName);
    return Boolean(openedPopupData?.popupContext?.popupHidden);
  },
  getPopupContext: state => popupName => {
    const popupData = getPopupData(state, popupName);
    if (!popupData) {
      return null;
    }
    return popupData.popupContext;
  }
};

// actions
const actions = {
  openPopup({ commit, getters }, popupData) {
    if (!popupData.hasOwnProperty('shown')) {
      popupData.shown = true;
    }

    const isSegmentationPopupOpened = getters.isPopupOpened(
      PopupNamesEnum.USER_SEGMENTATION_POPUP
    );

    if (isSegmentationPopupOpened) {
      popupData.popupContext = {
        ...popupData.popupContext,
        popupHidden: true
      };
    }

    commit('addOpenedPopup', popupData);
  },
  closePopup({ state, commit, dispatch, getters }, popupData) {
    const closedPopupName = popupData.name;
    commit('removeFromOpenedPopup', popupData);

    if (closedPopupName === PopupNamesEnum.USER_SEGMENTATION_POPUP) {
      const hiddenPopup = state.openedPopups.find(popup =>
        getters.isPopupHidden(popup.name)
      );
      if (hiddenPopup) {
        dispatch('unhidePopup', hiddenPopup.name);
      }
    }
  },
  hidePopup({ commit }, popupName) {
    commit('hidePopupByName', popupName);
  },
  unhidePopup({ commit }, popupName) {
    commit('unhidePopupByName', popupName);
  },
  closeAll({ state, dispatch }) {
    state.openedPopups.forEach(({ name }) => {
      dispatch('closePopup', {
        name
      });
    });
  },
  async closeLastPopup({ state, dispatch }) {
    const lastPopup = state.openedPopups[state.openedPopups.length - 1];
    if (!lastPopup) {
      return;
    }
    await dispatch('closePopup', {
      name: lastPopup.name
    });

    const prevPopup = state.openedPopups[state.openedPopups.length - 1];

    if (prevPopup && prevPopup.popupContext?.popupHidden) {
      dispatch('unhidePopup', prevPopup.name);
    }
  },
  openUsageSuggestionPopup({ rootGetters }) {
    const Context = rootGetters['ContextStore/contextGetter'];
    UnsupportedBrowserPopupService.openPopup(Context);
  },
  openCongratulationPopup({ dispatch }, payload) {
    const congratulationContext = {
      ...payload,
      type: CongratulationTypesEnum.TRIAL
    };

    dispatch('openPopup', {
      name: PopupNamesEnum.CONGRATULATION_POPUP,
      popupContext: congratulationContext
    });
  },
  openSubscriptionCongratulationPopup({ dispatch }, payload) {
    const congratulationContext = {
      ...payload,
      type: CongratulationTypesEnum.SUBSCRIPTION
    };

    dispatch('openPopup', {
      name: PopupNamesEnum.CONGRATULATION_POPUP,
      popupContext: congratulationContext
    });
  },
  openPromoCongratulationPopup({ dispatch }, payload) {
    const congratulationContext = {
      ...payload,
      type: CongratulationTypesEnum.PROMO_CODE
    };

    dispatch('openPopup', {
      name: PopupNamesEnum.CONGRATULATION_POPUP,
      popupContext: congratulationContext
    });
  },
  startLoader({ commit }) {
    commit('setIsLoading', true);
  },
  finishLoader({ commit }) {
    commit('setIsLoading', false);
  },
  openSystemExceptionToaster({ dispatch }) {
    dispatch('openToaster', {
      text: $t('PresentSystemException.title')
    });
  },
  openErrorToaster({ dispatch }, payload) {
    dispatch('openToaster', {
      ...payload
    });
  },
  openSuccessToaster({ dispatch }, payload) {
    dispatch('openToaster', {
      toasterIcon: 'ico-status-success',
      ...payload
    });
  },
  openToaster() {},
  openSwitchCompilationToaster({ dispatch }, payload) {
    dispatch('openSuccessToaster', {
      ...payload
    });
  },
  openSharedCompilationToaster() {},
  openReturnToReadingToaster() {},
  closeReturnToReadingToaster() {},
  openReturnToCourseToaster() {},
  closeReturnToCourseToaster() {},
  openAppMenu() {},
  closeAppMenu() {},
  async handleSendMailError({ rootGetters, dispatch }, payload) {
    const sendEmailNotAllowedMsg = rootGetters[
      'ContextStore/getSharedErrorMessage'
    ]('sendEmailNotAllowed');
    if (
      payload?.data?.status === 'ERROR' &&
      payload?.data?.text === sendEmailNotAllowedMsg
    ) {
      const text = $t('SendEmail.notAllowed.text');
      await dispatch('openErrorToaster', {
        text
      });
      return true;
    }
    return false;
  },
  updatePopupContext({ commit }, { name, popupContext }) {
    commit('updatePopupContext', {
      name,
      popupContext
    });
  }
};

// mutations
const mutations = {
  setIsLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  addOpenedPopup(state, popupData) {
    const popupDataIndex = getPopupDataIndex(state, popupData.name);
    if (popupDataIndex !== -1) {
      return;
    }

    state.openedPopups.push(popupData);
  },
  removeFromOpenedPopup(state, popupData) {
    const popupDataIndex = getPopupDataIndex(state, popupData.name);
    if (popupDataIndex === -1) {
      return;
    }
    state.openedPopups.splice(popupDataIndex, 1);
  },
  hidePopupByName(state, popupName) {
    const openedPopupData = getPopupData(state, popupName);
    if (!openedPopupData) {
      return;
    }
    openedPopupData.popupContext = {
      ...openedPopupData.popupContext,
      popupHidden: true
    };
  },
  unhidePopupByName(state, popupName) {
    const openedPopupData = getPopupData(state, popupName);
    if (!openedPopupData) {
      return;
    }
    openedPopupData.popupContext = {
      ...openedPopupData.popupContext,
      popupHidden: false
    };
  },
  updatePopupContext(state, { name, popupContext }) {
    const openedPopupData = getPopupData(state, name);
    if (!openedPopupData) {
      return;
    }
    openedPopupData.popupContext = popupContext;
  }
};

export default {
  state: initState,
  getters: storeGetters,
  actions,
  mutations
};
