<template>
  <div id="app">
    <MediaDetector />
    <v-app id="inspire">
      <ErrorPresentation v-if="error" :is-prod="isProd" :error="error" />
      <ApplicationToolbar v-if="showToolbar" ref="appToolbar" />
      <div v-for="openedPopup in openedPopups" :key="openedPopup.name">
        <div
          :is="openedPopup.name"
          :popup-context="openedPopup.popupContext"
          @popupEvent="popupEventHandler"
        />
      </div>

      <div v-if="currentToaster">
        <div
          :is="currentToaster"
          :toaster-context="toasterContext"
          @toasterEvent="toasterEventHandler"
        />
      </div>

      <div v-if="loadingPopup">
        <component :is="loadingPopup" :popup-context="popupContext" />
      </div>

      <div v-if="isShownDictionary && isAuthenticated">
        <Dictionary />
      </div>

      <div class="content-wrapper">
        <VisibilityChecker />
        <Nuxt
          class="view-content"
          :class="{ 'extras-is-open': isExtrasOpened }"
        />
      </div>
      <span id="fontChecker" />
      <div id="spriteContainer" />
      <div v-if="updateToasterAvailable">
        <UpdateToaster />
      </div>
    </v-app>
    <div class="body-blackout"></div>
    <SupportIcon v-show="supportButton" />
    <OfflineBar :is-offline="!isOnline" />
    <UnsupportedBrowserPopup :os-urls="artifactUrlDetails" />
    <CacheOfflineImages :product-name="productName" :brand="brand" />
    <SearchLoader :load-by-event="true" style="display:none;" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import get from 'lodash/get';
// import isEmpty from 'lodash/isEmpty';
import dayJS from '@/dayJS';
import PopupNamesEnum from '@/enums/PopupNamesEnum';
import AppStates from '@/enums/AppStateEnum';
// import ManagePublicationsStates from '@/enums/ManagePublicationsStatesEnum';
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('default.vue');
import AppModeEnum from '@/enums/AppModeEnum';

import coverFallBackMixin from '@/components/mixins/coverFallBackMixin';
import titleMixin from '@/components/mixins/titleMixin';
import appPopupMixin from '@/components/mixins/appPopupMixin';
import defaultLayoutMixin from '@/components/mixins/defaultLayoutMixin';
import aboutViewMixin from '@/components/mixins/aboutViewMixin';

import SearchLoader from '@/components/SearchLoader.vue';
import CacheOfflineImages from '@/components/base/CacheOfflineImages/CacheOfflineImages.vue';
import ApplicationToolbar from '@/components/views/ApplicationToolbar/ApplicationToolbar.vue';
import OfflineBar from '@/components/views/OfflineBar/OfflineBar.vue';
import SupportIcon from '@/components/views/SupportIcon/SupportIcon.vue';
import Dictionary from '@/components/views/Dictionary/Dictionary';
import MediaDetector from '@/components/views/MediaDetector/MediaDetector';
import VisibilityChecker from '@/components/views/VisibilityChecker/VisibilityChecker.vue';
import ErrorPresentation from '@/components/views/ErrorPresentation/ErrorPresentation.vue';
import UnsupportedBrowserPopup from '@/components/base/UnsupportedBrowserPopup/UnsupportedBrowserPopup.vue';

import AppService from '@/services/AppService';
import AssetsManager from '@/services/AssetsManager/AssetsManager';
import Utils from '@/services/utils/Utils';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import QueryParamService from '@/services/QueryParamService';
import AgentService from '@/services/Agent/AgentService';
import LocalStorageService from '@/services/LocalStorageService';
import pwaService from '@/services/PwaService';

import Vue from 'vue';
import VueErrorHelper from '@/services/utils/VueErrorHelper';

let appContext = {};

class SystemError {
  constructor(message, stack, info) {
    this.message = message || 'empty message';
    this.stack = stack || 'empty stack';
    this.info = info || 'empty info';
  }
}

Vue.config.productionTip = false;
Vue.config.errorHandler = function(error, vm) {
  let trace = '';
  if (process.client && vm) {
    trace = VueErrorHelper.generateComponentTrace(vm);
  }
  const message = error && error.message ? error.message : 'empty message';
  const stack = error && error.stack ? error.stack : 'empty stack';
  appContext.error = new SystemError(message, stack, trace);
  try {
    if (process.client) {
      LocalStorageService.set('AppServiceInitProcessing', false);
      const query = QueryParamService.getAllParams(appContext.$router) || {};
      if (Object.keys(query).length !== 0) {
        QueryParamService.removeAllParams(appContext.$router);
      }
    }
  } catch (err) {
    logger.error(`Get error on remove query params error: ${err}`);
  }

  if (process.env.IS_SERVE) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  // if (process.server) {
  logger.error(
    `Get error in global handler error: ${error} stack: ${error?.stack}`
  );
  // }
};
Vue.config.warnHandler = function(error, vm, info) {
  appContext.error = new SystemError(error, '', info);
};

if (process.client) {
  const originalLog = window.console.log;
  const originalWarn = window.console.warn;
  const originalError = window.console.error;
  const originalInfo = window.console.info;
  const handleUnhandledRejection = event => {
    event.preventDefault();
  };

  window.hideConsoleLogs = () => {
    localStorage.setItem('showLogs', false);
    window.console.log = () => {};
    window.console.error = () => {};
    window.console.warn = () => {};
    window.console.info = () => {};

    window.onerror = () => true;
    window.onunhandledrejection = () => true;
    window.addEventListener('unhandledrejection', handleUnhandledRejection);
  };

  window.showConsoleLogs = () => {
    localStorage.setItem('showLogs', true);
    window.console.log = originalLog;
    window.console.warn = originalWarn;
    window.console.error = originalError;
    window.console.info = originalInfo;

    window.onerror = () => false;
    window.onunhandledrejection = () => false;
    window.removeEventListener('unhandledrejection', handleUnhandledRejection);
  };

  const isProd = process.env.IS_PROD;
  const rawShowLogs = localStorage.getItem('showLogs');
  const hasValue = Boolean(rawShowLogs);
  if (!hasValue) {
    localStorage.setItem('showLogs', !isProd);
  }
  if (isProd) {
    window.hideConsoleLogs();
  }
  const showLogs = rawShowLogs === 'true';
  if (showLogs) {
    window.showConsoleLogs();
  }
}

export default {
  name: 'DefaultLayout',
  components: {
    ApplicationToolbar,
    OfflineBar,
    SupportIcon,
    Dictionary,
    SearchLoader,
    MediaDetector,
    VisibilityChecker,
    ErrorPresentation,
    CacheOfflineImages,
    UnsupportedBrowserPopup,
    UpdateToaster: () =>
      import('@/components/views/UpdateToaster/UpdateToaster')
  },
  mixins: [
    appPopupMixin,
    defaultLayoutMixin,
    titleMixin,
    coverFallBackMixin,
    aboutViewMixin
  ],
  data() {
    appContext = this;

    return {
      error: null,
      productName: this.$t('ProductName'),
      updateToasterAvailable: false,
      isShownDictionary: false, // FV-142. Disable dictionary until Webster dict is not added
      endAppActionSubscription: null,
      endAppMutationSubscription: null,
      supportButton: false
    };
  },
  computed: {
    ...mapGetters('ContextStore', [
      'appModeGetter',
      'brand',
      'isDevice',
      'isElectron',
      'isDeviceBrowser',
      'appState',
      'showSupport',
      'isNative'
    ]),
    ...mapGetters('UserStore', ['getUser']),
    isProd() {
      return this.$store.getters['ContextStore/isProd'];
    },
    isAuthenticated() {
      return this.$store.getters['UserStore/isAuthenticated'];
    },
    isOnline() {
      return this.$store.getters['ContextStore/isOnline'];
    },
    isExtrasOpened() {
      return this.$store.getters['ManagePopupStore/isPopupOpened'](
        PopupNamesEnum.EXTRAS_POPUP
      );
    },
    systemLang() {
      return this.$store.getters['ContextStore/getSystemLanguage'];
    },
    isSearchPopupOpened() {
      return this.$store.getters['ManagePopupStore/isPopupOpened'](
        PopupNamesEnum.SEARCH
      );
    },
    isAnnotationInfoPopupOpened() {
      return this.$store.getters['ManagePopupStore/isPopupOpened'](
        PopupNamesEnum.ANNOTATION_INFO_POPUP
      );
    },
    isErrorPopupOpened() {
      return this.error;
    }
  },
  watch: {
    async isOnline(val) {
      if (val) {
        AssetsManager.resumeDownloadIfNeeded();
        this.$store.dispatch('PaymentsStore/stopWatcher');
        this.$store.dispatch('UserStore/actualizeUser');
        this.$store.dispatch('AssessmentStore/startActualizeAssessmentHistory');
        await this.$_syncUserData();
      } else {
        this.$store.dispatch('PaymentsStore/startWatcher');
        this.$store.dispatch('AssessmentStore/stopActualizeAssessmentHistory');
      }
    },
    systemLang(newSystemLang) {
      this.$vuetify.rtl = Utils.getDirection(newSystemLang) === 'rtl';
      this.setDateTimeLocalization(newSystemLang);
    },
    appState() {
      this.$_updateSupportPopup();
    },
    isSearchPopupOpened(isOpened) {
      if (!isOpened && this.isExtrasOpened) {
        return;
      }
      this.toggleOverflowClassOnHtml(isOpened);
    },
    isAnnotationInfoPopupOpened(isOpened) {
      this.toggleOverflowClassOnHtml(isOpened);
    },
    isErrorPopupOpened(isErrorPopupOpened) {
      this.toggleOverflowClassOnHtml(isErrorPopupOpened);
    },
    getUser() {
      this.$_updateSupportPopup();
    },
    isAuthenticated(val) {
      if (val) {
        AppService.initAppReaderAfterStart(this.$store);
      }
    }
  },
  mounted() {
    this.$_hideSplashScreenIfNeeded();
    this.$_updateSupportPopup();
    this.$_setIsDefaultLayoutInitiated();
    this.updateToasterAvailable = this.isElectron || this.isDevice;

    if (this.isAuthenticated) {
      AppService.initAppReaderAfterStart(this.$store);
    }
    this.$_startFacebookPixle();
    pwaService.cachePageOnServiceWorkerInstalled({
      route: this.$route,
      store: this.$store
    });
  },
  methods: {
    async $_syncUserData() {
      const isGuest = this.$store.getters['UserStore/isGuestUser'];
      const isAuthenticated = this.$store.getters['UserStore/isAuthenticated'];
      if (isGuest || !isAuthenticated) {
        return;
      }
      const clientNodeContext = this.$store.getters[
        'ContextStore/clientNodeContextGetter'
      ];
      const syncResp = await AgentService.syncData();
      if (
        !syncResp.synced &&
        !syncResp.progressEvent &&
        syncResp.isOnline &&
        !clientNodeContext.iFrameDetection &&
        this.appMode !== AppModeEnum.EDITOR
      ) {
        this.$store.dispatch('ManagePopupStore/openErrorToaster', {
          text: 'Oops! Something went wrong with sync data'
        });
      }
    },
    $_startFacebookPixle() {
      try {
        const isDevice = this.$store.getters['ContextStore/isDevice'];
        const isElectron = this.$store.getters['ContextStore/isElectron'];
        if (isDevice || isElectron || !this.$fb) {
          return;
        }
        this.$fb.enable();
      } catch (error) {
        logger.error(`Get error on start facebook pixel error:${error}`);
      }
    },
    $_hideSplashScreenIfNeeded() {
      if (this.isDevice) {
        navigator.splashscreen.hide();
      }
    },
    $_updateSupportPopup() {
      if (!this.showSupport) {
        this.$_hideSupportPopup();
        return;
      }
      switch (this.appState) {
        case AppStates.MANAGE_PUBLICATION:
        case AppStates.MANAGE_COMPILATION:
        case AppStates.MANAGE_RECENT_OPEN:
        case AppStates.MANAGE_SAVED_FOR_OFFLINE:
        case AppStates.MANAGE_BOOK_WITHOUT_AUDIO:
        case AppStates.MANAGE_ABOUT:
          this.$_showSupportPopup();
          break;
        default:
          this.$_hideSupportPopup();
      }
    },
    $_showSupportPopup() {
      this.supportButton = true;
    },
    $_hideSupportPopup() {
      this.supportButton = false;
      this.$_closeCurrentPopup(PopupNamesEnum.SUPPORT_POPUP);
    },
    setDateTimeLocalization(lang) {
      dayJS.setLocale(lang);
    },
    isScrollable(element) {
      return element.scrollHeight > element.clientHeight;
    },
    toggleOverflowClassOnHtml(isOpened) {
      const html =
        window.document.querySelector(
          `.${AppConstantsUtil.MANAGE_PUBLICATION_SCROLL_CLASS}`
        ) ||
        window.document.querySelector(
          `.${AppConstantsUtil.PUBLICATION_SCROLL_CLASS}`
        ) ||
        window.document.querySelector('html');

      const isMobile = this.isDevice || this.isDeviceBrowser;
      const bodyClass =
        !isMobile && this.isScrollable(html)
          ? AppConstantsUtil.BODY_OVERFLOW_CLASS_PADDING
          : AppConstantsUtil.BODY_OVERFLOW_CLASS;

      if (isOpened) {
        html.classList.add(bodyClass);
      } else {
        html.classList.remove(AppConstantsUtil.BODY_OVERFLOW_CLASS);
        html.classList.remove(AppConstantsUtil.BODY_OVERFLOW_CLASS_PADDING);
      }
    },
    $_setIsDefaultLayoutInitiated() {
      this.$store.dispatch('ContextStore/setIsDefaultLayoutInitiated', true);
    }
  }
};
</script>

<style lang="less">
@import '~@/assets/styles/global.less';
.search-widget {
  position: fixed;
  z-index: 20;
}
</style>
